import { combineReducers } from "redux";
import LoginReducer from "./LoginReducer";
import UserReducer from "./UserReducer";
import LayoutReducer from "./LayoutReducer";
import ScrumBoardReducer from "./ScrumBoardReducer";
import NotificationReducer from "./NotificationReducer";
import EcommerceReducer from "./EcommerceReducer";
import CategoeyReducer from "./CategoryReducer";
import ProductReducer from "./ProductReducer";
import usersReducer from "./UsersReducer";
import CartReducer from "./CartReducer";
import BrandRedeucer from "./BrandReducer";
import OrderReducer from "./OrderReducer";
import CommissionReducer from "./CommissionReducer";
import PaymentReducer from "./PaymentReducer";

const RootReducer = combineReducers({
  login: LoginReducer,
  user: UserReducer,
  layout: LayoutReducer,
  scrumboard: ScrumBoardReducer,
  notification: NotificationReducer,
  ecommerce: EcommerceReducer,
  category: CategoeyReducer,
  product: ProductReducer,
  users: usersReducer,
  cart: CartReducer,
  brand: BrandRedeucer,
  order: OrderReducer,
  commission: CommissionReducer,
  payment: PaymentReducer,
});

export default RootReducer;
