import { updatedObject } from "../Utility";

const initialState = {
  brands: "",
  postBrandStatus: "",
  postModelStatus: "",
  BrandModel: "",
  editBrandStatus: "",
  editModelStatus: "",
  editBrandError: "",
  editModelError: "",
  postModelError: "",
  postBrandError: "",
};

const getBrandsSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    brands: action.brands,
  });
};

const getBrandsFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const postBrandSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    postBrandStatus: action.status,
  });
};

const postBrandFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    postBrandError: action.postBrandError,
  });
};

const postModelSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    postModelStatus: action.status,
  });
};

const postModelFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    postModelError: action.postModelError,
  });
};

const clearPostBrandStatus = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    postBrandStatus: "",
  });
};

const clearBrands = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    brands: "",
  });
};

const clearPostModelStatus = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    postModelStatus: "",
  });
};

const getBrandSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    BrandModel: action.BrandModel,
  });
};

const getBrandFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const editBrandSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    editBrandStatus: action.status,
  });
};

const editBrandFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    editBrandError: action.editBrandError,
  });
};

const editModelSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    editModelStatus: action.status,
  });
};

const editModelFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    editModelError: action.editModelError,
  });
};

const clearUpdateStatus = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    editBrandStatus: "",
    editModelStatus: "",
  });
};

const clearModels = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    BrandModel: "",
  });
};

const BrandReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_BRANDS_SUCCESS":
      return getBrandsSuccess(state, action);
    case "GET_BRANDS_FAIL":
      return getBrandsFail(state, action);
    case "POST_BRAND_SUCCESS":
      return postBrandSuccess(state, action);
    case "POST_BRAND_FAIL":
      return postBrandFail(state, action);
    case "POST_MODEL_SUCCESS":
      return postModelSuccess(state, action);
    case "POST_MODEL_FAIL":
      return postModelFail(state, action);
    case "CLEAR_POST_BRAND_STATUS":
      return clearPostBrandStatus(state, action);
    case "CLEAR_BRANDS":
      return clearBrands(state, action);
    case "CLEAR_POST_MODEL_STATUS":
      return clearPostModelStatus(state, action);
    case "GET_BRAND_SUCCESS":
      return getBrandSuccess(state, action);
    case "GET_BRAND_FAIL":
      return getBrandFail(state, action);
    case "EDIT_BRAND_SUCCESS":
      return editBrandSuccess(state, action);
    case "EDIT_BRAND_FAIL":
      return editBrandFail(state, action);
    case "EDIT_MODEL_SUCCESS":
      return editModelSuccess(state, action);
    case "EDIT_MODEL_FAIL":
      return editModelFail(state, action);
    case "CLEAR_BRAND_MODEL_UPDATE_STATUS":
      return clearUpdateStatus(state, action);
    case "CLEAR_MODELS":
      return clearModels(state, action);
    default:
      return state;
  }
};

export default BrandReducer;
