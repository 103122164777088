import { MatxLoadable } from "matx";
// import { authRoles } from "../../../auth/authRoles";

const Settings = MatxLoadable({
  loader: () => import("./Settings"),
});

const PersonalDetail = MatxLoadable({
  loader: () => import("./PersonalDetails"),
});

const Users = MatxLoadable({
  loader: () => import("./Users/Users"),
});

const AddUsers = MatxLoadable({
  loader: () => import("./Users/AddUser"),
});

const ResetPassword = MatxLoadable({
  loader: () => import("./ResetPassword"),
});

const AddKYC = MatxLoadable({
  loader: () => import("./KYC/AddKYC"),
});

const ViewKYC = MatxLoadable({
  loader: () => import("./KYC/ViewKYC"),
});

const SettingsRoutes = [
  {
    path: "/settings",
    component: Settings,
  },
  {
    path: "/personalDetails",
    component: PersonalDetail,
  },
  {
    path: "/users",
    component: Users,
  },
  {
    path: "/adduser",
    component: AddUsers,
  },
  {
    path: "/resetpassword",
    component: ResetPassword,
  },
  {
    path: "/addkyc",
    component: AddKYC,
  },
  {
    path: "/viewkyc",
    component: ViewKYC,
  },
];

export default SettingsRoutes;
