import Axios from "axios";
import { instance } from "../ProductApi";

const axios = Axios.create({
  baseURL: process.env.REACT_APP_PRODUCT_API,
  headers: {
    Accept: "application/json",
  },
});

export const getCartSuccess = (data) => {
  return {
    type: "GET_CART_SUCCESS",
    cartData: data,
  };
};

export const getCartFail = (error) => {
  return {
    type: "GET_CART_FAIL",
    error: error,
  };
};

export const getCart = () => {
  return (dispatch) => {
    instance
      .get("/product/getcartitems/")
      .then((response) => {
        dispatch(getCartSuccess(response.data));
      })
      .catch((error) => {
        dispatch(getCartFail(error));
      });
  };
};

export const createCartSuccess = (data) => {
  return {
    type: "CREATE_CART_SUCCESS",
    cartData: data,
  };
};

export const createCartFail = (error) => {
  return {
    type: "CREATE_CART_FAIL",
    error: error,
  };
};

export const createCart = () => {
  return (dispatch) => {
    instance
      .post("/product/cart/1/")
      .then((response) => {
        dispatch(createCartSuccess(response.data.data));
      })
      .catch((error) => {
        dispatch(createCartFail(error));
      });
  };
};

export const addToCartSuccess = (status) => {
  return {
    type: "ADD_TO_CART_SUCCESS",
    cartItemStatus: status,
  };
};

export const addToCartFail = (error) => {
  return {
    type: "ADD_TO_CART_FAIL",
    error: error,
  };
};

export const addToCart = (productData) => {
  return (dispatch) => {
    instance
      .post("/product/cartitem/1/", productData)
      .then((response) => {
        dispatch(addToCartSuccess(response.data.success));
      })
      .catch((error) => {
        dispatch(addToCartFail(error));
      });
  };
};

export const clearAddToCartStatus = () => {
  return {
    type: "CLEAR_ADD_TO_CART_STATUS",
  };
};

export const getCartItemsSuccess = (data) => {
  return {
    type: "GET_CART_ITEMS_SUCCESS",
    cartData: data,
  };
};

export const getCartItemsFail = (error) => {
  return {
    type: "GET_CART_ITEMS_FAIL",
    error: error,
  };
};

export const getCartItems = () => {
  return (dispatch) => {
    instance
      .get("/product/getcartitems/")
      .then((response) => {
        dispatch(getCartItemsSuccess(response.data.data));
      })
      .catch((error) => {
        console.log(error.response)
        dispatch(getCartItemsFail(error));
      });
  };
};

export const deleteCartItemSuccess = (status) => {
  return {
    type: "DELETE_CART_ITEM_SUCCESS",
    deleteStatus: status,
  };
};

export const deleteCartItemFail = (error) => {
  return {
    type: "DELETE_CART_ITEM_FAIL",
    error: error,
  };
};

export const deleteCartItem = (id) => {
  return (dispatch) => {
    instance
      .delete(`/product/cartitem/${id}`)
      .then((response) => {
        dispatch(deleteCartItemSuccess(response.data.success));
      })
      .catch((error) => {
        dispatch(deleteCartItemFail(error));
      });
  };
};

export const clearCartItemDeleteStatus = () => {
  return {
    type: "CLEAR_CART_ITEM_DELETE_STATUS",
  };
};

export const clearCartData = () => {
  return {
    type: "CLEAR_CART_DATA",
  };
};

export const deleteCartSuccess = (status) => {
  return {
    type: "DELETE_CART_SUCCESS",
    deleteStatus: status,
  };
};

export const deleteCartFail = (error) => {
  return {
    type: "DELETE_CART_FAIL",
    error: error,
  };
};

export const deleteCart = (id) => {
  return (dispatch) => {
    instance
      .delete(`/product/cart/${id}`)
      .then((response) => {
        dispatch(deleteCartSuccess(response.data.success));
      })
      .catch((error) => {
        dispatch(deleteCartFail(error));
      });
  };
};

export const clearCartDeleteStatus = () => {
  return {
    type: "CLEAR_CART_DELETE_STATUS",
  };
};

export const clearCreateCartData = () => {
  return {
    type: "CLEAR_CREATE_CART_DATA",
  };
};

export const deleteOrderedCartItemsSuccess = (status) => {
  return {
    type: "DELETE_ORDERED_CART_ITEMS_SUCCESS",
    orderedItemsDeleteStatus: status,
  };
};

export const deleteOrderedCartItemsFail = (error) => {
  return {
    type: "DELETE_ORDERED_CART_ITEMS_FAIL",
    error: error,
  };
};

export const deleteOrderedCartItems = (id) => {
  const data = { ids: id }
  return (dispatch) => {
    instance
      .delete("/product/multiplecartitemdelete/", { data })
      .then((response) => {
        dispatch(deleteOrderedCartItemsSuccess(response.data.success));
      })
      .catch((error) => {
        dispatch(deleteOrderedCartItemsFail(error));
      });
  };
};

export const clearOrderedItemsDeleteStatus = () => {
  return {
    type: "CLEAR_ORDERED_CART_ITEMS_DELETE_STATUS",
  };
};
