import { MatxLoadable } from "matx";
// import { authRoles } from "../../../auth/authRoles";

const EcomProducts = MatxLoadable({
  loader: () => import("./EcomProducts")
})

const EcomProductDetail = MatxLoadable({
  loader: () => import("./EcomProductDetail")
})

const RetailerEcomRoutes = [
  {
    path: "/retailerecom/ecomproducts",
    component: EcomProducts,
    // auth: authRoles.admin
  },
  {
    path: "/ecomproducts/ecomproductDetail",
    component: EcomProductDetail,
    // auth: authRoles.admin,
  },
];

export default RetailerEcomRoutes;