import { updatedObject } from "../Utility";

const initialState = {
  error: null,
  loading: false,
  registeredUsers: "",
  deleteStatus: "",
  approveStatus: "",
  userDetails: "",
  specifiedUsers: "",
  editUserDetailStatus: "",
  forgotPasswordStatus: "",
  resetPasswordStatus: "",
  thirdPartyRegisterStatus: "",
  thirdPartyUserList: "",
  addKYCStatus: "",
  checkKYCStatus: "",
  myKYCDetails: "",
  userState: ""
};

const postUserStart = (state, action) => {
  return updatedObject(state, {
    loading: true,
    error: null,
  });
};

const postUserSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    approveStatus: action.status,
  });
};

const postUserFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const getUsersStart = (state, action) => {
  return updatedObject(state, {
    loading: true,
    error: null,
  });
};

const getUsersSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    registeredUsers: action.users,
  });
};

const getUsersFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const deleteUserStart = (state, action) => {
  return updatedObject(state, {
    loading: true,
    error: null,
  });
};

const deleteUserSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    deleteStatus: action.successStatus,
  });
};

const deleteUserFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const clearUsersList = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    registeredUsers: "",
  });
};

const clearDeleteStatus = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    deleteStatus: "",
  });
};

const clearApproveStatus = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    approveStatus: "",
  });
};

const getUserDetailsStart = (state, action) => {
  return updatedObject(state, {
    loading: true,
    error: null,
  });
};

const getUserDetailsSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    userDetails: action.userDetails,
  });
};

const getUserDetailsFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const getSpecifiedUsersStart = (state, action) => {
  return updatedObject(state, {
    loading: true,
    error: null,
  });
};

const getSpecifiedUsersSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    specifiedUsers: action.specifiedusers,
  });
};

const getSpecifiedUsersFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const editUserDetailsSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    editUserDetailStatus: action.status,
  });
};

const editUserDetailsFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const forgotPasswordSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    forgotPasswordStatus: action.successStatus,
  });
};

const forgotPasswordFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const resetPasswordSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    resetPasswordStatus: action.successStatus,
  });
};

const resetPasswordFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const addThirdPartyUserSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    thirdPartyRegisterStatus: action.thirdPartyRegisterStatus,
  });
};

const addThirdPartyUserFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const clearResetPasswordStatus = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    resetPasswordStatus: "",
  });
};

const getThirdPartyUserListSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    thirdPartyUserList: action.thirdPartyUserList,
  });
};

const getThirdPartyUserListFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const addKYCDetailsSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    addKYCStatus: action.addKYCStatus,
  });
};

const addKYCDetailsFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const clearAddKYCStatus = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    addKYCStatus: "",
  });
};

const checkKYCaddedSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    checkKYCStatus: action.checkKYCStatus,
  });
};

const checkKYCaddedFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const clearCheckKYCaddedStatus = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    checkKYCStatus: "",
  });
};

const getMyKYCDetailsSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    myKYCDetails: action.myKYCDetails,
  });
};

const getMyKYCDetailsFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const getUserStateSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    userState: action.userState,
  });
};

const getUserStateFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case "POST_USER_START":
      return postUserStart(state, action);
    case "POST_USER_SUCCESS":
      return postUserSuccess(state, action);
    case "POST_USER_FAIL":
      return postUserFail(state, action);
    case "GET_USERS_START":
      return getUsersStart(state, action);
    case "GET_USERS_SUCCESS":
      return getUsersSuccess(state, action);
    case "GET_USERS_FAIL":
      return getUsersFail(state, action);
    case "DELETE_USER_START":
      return deleteUserStart(state, action);
    case "DELETE_USER_SUCCESS":
      return deleteUserSuccess(state, action);
    case "DELETE_USER_FAIL":
      return deleteUserFail(state, action);
    case "CLEAR_USERS_LIST":
      return clearUsersList(state, action);
    case "CLEAR_DELETE_STATUS":
      return clearDeleteStatus(state, action);
    case "CLEAR_APPROVE_STATUS":
      return clearApproveStatus(state, action);
    case "GET_USER_DETAILS_START":
      return getUserDetailsStart(state, action);
    case "GET_USER_DETAILS_SUCCESS":
      return getUserDetailsSuccess(state, action);
    case "GET_USER_DETAILS_FAIL":
      return getUserDetailsFail(state, action);
    case "GET_SPECIFIED_USERS_START":
      return getSpecifiedUsersStart(state, action);
    case "GET_SPECIFIED_USERS_SUCCESS":
      return getSpecifiedUsersSuccess(state, action);
    case "GET_SPECIFIED_USERS_FAIL":
      return getSpecifiedUsersFail(state, action);
    case "EDIT_USER_DETAILS_SUCCESS":
      return editUserDetailsSuccess(state, action);
    case "EDIT_USER_DETAILS_FAIL":
      return editUserDetailsFail(state, action);
    case "FORGOT_PASSWORD_SUCCESS":
      return forgotPasswordSuccess(state, action);
    case "FORGOT_PASSWORD_FAIL":
      return forgotPasswordFail(state, action);
    case "RESET_PASSWORD_SUCCESS":
      return resetPasswordSuccess(state, action);
    case "RESET_PASSWORD_FAIL":
      return resetPasswordFail(state, action);
    case "ADD_THIRD_PARTY_USER_SUCCESS":
      return addThirdPartyUserSuccess(state, action);
    case "ADD_THIRD_PARTY_USER_FAIL":
      return addThirdPartyUserFail(state, action);
    case "CLEAR_RESET_PASSWORD_STATUS":
      return clearResetPasswordStatus(state, action);
    case "GET_THIRD_PARTY_USERLIST_SUCCESS":
      return getThirdPartyUserListSuccess(state, action);
    case "GET_THIRD_PARTY_USERLIST_FAIL":
      return getThirdPartyUserListFail(state, action);
    case "ADD_KYC_DETAILS_SUCCESS":
      return addKYCDetailsSuccess(state, action);
    case "ADD_KYC_DETAILS_FAIL":
      return addKYCDetailsFail(state, action);
    case "CLEAR_ADD_KYC_STATUS":
      return clearAddKYCStatus(state, action);
    case "CHECK_KYC_ADDED_SUCCESS":
      return checkKYCaddedSuccess(state, action);
    case "CHECK_KYC_ADDED_FAIL":
      return checkKYCaddedFail(state, action);
    case "CLEAR_CHECK_KYC_ADDED_STATUS":
      return clearCheckKYCaddedStatus(state, action);
    case "GET_MY_KYC_DETAILS_SUCCESS":
      return getMyKYCDetailsSuccess(state, action);
    case "GET_MY_KYC_DETAILS_FAIL":
      return getMyKYCDetailsFail(state, action);
    case "GET_USER_STATE_SUCCESS":
      return getUserStateSuccess(state, action);
    case "GET_USER_STATE_FAIL":
      return getUserStateFail(state, action);
    default:
      return state;
  }
};

export default usersReducer;
