import React, { Component } from "react";
import {
  Card,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Grid,
  Button,
  Link,
} from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import * as actions from "../../redux/actions/Index";
import { message } from "antd";
import "antd/dist/antd.css";
import { mxValidate } from "../../../matx/components/Utility.js";

class SignUp extends Component {
  state = {
    firstName: "",
    lastName: "",
    address1: "",
    address2: "",
    city: "",
    pincode: "",
    email: "",
    telephone: "",
    salutation: "",
    contactName: "",
    contactNumber: "",
    userType: "",
    state: "",
    establishmentname: "",
    errorArr: [],
  };

  componentDidMount = () => {
    this.props.onGetState();
  };

  handleChange = (event) => {
    event.preventDefault();
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleStateChange = (event) => {
    event.preventDefault();
    const state_idx = event.target.value;
    this.setState({ state: state_idx });
    this.props.onGetCity(state_idx);
  };

  handleRegisterUser = () => {
    this.setState({ errorArr: {} });
    let message = "";

    //Registration type validation
    let validateRegArr = {
      name: "Registration type",
      input: this.state.userType,
      selectValue: true,
    };
    let responseReg = mxValidate(validateRegArr);
    this.setState((prevState) => ({
      errorArr: {
        ...prevState.errorArr,
        errUserType: responseReg,
      },
    }));
    message = message + responseReg;

    //Establishment validation
    let validateEstArr = {
      name: "Establishment name",
      input: this.state.establishmentname,
      emptyValue: true,
      validChars: /^[a-zA-Z0-9\.&,-/ ]*$/,
      min: 2,
      max: 50,
      digitsOnly: true,
      regexMessage:
        "alphabets, digits, ampersand, hyphen, comma, backslash, space and dot characters",
    };
    let responseEstablishment = mxValidate(validateEstArr);
    this.setState((prevState) => ({
      errorArr: {
        ...prevState.errorArr,
        errEstablishment: responseEstablishment,
      },
    }));
    message = message + responseEstablishment;

    //Address1 validation
    let validateAddr1Arr = {
      name: "Address 1",
      input: this.state.address1,
      emptyValue: true,
      validChars: /^[a-zA-Z0-9\.&,-/ ]*$/,
      min: 2,
      max: 100,
      regexMessage:
        "alphabets, digits, ampersand, hyphen, comma, backslash, space and dot characters",
    };
    let responseAddr1 = mxValidate(validateAddr1Arr);
    this.setState((prevState) => ({
      errorArr: {
        ...prevState.errorArr,
        errAddr1: responseAddr1,
      },
    }));
    message = message + responseAddr1;

    //Address 2 validation
    let validateAddr2Arr = {
      name: "Address 2",
      input: this.state.address2,
      validChars: /^[a-zA-Z0-9\.&,-/ ]*$/,
      max: 100,
      regexMessage:
        "alphabets, digits, ampersand, hyphen, comma, backslash, space and dot characters",
    };
    let responseAddr2 = mxValidate(validateAddr2Arr);
    this.setState((prevState) => ({
      errorArr: {
        ...prevState.errorArr,
        errAddr2: responseAddr2,
      },
    }));
    message = message + responseAddr2;

    //State validation
    let validateStateArr = {
      name: "State",
      input: this.state.state,
      selectValue: true,
    };
    let responseState = mxValidate(validateStateArr);
    this.setState((prevState) => ({
      errorArr: {
        ...prevState.errorArr,
        errState: responseState,
      },
    }));
    message = message + responseState;

    //City validation
    let validateCityArr = {
      name: "City",
      input: this.state.city,
      selectValue: true,
    };
    let responseCity = mxValidate(validateCityArr);
    this.setState((prevState) => ({
      errorArr: {
        ...prevState.errorArr,
        errCity: responseCity,
      },
    }));
    message = message + responseCity;

    //Telephone1 validation
    let validateTel1Arr = {
      name: "Telephone 1",
      input: this.state.contactNumber,
      emptyValue: true,
      validChars: /^[0-9]*$/,
      min: 10,
      max: 11,
      regexMessage: "digits",
    };
    let responseTel1 = mxValidate(validateTel1Arr);
    this.setState((prevState) => ({
      errorArr: {
        ...prevState.errorArr,
        errTel1: responseTel1,
      },
    }));
    message = message + responseTel1;

    //Telephone2 validation
    let validateTel2Arr = {
      name: "Telephone 2",
      input: this.state.telephone,
      validChars: /^[0-9]*$/,
      minAllowNull: 10,
      max: 11,
      regexMessage: "digits",
    };
    let responseTel2 = mxValidate(validateTel2Arr);
    this.setState((prevState) => ({
      errorArr: {
        ...prevState.errorArr,
        errTel2: responseTel2,
      },
    }));
    message = message + responseTel2;

    //E-Mail address validation
    let validateEmailArr = {
      name: "E-Mail address",
      input: this.state.email,
      email: true,
    };
    let responseEmail = mxValidate(validateEmailArr);
    this.setState((prevState) => ({
      errorArr: {
        ...prevState.errorArr,
        errEmail: responseEmail,
      },
    }));
    message = message + responseEmail;

    //Pincode validation
    let validatePincodeArr = {
      name: "Pincode",
      input: this.state.pincode,
      emptyValue: true,
      validChars: /^[0-9]*$/,
      equal: 6,
      regexMessage: "digits",
    };
    let responsePincode = mxValidate(validatePincodeArr);
    this.setState((prevState) => ({
      errorArr: {
        ...prevState.errorArr,
        errPincode: responsePincode,
      },
    }));
    message = message + responsePincode;

    //Saluation validation
    let validateSaluationArr = {
      name: "Saluation",
      input: this.state.salutation,
      selectValue: true,
    };
    let responseSalutation = mxValidate(validateSaluationArr);
    this.setState((prevState) => ({
      errorArr: {
        ...prevState.errorArr,
        errSaluation: responseSalutation,
      },
    }));
    message = message + responseSalutation;

    //Firstname validation
    let validateFnameArr = {
      name: "Firstname",
      input: this.state.firstName,
      emptyValue: true,
      validChars: /^[a-zA-Z\. ]*$/,
      max: 20,
      regexMessage: "alphabets, space and dot characters",
    };
    let responseFname = mxValidate(validateFnameArr);
    this.setState((prevState) => ({
      errorArr: {
        ...prevState.errorArr,
        errFirstname: responseFname,
      },
    }));
    message = message + responseFname;

    //Lastname validation
    let validateLnameArr = {
      name: "Lastname",
      input: this.state.lastName,
      emptyValue: true,
      validChars: /^[a-zA-Z\. ]*$/,
      max: 20,
      regexMessage: "alphabets, space and dot characters",
    };
    let responseLname = mxValidate(validateLnameArr);
    this.setState((prevState) => ({
      errorArr: {
        ...prevState.errorArr,
        errLastname: responseLname,
      },
    }));
    message = message + responseLname;

    let submitData = {
      typeofuser: this.state.userType,
      companyname: this.state.establishmentname,
      address1: this.state.address1,
      address2: this.state.address2,
      city_idx: this.state.city,
      telephone: this.state.contactNumber,
      landline: this.state.telephone,
      email: this.state.email,
      pincode: parseInt(this.state.pincode),
      saluation: this.state.salutation,
      first_name: this.state.firstName,
      last_name: this.state.lastName,
    };

    let apiResponse = message === "" ? this.props.registerUser(submitData) : "";
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.registerError !== this.props.registerError) {
      this.setState({ errorArr: {} });

      Object.keys(this.props.registerError).map((key) => {
        this.setState((prevState) => ({
          errorArr: {
            ...prevState.errorArr,
            ["err" + key]: this.props.registerError[key][0],
          },
        }));
      });
    }
  }
  success = () => {
    message.success(
      "Registration successfull and due for verification process",
      5
    );
  };

  render() {
    if (this.props.registerStatus === "True") {
      this.success();
      return <Redirect to="/session/signin" />;
    }
    return (
      <div className="signup flex flex-center w-100 h-100vh">
        <div className="p-8">
          <Card className="signup-card position-relative y-center">
            <Grid container>
              <Grid item lg={4} md={4} sm={4} xs={12}>
                <div className="p-32 bg-primary h-100">
                  <div
                    className="flex-column flex-center"
                    style={{
                      height: "80%",
                      textAlign: "center",
                      color: "white",
                    }}
                  >
                    <h3>Ecaps Registration</h3>
                    <span>Ecaps is your Opportunity.The Right One</span>
                    <img
                      src="/assets/images/illustrations/posting_photo.svg"
                      alt=""
                    />
                  </div>
                </div>
              </Grid>
              <Grid item lg={8} md={8} sm={8} xs={12}>
                <div className="bg-light-gray">
                  <Grid container direction="row">
                    <Grid item md sm={6}>
                      <div className="px-16 h-100">
                        <ValidatorForm ref="form">
                          <FormControl
                            variant="outlined"
                            className="mb-8 mt-16 w-100"
                          >
                            <InputLabel>Registration type *</InputLabel>
                            <Select
                              required
                              onChange={this.handleChange}
                              name="userType"
                              value={this.state.userType}
                              label="Registration Type"
                            >
                              <MenuItem value="M">Manufacturer</MenuItem>
                              <MenuItem value="D">Distributer</MenuItem>
                              <MenuItem value="R">Retailer</MenuItem>
                            </Select>
                            <div className="errorDiv">
                              {this.state.errorArr["errUserType"]}
                            </div>
                          </FormControl>
                        </ValidatorForm>
                      </div>
                    </Grid>
                    <Grid item md sm={6}>
                      <div className="px-16 h-100">
                        <ValidatorForm ref="form">
                          <TextValidator
                            required
                            className="mb-8 mt-16 w-100"
                            variant="outlined"
                            label="Establishment name"
                            onChange={this.handleChange}
                            type="text"
                            name="establishmentname"
                            value={this.state.establishmentname}
                          />
                          <div className="errorDiv">
                            {this.state.errorArr["errEstablishment"]}
                          </div>
                        </ValidatorForm>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container direction="row">
                    <Grid item md sm={6}>
                      <div className="px-16 h-100">
                        <ValidatorForm ref="form">
                          <TextValidator
                            required
                            className="mb-8 mt-8 w-100"
                            variant="outlined"
                            label="Address 1"
                            onChange={this.handleChange}
                            type="text"
                            name="address1"
                            value={this.state.address1}
                          />
                          <div className="errorDiv">
                            {this.state.errorArr["errAddr1"]}
                          </div>
                        </ValidatorForm>
                      </div>
                    </Grid>
                    <Grid item md sm={6}>
                      <div className="px-16 h-100">
                        <ValidatorForm ref="form">
                          <TextValidator
                            className="mb-8 mt-8 w-100"
                            variant="outlined"
                            label="Address 2"
                            onChange={this.handleChange}
                            type="text"
                            name="address2"
                            value={this.state.address2}
                          />
                          <div className="errorDiv">
                            {this.state.errorArr["errAddr2"]}
                          </div>
                        </ValidatorForm>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container direction="row">
                    <Grid item md sm={6}>
                      <div className="px-16 h-100">
                        <ValidatorForm ref="form">
                          <FormControl
                            variant="outlined"
                            className="mb-8 mt-8 w-100"
                          >
                            <InputLabel>State *</InputLabel>
                            <Select
                              required
                              onChange={this.handleStateChange}
                              name="state"
                              value={this.state.state}
                              label="State"
                            >
                              {this.props.states
                                ? this.props.states.map((state) => (
                                    <MenuItem
                                      key={state.state_idx}
                                      value={state.state_idx}
                                    >
                                      {state.name}
                                    </MenuItem>
                                  ))
                                : null}
                            </Select>
                          </FormControl>
                          <div className="errorDiv">
                            {this.state.errorArr["errState"]}
                          </div>
                        </ValidatorForm>
                      </div>
                    </Grid>
                    <Grid item md sm={6}>
                      <div className="px-16 h-100">
                        <ValidatorForm ref="form">
                          <FormControl
                            variant="outlined"
                            className="mb-8 mt-8 w-100"
                          >
                            <InputLabel>City *</InputLabel>
                            <Select
                              required
                              onChange={this.handleChange}
                              name="city"
                              value={this.state.city}
                              label="City"
                            >
                              {this.props.cities
                                ? this.props.cities.map((city) => (
                                    <MenuItem
                                      key={city.city_idx}
                                      value={city.city_idx}
                                    >
                                      {city.name}
                                    </MenuItem>
                                  ))
                                : null}
                            </Select>
                          </FormControl>
                          <div className="errorDiv">
                            {this.state.errorArr["errCity"]}
                          </div>
                        </ValidatorForm>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container direction="row">
                    <Grid item md sm={6}>
                      <div className="px-16 h-100">
                        <ValidatorForm ref="form">
                          <TextValidator
                            required
                            className="mb-8 mt-8 w-100"
                            label="Telephone 1"
                            variant="outlined"
                            onChange={this.handleChange}
                            name="contactNumber"
                            type="text"
                            value={this.state.contactNumber}
                          />
                          <div className="errorDiv">
                            {this.state.errorArr["errTel1"]}
                            {this.state.errorArr["errtelephone"]}
                          </div>
                        </ValidatorForm>
                      </div>
                    </Grid>
                    <Grid item md sm={6}>
                      <div className="px-16 h-100">
                        <ValidatorForm ref="form">
                          <TextValidator
                            className="mb-8 mt-8 w-100"
                            label="Telephone 2"
                            variant="outlined"
                            onChange={this.handleChange}
                            name="telephone"
                            type="text"
                            value={this.state.telephone}
                          />
                          <div className="errorDiv">
                            {this.state.errorArr["errTel2"]}
                          </div>
                        </ValidatorForm>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container direction="row">
                    <Grid item md sm={6}>
                      <div className="px-16 h-100">
                        <ValidatorForm ref="form">
                          <TextValidator
                            required
                            className="mb-8 mt-8 w-100"
                            label="Email"
                            variant="outlined"
                            onChange={this.handleChange}
                            name="email"
                            type="email"
                            value={this.state.email}
                          />
                          <div className="errorDiv">
                            {this.state.errorArr["errEmail"]}
                            {this.state.errorArr["erremail"]}
                          </div>
                        </ValidatorForm>
                      </div>
                    </Grid>
                    <Grid item md sm={6}>
                      <div className="px-16 h-100">
                        <ValidatorForm ref="form">
                          <TextValidator
                            required
                            className="mb-8 mt-8 w-100"
                            label="Pincode"
                            variant="outlined"
                            onChange={this.handleChange}
                            name="pincode"
                            type="number"
                            value={this.state.pincode}
                          />
                          <div className="errorDiv">
                            {this.state.errorArr["errPincode"]}
                          </div>
                        </ValidatorForm>
                      </div>
                    </Grid>
                  </Grid>
                  <div className="px-8 h-100">
                    <div style={{ marginBottom: "10px", marginLeft: "5px" }}>
                      <span className="text-muted">Contact person details</span>
                    </div>
                    <Grid container>
                      <Grid item md={4} sm={4}>
                        <div className="px-8 h-100">
                          <ValidatorForm ref="form">
                            <FormControl
                              variant="outlined"
                              className="mb-16 w-100"
                            >
                              <InputLabel>Salutation</InputLabel>
                              <Select
                                required
                                onChange={this.handleChange}
                                name="salutation"
                                value={this.state.salutation}
                                label="Salutation"
                              >
                                <MenuItem value="Mr.">Mr.</MenuItem>
                                <MenuItem value="Mrs.">Mrs.</MenuItem>
                                <MenuItem value="Ms.">Ms.</MenuItem>
                              </Select>
                            </FormControl>
                            <div className="errorDiv">
                              {this.state.errorArr["errSaluation"]}
                            </div>
                          </ValidatorForm>
                        </div>
                      </Grid>
                      <Grid item md={4} sm={4}>
                        <div className="px-8 h-100">
                          <ValidatorForm ref="form">
                            <TextValidator
                              required
                              className="mb-16 w-100"
                              variant="outlined"
                              label="First name"
                              onChange={this.handleChange}
                              type="text"
                              name="firstName"
                              value={this.state.firstName}
                            />
                            <div className="errorDiv">
                              {this.state.errorArr["errFirstname"]}
                            </div>
                          </ValidatorForm>
                        </div>
                      </Grid>
                      <Grid item md={4} sm={4}>
                        <div className="px-8 h-100">
                          <ValidatorForm ref="form">
                            <TextValidator
                              required
                              className="mb-16 w-100"
                              variant="outlined"
                              label="Last name"
                              onChange={this.handleChange}
                              type="text"
                              name="lastName"
                              value={this.state.lastName}
                            />
                            <div className="errorDiv">
                              {this.state.errorArr["errLastname"]}
                            </div>
                          </ValidatorForm>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                  <div className="p-16">
                    <div className="flex flex-middle">
                      <Button
                        className="capitalize w-100"
                        variant="contained"
                        color="primary"
                        type="submit"
                        onClick={this.handleRegisterUser}
                      >
                        Register
                      </Button>
                    </div>
                    <div className="mt-8">
                      <span>
                        Already a user?{" "}
                        <Link href="/session/signin">Login</Link>
                      </span>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Card>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  // loginWithEmailAndPassword: PropTypes.func.isRequired,
  states: state.login.states,
  cities: state.login.cities,
  registerError: state.login.registerError,
  registerStatus: state.login.registerStatus,
});

const mapDispatchToProps = (dispatch) => {
  return {
    registerUser: (registerData) =>
      dispatch(actions.registerUser(registerData)),
    onGetState: () => dispatch(actions.getState()),
    onGetCity: (state_idx) => dispatch(actions.getCity(state_idx)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignUp));
