
export function mxValidate(valArr) {
  var input = valArr['input'];
  var message = '';
  
  //check if text box value is null
  message = (valArr['emptyValue'] && input === "") ? `Please enter your ${valArr['name']} *` : ''
  if (message)
    return message;
  
  //check if select box value is null
  message = (valArr['selectValue'] && input === "") ? `Please select your ${valArr['name']} *` : ''
  if (message)
    return message;
  
   //check the input is a valid number only if entered
   message = (valArr['allowNull'] && (input.length > 0)) ? (valArr['validnumber'] && typeof parseInt(input) != 'number') ? `${valArr['name']} must be a valid number` : '' : ''
   if (message)
     return message
  
  //valid characters 
  var regex = new RegExp(valArr['validChars'])
  message = regex.test(input) ? '' : `${valArr['name']} accepts only ${valArr['regexMessage']} *`
  if (message)
    return message
  
  //digits only not allowed
  message = (valArr['digitsOnly'] && !isNaN(input)) ? `${valArr['name']} cannot be a number *` : ''
  if (message)
    return message
  
  //minimum required length
  message = (valArr['min'] && input.length < valArr['min']) ? `${valArr['name']} required minimum ${valArr['min']} characters *` : ''
  if (message)
    return message
  
  //allow null and if not null keep minimum value
  message = (valArr['minAllowNull'] && (input.length > 0)) ? (input.length < valArr['minAllowNull']) ? `${valArr['name']} required minimum ${valArr['minAllowNull']} characters *` : '' : ''
  if (message)
    return message

  //maximum allowed string length
  message = (valArr['max'] && input.length > valArr['max']) ? `Maximum number of characters allowed in ${valArr['name']} is ${valArr['max']} *` : ''
  if (message)
    return message
  
  //email address validation
  message = (valArr['email'] && !(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(input))) ? `Please enter a valid ${valArr['name']} *` : ''
  if (message)
    return message
  
  //must have n characters
  message = (valArr['equal'] && input.length !== valArr['equal']) ? `${valArr['name']} must have ${valArr['equal']} ${valArr['regexMessage']} *` : ''
  if (message)
    return message
  
  return message
}
