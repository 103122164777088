import { MatxLoadable } from "matx";

const DistributorOrders = MatxLoadable({
  loader: () => import("./DistributorOrders"),
});

const RetailerOrders = MatxLoadable({
  loader: () => import("./RetailerOrders"),
});

const RetailerOrderDetails = MatxLoadable({
  loader: () => import("./RetailerOrderDetails"),
});

const DistributorOrderDetail = MatxLoadable({
  loader: () => import("./DistributorOrderDetail"),
});

const RetailerDeliveryNote = MatxLoadable({
  loader: () => import("./RetailerDeliveryNote"),
});

const RetailerReturns = MatxLoadable({
  loader: () => import("./RetailerReturns"),
});

const DistributorReturns = MatxLoadable({
  loader: () => import("./MyReturns"),
});

const RetailerReturnsDetail = MatxLoadable({
  loader: () => import("./RetailerReturnsDetail"),
});

const Invoice = MatxLoadable({
  loader: () => import("./Invoice"),
});

const Refund = MatxLoadable({
  loader: () => import("../../Refund"),
});

const OrderRoutes = [
  {
    path: "/distributor/myorders",
    component: DistributorOrders,
  },
  {
    path: "/distributor/myordersdetail",
    component: DistributorOrderDetail,
  },
  {
    path: "/retailerorders",
    component: RetailerOrders,
  },
  {
    path: "/retailerorderdetails",
    component: RetailerOrderDetails,
  },
  {
    path: "/rdeliveryNote",
    component: RetailerDeliveryNote,
  },
  {
    path: "/rreturns",
    component: RetailerReturns,
  },
  {
    path: "/myreturns",
    component: DistributorReturns,
  },
  {
    path: "/rreturnsDetail",
    component: RetailerReturnsDetail,
  },
  {
    path: "/rinvoice",
    component: Invoice,
  },
  {
    path: "/drefunds",
    component: Refund,
  },
];

export default OrderRoutes;
