import { MatxLoadable } from "matx";
// import { authRoles } from "../../../auth/authRoles";

const Products = MatxLoadable({
  loader: () => import("./Products")
})

const ProductDetail = MatxLoadable({
  loader: () => import("./ProductDetail")
})

const EcommerceRoutes = [
  {
    path: "/ecommerce/products",
    component: Products,
    // auth: authRoles.admin
  },
  {
    path: "/products/productDetail",
    component: ProductDetail,
    // auth: authRoles.admin,
  },
];

export default EcommerceRoutes;