import Axios from "axios";
import { instance } from "../ProductApi";

const axios = Axios.create({
  baseURL: process.env.REACT_APP_PRODUCT_API,
  headers: { Accept: "application/json", "Access-Control-Allow-Origin": "*" },
});

export const postCategoryStart = () => {
  return {
    type: "POST_CATEGORY_START",
  };
};

export const postCategorySuccess = (status, data) => {
  return {
    type: "POST_CATEGORY_SUCCESS",
    successStatus: status,
    data: data,
  };
};

export const postCategoryFail = (error) => {
  return {
    type: "POST_CATEGORY_FAIL",
    error: error,
  };
};

export const postCategory = (categoryData, PID) => {
  const postData = {
    category: categoryData,
  };
  return (dispatch) => {
    dispatch(postCategoryStart());
    instance
      .post(`/product/setcategory/${PID}/`, postData)
      .then((response) => {
        dispatch(
          postCategorySuccess(response.data.success, response.data.data)
        );
      })
      .catch((error) => {
        dispatch(postCategoryFail(error.response.data.message));
      });
  };
};

export const editCategorySuccess = (data) => {
  return {
    type: "EDIT_CATEGORY_SUCCESS",
    response: data,
  };
};

export const editCategoryFail = (error) => {
  return {
    type: "EDIT_CATEGORY_FAIL",
    error: error,
  };
};

export const editCategory = (categoryData, ID, PID) => {
  return (dispatch) => {
    dispatch(postCategoryStart());
    instance
      .put(`/product/categoryedit/${ID}/${PID}/`, categoryData)
      .then((response) => {
        dispatch(editCategorySuccess(response.data.success));
      })
      .catch((error) => {
        dispatch(editCategoryFail(error.response.data.message));
      });
  };
};

export const clearCategories = () => {
  return {
    type: "CLEAR_CATEGORIES",
  };
};

export const clearSuccessStatus = () => {
  return {
    type: "CLEAR_SUCCESS_STATUS",
  };
};

export const clearEditResponse = () => {
  return {
    type: "CLEAR_EDIT_RESPONSE",
  };
};

export const getCategoryStart = () => {
  return {
    type: "GET_CATEGORY_START",
  };
};

export const getCategorySuccess = (data) => {
  return {
    type: "GET_CATEGORY_SUCCESS",
    categoryData: data,
  };
};

export const getCategoryFail = (error) => {
  return {
    type: "GET_CATEGORY_FAIL",
    error: error,
  };
};

export const getCategory = (id) => {
  let URL = `/product/getcategory/${id}/`;
  return (dispatch) => {
    dispatch(getCategoryStart());
    instance
      .get(URL)
      .then((response) => {
        dispatch(getCategorySuccess(response.data.data));
      })
      .catch((error) => {
        dispatch(getCategoryFail(error));
      });
  };
};

export const getAllCategoriesStart = () => {
  return {
    type: "GET_ALL_CATEGORIES_START",
  };
};

export const getAllCategoriesSuccess = (data) => {
  return {
    type: "GET_ALL_CATEGORIES_SUCCESS",
    allCategoryData: data,
  };
};

export const getAllCategoriesFail = (error) => {
  return {
    type: "GET_ALL_CATEGORIES_FAIL",
    error: error,
  };
};

export const getAllCategories = () => {
  let URL = "/product/allcategory/";
  return (dispatch) => {
    dispatch(getAllCategoriesStart());
    instance
      .get(URL)
      .then((response) => {
        dispatch(getAllCategoriesSuccess(response.data.data));
      })
      .catch((error) => {
        dispatch(getAllCategoriesFail(error));
      });
  };
};

export const postVarientStart = () => {
  return {
    type: "POST_VARIENT_START",
  };
};

export const postVarientSuccess = (status) => {
  return {
    type: "POST_VARIENT_SUCCESS",
    status: status,
  };
};

export const postVarientFail = (error) => {
  return {
    type: "POST_VARIENT_FAIL",
    error: error,
  };
};

export const postVarient = (varientData) => {
  return (dispatch) => {
    dispatch(postVarientStart());
    instance
      .post("/product/varient/", varientData)
      .then((response) => {
        dispatch(postVarientSuccess(response.data.success));
      })
      .catch((error) => {
        dispatch(postVarientFail(error));
      });
  };
};

export const getVarientsStart = () => {
  return {
    type: "GET_VARIENTS_START",
  };
};

export const getVarientsSuccess = (data) => {
  return {
    type: "GET_VARIENTS_SUCCESS",
    Varients: data,
  };
};

export const getVarientsFail = (error) => {
  return {
    type: "GET_VARIENTS_FAIL",
    error: error,
  };
};

export const getVarients = () => {
  return (dispatch) => {
    dispatch(getVarientsStart());
    instance
      .get("/product/getvarient/")
      .then((response) => {
        dispatch(getVarientsSuccess(response.data.data));
      })
      .catch((error) => {
        dispatch(getVarientsFail(error));
      });
  };
};

export const getVarientOptionsStart = () => {
  return {
    type: "GET_VARIENT_OPTIONS_START",
  };
};

export const getVarientOptionsSuccess = (data) => {
  return {
    type: "GET_VARIENT_OPTIONS_SUCCESS",
    VarientOptions: data,
  };
};

export const getVarientOptionsFail = (error) => {
  return {
    type: "GET_VARIENT_OPTIONS_FAIL",
    error: error,
  };
};

export const getVarientOptions = (id) => {
  return (dispatch) => {
    dispatch(getVarientOptionsStart());
    URL = `/product/getvarientoption/${id}`;
    instance
      .get(URL)
      .then((response) => {
        dispatch(getVarientOptionsSuccess(response.data.data));
      })
      .catch((error) => {
        dispatch(getVarientOptionsFail(error));
      });
  };
};

export const getUnitsStart = () => {
  return {
    type: "GET_UNITS_START",
  };
};

export const getUnitsSuccess = (data) => {
  return {
    type: "GET_UNITS_SUCCESS",
    Units: data,
  };
};

export const getUnitsFail = (error) => {
  return {
    type: "GET_UNITS_FAIL",
    error: error,
  };
};

export const getUnits = () => {
  return (dispatch) => {
    dispatch(getUnitsStart());
    instance
      .get("/product/unit/")
      .then((response) => {
        dispatch(getUnitsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(getUnitsFail(error));
      });
  };
};

export const deleteCategoryStart = () => {
  return {
    type: "DELETE_CATEGORY_START",
  };
};

export const deleteCategorySuccess = (status) => {
  return {
    type: "DELETE_CATEGORY_SUCCESS",
    status: status,
  };
};

export const deleteCategoryFail = (error) => {
  return {
    type: "DELETE_CATEGORY_FAIL",
    error: error,
  };
};

export const deleteCategory = (id, parentId) => {
  return (dispatch) => {
    dispatch(deleteCategoryStart());
    instance
      .delete(`/product/categoryedit/${id}/${parentId}/`)
      .then((response) => {
        dispatch(deleteCategorySuccess(response.status));
      })
      .catch((error) => {
        dispatch(deleteCategoryFail(error));
      });
  };
};

export const clearCategoryDeleteStatus = () => {
  return {
    type: "CLEAR_CATEGORY_DELETE_STATUS",
  };
};

export const postEditVarientOptionStart = () => ({
  type: "POST_EDIT_VARIANT_OPTION_START",
});
export const postEditVariantOptionSuccess = (status) => ({
  type: "POST_EDIT_VARIANT_OPTION_SUCCESS",
  status: status,
});
export const postEditVariantOptionFail = (error) => ({
  type: "POST_EDIT_VARIANT_OPTION_FAIL",
  error: error,
});

export const editVariantOption = (variantOptionData, ID) => {
  return (dispatch) => {
    dispatch(postEditVarientOptionStart());
    instance
      .put(`/product/createvarientoption/${ID}`, variantOptionData)
      .then((response) => {
        dispatch(postEditVariantOptionSuccess(response.data.success));
      })
      .catch((error) => {
        dispatch(postEditVariantOptionFail(error));
      });
  };
};

export const clearVariantOptionEditResponse = () => {
  return {
    type: "CLEAR_VARIANT_OPTION_EDIT_RESPONSE",
  };
};

// Add Variant option block
export const postAddVarientOptionStart = () => ({
  type: "POST_ADD_VARIANT_OPTION_START",
});
export const postAddVariantOptionSuccess = (status) => ({
  type: "POST_ADD_VARIANT_OPTION_SUCCESS",
  status: status,
});
export const postAddVariantOptionFail = (error) => ({
  type: "POST_ADD_VARIANT_OPTION_FAIL",
  error: error,
});

export const addVariantOption = (variantOptionData, ID) => {
  return (dispatch) => {
    dispatch(postAddVarientOptionStart());
    instance
      .post(`/product/createvarientoption/${ID}`, variantOptionData)
      .then((response) => {
        dispatch(postAddVariantOptionSuccess(response.data.success));
      })
      .catch((error) => {
        dispatch(postAddVariantOptionFail(error.response && error.response.data.message));
      });
  };
};

export const clearVariantOptionAddResponse = () => {
  return {
    type: "CLEAR_VARIANT_OPTION_ADD_RESPONSE",
  };
};
//End of add variant option block

//Add Vaiant block
export const postAddVariantStart = () => ({ type: "POST_ADD_VARIANT_START" });
export const postAddVariantSuccess = (status) => ({
  type: "POST_ADD_VARIANT_SUCCESS",
  status: status,
});
export const postAddVariantFail = (error) => ({
  type: "POST_ADD_VARIANT_FAIL",
  error: error,
});
export const addVariant = (variantData) => {
  return (dispatch) => {
    dispatch(postAddVariantStart());
    instance
      .post(`/product/createvarient/`, variantData)
      .then((response) => {
        dispatch(postAddVariantSuccess(response.data.success));
      })
      .catch((error) => {
        dispatch(postAddVariantFail(error.response && error.response.data.message));
      });
  };
};
export const clearVariantAddResponse = () => {
  return {
    type: "CLEAR_VARIANT_ADD_RESPONSE",
  };
};

export const clearVariantAddFailResponse = () => {
  return {
    type: "CLEAR_VARIANT_ADD_FAIL_RESPONSE",
  };
};
//End of add Variant block

//Edit Variant block
export const postEditVariantStart = () => ({ type: "POST_EDIT_VARIANT_START" });
export const postEditVariantSuccess = (status) => ({
  type: "POST_EDIT_VARIANT_SUCCESS",
  status: status,
});
export const postEditVariantFail = (error) => ({
  type: "POST_EDIT_VARIANT_FAIL",
  error: error,
});
export const editVariant = (variantData) => {
  return (dispatch) => {
    dispatch(postEditVariantStart());
    instance
      .put(`/product/createvarient/`, variantData)
      .then((response) => {
        dispatch(postEditVariantSuccess(response.data.success));
      })
      .catch((error) => {
        dispatch(postEditVariantFail(error));
      });
  };
};
export const clearVariantEditResponse = () => {
  return {
    type: "CLEAR_VARIANT_EDIT_RESPONSE",
  };
};
//End of edit variant block

export const getTaxSuccess = (data) => {
  return {
    type: "GET_TAX_SUCCESS",
    tax: data,
  };
};

export const getTaxFail = (error) => {
  return {
    type: "GET_TAX_FAIL",
    error: error,
  };
};

export const getTax = () => {
  return (dispatch) => {
    instance
      .get("/product/gettax/")
      .then((response) => {
        dispatch(getTaxSuccess(response.data.data));
      })
      .catch((error) => {
        dispatch(getTaxFail(error));
      });
  };
};

export const updateTaxSuccess = (status) => {
  return {
    type: "UPDATE_TAX_SUCCESS",
    status: status,
  };
};

export const updateTaxFail = (error) => {
  return {
    type: "UPDATE_TAX_FAIL",
    error: error,
  };
};

export const updateTax = (data, id) => {
  return (dispatch) => {
    instance
      .put(`/product/taxupdate/${id}/`, data)
      .then((response) => {
        dispatch(updateTaxSuccess(response.data.success));
      })
      .catch((error) => {
        dispatch(updateTaxFail(error));
      });
  };
};

export const clearTaxes = () => {
  return {
    type: "CLEAR_TAXES",
  };
};

export const clearUpdateTaxStatus = () => {
  return {
    type: "CLEAR_UPDATE_TAX_STATUS",
  };
};

export const postUnitSuccess = (status) => {
  return {
    type: "POST_UNIT_SUCCESS",
    postUnitStatus: status,
  };
};

export const postUnitFail = (error) => {
  return {
    type: "POST_UNIT_FAIL",
    error: error,
  };
};

export const postUnit = (unit) => {
  return (dispatch) => {
    instance
      .post("/product/unit/", unit)
      .then((response) => {
        dispatch(postUnitSuccess(response.status));
      })
      .catch((error) => {
        dispatch(postUnitFail(error.response.data.message));
      });
  };
};

export const editUnitSuccess = (status) => {
  return {
    type: "EDIT_UNIT_SUCCESS",
    editUnitStatus: status,
  };
};

export const editUnitFail = (error) => {
  return {
    type: "EDIT_UNIT_FAIL",
    error: error,
  };
};

export const editUnit = (unitData, unitId) => {
  return (dispatch) => {
    instance
      .put(`/product/unitupdate/${unitId}/`, unitData)
      .then((response) => {
        dispatch(editUnitSuccess(response.data.success));
      })
      .catch((error) => {
        dispatch(editUnitFail(error));
      });
  };
};

export const clearPostEditUnitStatus = () => {
  return {
    type: "CLEAR_POST_EDIT_UNIT_STATUS",
  };
};

export const clearUnitData = () => {
  return {
    type: "CLEAR_UNIT_DATA",
  };
};

export const clearAllCategories = () => {
  return {
    type: "CLEAR_ALL_CATEGORIES",
  };
};
