import { instance } from "../CustomerApi";
import Axios from "axios";

export const axios = Axios.create({
  baseURL: process.env.REACT_APP_CUSTOMER_API,
  headers: { Accept: "application/json" },
});

export const RESET_PASSWORD = "RESET_PASSWORD";

export const authStart = () => {
  return {
    type: "AUTH_START",
  };
};

export const authSuccess = (refresh, access, success, type) => {
  return {
    type: "AUTH_SUCCESS",
    refreshToken: refresh,
    accessToken: access,
    status: success,
    userType: type,
  };
};

export const authFail = (error) => {
  return {
    type: "AUTH_FAIL",
    error: error,
  };
};

export const auth = (loginData) => {
  return (dispatch) => {
    dispatch(authStart());
    axios
      .post("/user/token/", loginData)
      .then((response) => {
        localStorage.setItem("refreshToken", response.data.refresh);
        localStorage.setItem("accessToken", response.data.access);
        localStorage.setItem("userType", response.data.type);
        dispatch(
          authSuccess(
            response.data.refresh,
            response.data.access,
            response.data.success,
            response.data.type
          )
        );
      })
      .catch((error) => {
        dispatch(authFail(error.response.data));
      });
  };
};

export const registerUserStart = () => {
  return {
    type: "REGISTER_USER_START",
  };
};

export const registerUserSuccess = (success, message) => {
  return {
    type: "REGISTER_USER_SUCCESS",
    status: success,
    message: message,
  };
};

export const registerUserFail = (error) => {
  return {
    type: "REGISTER_USER_FAIL",
    error: error,
  };
};

export const registerUser = (registerData) => {
  return (dispatch) => {
    dispatch(registerUserStart());
    axios
      .post("/user/register/", registerData)
      .then((response) => {
        dispatch(
          registerUserSuccess(response.data.success, response.data.message)
        );
      })
      .catch((error) => {
        dispatch(registerUserFail(error.response.data.exception));
      });
  };
};

export const getStateStart = () => {
  return {
    type: "GET_STATE_START",
  };
};

export const getStateSuccess = (data) => {
  return {
    type: "GET_STATE_SUCCESS",
    state: data,
  };
};

export const getStateFail = (error) => {
  return {
    type: "GET_STATE_FAIL",
    error: error,
  };
};

export const getState = () => {
  return (dispatch) => {
    dispatch(getStateStart());
    axios
      .get("/user/state/")
      .then((response) => {
        dispatch(getStateSuccess(response.data));
      })
      .catch((error) => {
        dispatch(getStateFail(error));
      });
  };
};

export const getCityStart = () => {
  return {
    type: "GET_CITY_START",
  };
};

export const getCitySuccess = (data) => {
  return {
    type: "GET_CITY_SUCCESS",
    city: data,
  };
};

export const getCityFail = (error) => {
  return {
    type: "GET_CITY_FAIL",
    error: error,
  };
};

export const getCity = (state_id) => {
  return (dispatch) => {
    dispatch(getCityStart());
    axios
      .get(`/user/city/${state_id}/`)
      .then((response) => {
        dispatch(getCitySuccess(response.data));
      })
      .catch((error) => {
        dispatch(getCityFail(error));
      });
  };
};

export function resetPassword({ email }) {
  return (dispatch) => {
    dispatch({
      payload: email,
      type: RESET_PASSWORD,
    });
  };
}

export const logoutStart = () => {
  return {
    type: "LOGOUT_START",
  };
};

export const logoutSuccess = (status) => {
  return {
    type: "LOGOUT_SUCCESS",
    status: status,
  };
};

export const logoutFail = (error) => {
  return {
    type: "LOGOUT_FAIL",
    error: error,
  };
};

export const logout = () => {
  return (dispatch) => {
    dispatch(logoutStart());
    const data = {
      token: localStorage.getItem("refreshToken"),
    };
    instance
      .post("/user/logout/", data)
      .then((response) => {
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("accessToken");
        localStorage.removeItem("userType");
        dispatch(logoutSuccess(response.data.success));
      })
      .catch((error) => {
        dispatch(logoutFail(error.response));
      });
  };
};

export const clearLoginSuccessStatus = () => {
  return {
    type: "CLEAR_LOGIN_SUCCESS",
  };
};

export const clearErrStatus = () => {
  return {
    type: "CLEAR_ERR_STATUS",
  };
};
