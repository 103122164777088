import { MatxLoadable } from "matx";

const Unit = MatxLoadable({
  loader: () => import("./Unit"),
});

const UnitRoutes = [
  {
    path: "/units",
    component: Unit,
  },
];

export default UnitRoutes;
