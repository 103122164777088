import Axios from "axios";
import { instance } from "../ProductApi";

const axios = Axios.create({
  baseURL: process.env.REACT_APP_PRODUCT_API,
  headers: {
    Accept: "application/json",
  },
});

export const orderProductsSuccess = (status, data) => {
  return {
    type: "ORDER_PRODUCTS_SUCCESS",
    orderStatus: status,
    orderedData: data,
  };
};

export const orderProductsFail = (error) => {
  return {
    type: "ORDER_PRODUCTS_FAIL",
    error: error,
  };
};

export const orderProducts = (orderData) => {
  const order_items = orderData;
  return (dispatch) => {
    instance
      .post("/product/order/", { order_items })
      .then((response) => {
        dispatch(
          orderProductsSuccess(response.data.success, response.data.data)
        );
      })
      .catch((error) => {
        dispatch(orderProductsFail(error));
      });
  };
};

export const getCustomerOrdersSuccess = (data) => {
  return {
    type: "GET_CUSTOMER_ORDERS_SUCCESS",
    customerOrders: data,
  };
};

export const getCustomerOrdersFail = (error) => {
  return {
    type: "GET_CUSTOMER_ORDERS_FAIL",
    error: error,
  };
};

export const getCustomerOrders = (type, page) => {
  return (dispatch) => {
    instance
      .get(`/product/getorder/${type}/?page=${page}`)
      .then((response) => {
        dispatch(getCustomerOrdersSuccess(response.data));
      })
      .catch((error) => {
        dispatch(getCustomerOrdersFail(error));
      });
  };
};

export const acceptOrderSuccess = (status) => {
  return {
    type: "ACCEPT_ORDER_SUCCESS",
    acceptOrderStatus: status,
  };
};

export const acceptOrderFail = (error) => {
  return {
    type: "ACCEPT_ORDER_FAIL",
    error: error,
  };
};

export const acceptOrder = (data, id) => {
  return (dispatch) => {
    instance
      .put(`/product/orderaccept/${id}/`, data)
      .then((response) => {
        dispatch(acceptOrderSuccess(response.data.success));
      })
      .catch((error) => {
        dispatch(acceptOrderFail(error));
      });
  };
};

export const clearOrderData = () => {
  return {
    type: "CLEAR_ORDER_DATA",
  };
};

export const clearAcceptOrderStatus = () => {
  return {
    type: "CLEAR_ACCEPT_ORDER_STATUS",
  };
};

export const clearOrderStatus = () => {
  return {
    type: "CLEAR_ORDER_STATUS",
  };
};

export const shipOrderSuccess = (status) => {
  return {
    type: "SHIP_ORDER_SUCCESS",
    shipOrderStatus: status,
  };
};

export const shipOrderFail = (error) => {
  return {
    type: "SHIP_ORDER_FAIL",
    error: error,
  };
};

export const shipOrder = (data, id) => {
  return (dispatch) => {
    instance
      .post(`/product/shiporder/${id}/`, data)
      .then((response) => {
        dispatch(shipOrderSuccess(response.data.success));
      })
      .catch((error) => {
        dispatch(shipOrderFail(error));
      });
  };
};

export const clearShipOrderStatus = () => {
  return {
    type: "CLEAR_SHIP_ORDER_STATUS",
  };
};

export const getMyOrdersSuccess = (data) => {
  return {
    type: "GET_MY_ORDERS_SUCCESS",
    distributorOrders: data,
  };
};

export const getMyOrdersFail = (error) => {
  return {
    type: "GET_MY_ORDERS_FAIL",
    error: error,
  };
};

export const getMyOrders = (page) => {
  return (dispatch) => {
    instance
      .get(`/product/myorders/?page=${page}`)
      .then((response) => {
        dispatch(getMyOrdersSuccess(response.data));
      })
      .catch((error) => {
        dispatch(getMyOrdersFail(error));
      });
  };
};

export const getStockSuccess = (data) => {
  return {
    type: "GET_STOCK_SUCCESS",
    inStock: data,
  };
};

export const getStockFail = (error) => {
  return {
    type: "GET_STOCK_FAIL",
    error: error,
  };
};

export const getStock = (id, type) => {
  const data = {
    order_item_id: id,
    status: type,
  };
  return (dispatch) => {
    instance
      .post("/product/instock/", data)
      .then((response) => {
        dispatch(getStockSuccess(response.data));
      })
      .catch((error) => {
        dispatch(getStockFail(error));
      });
  };
};

export const getIntransitSuccess = (data) => {
  return {
    type: "GET_INTRANSIT_SUCCESS",
    intransit: data,
  };
};

export const getIntransitFail = (error) => {
  return {
    type: "GET_INTRANSIT_FAIL",
    error: error,
  };
};

export const getIntransit = (id, type) => {
  const data = {
    order_item_id: id,
    status: type,
  };
  return (dispatch) => {
    instance
      .post("/product/mystock/", data)
      .then((response) => {
        dispatch(getIntransitSuccess(response.data));
      })
      .catch((error) => {
        dispatch(getIntransitFail(error));
      });
  };
};

export const shipPartialOrderSuccess = (status) => {
  return {
    type: "SHIP_PARTIAL_ORDER_SUCCESS",
    shipPartialOrderStatus: status,
  };
};

export const shipPartialOrderFail = (error) => {
  return {
    type: "SHIP_PARTIAL_ORDER_FAIL",
    error: error,
  };
};

export const shipPartialOrder = (data, id) => {
  return (dispatch) => {
    instance
      .post(`/product/partiallyship/${id}/`, data)
      .then((response) => {
        dispatch(shipPartialOrderSuccess(response.data.success));
      })
      .catch((error) => {
        dispatch(shipPartialOrderFail(error));
      });
  };
};

export const acceptRecievedSuccess = (data) => {
  return {
    type: "ACCEPT_RECIEVED_SUCCESS",
    acceptRecievedStatus: data,
  };
};

export const acceptRecievedFail = (error) => {
  return {
    type: "ACCEPT_RECIEVED_FAIL",
    error: error,
  };
};

export const acceptRecieved = (data, id) => {
  return (dispatch) => {
    instance
      .put(`/product/orderdelivered/${id}/`, data)
      .then((response) => {
        dispatch(acceptRecievedSuccess(response.data.success));
      })
      .catch((error) => {
        dispatch(acceptRecievedFail(error));
      });
  };
};

export const clearAcceptRecievedStatus = () => {
  return {
    type: "CLEAR_ACCEPT_RECIEVED_STATUS",
  };
};

export const shipRetailerOrderSuccess = (status) => {
  return {
    type: "SHIP_RETAILER_ORDER_SUCCESS",
    shipRetailerOrderStatus: status,
  };
};

export const shipRetailerOrderFail = (error) => {
  return {
    type: "SHIP_RETAILER_ORDER_FAIL",
    error: error,
  };
};

export const shipRetailerOrder = (data, id) => {
  return (dispatch) => {
    instance
      .post(`/product/retailership/${id}/`, data)
      .then((response) => {
        dispatch(shipRetailerOrderSuccess(response.data.success));
      })
      .catch((error) => {
        dispatch(shipRetailerOrderFail(error));
      });
  };
};

export const clearShipRetailerOrderStatus = () => {
  return {
    type: "CLEAR_SHIP_RETAILER_ORDER_STATUS",
  };
};

export const shipPartialRetailerOrderSuccess = (status) => {
  return {
    type: "SHIP_PARTIAL_RETAILER_ORDER_SUCCESS",
    shipPartialRetailerOrderStatus: status,
  };
};

export const shipPartialRetailerOrderFail = (error) => {
  return {
    type: "SHIP_PARTIAL_RETAILER_ORDER_FAIL",
    error: error,
  };
};

export const shipPartialRetailerOrder = (data, id) => {
  return (dispatch) => {
    instance
      .post(`/product/retailerpartialship/${id}/`, data)
      .then((response) => {
        dispatch(shipPartialRetailerOrderSuccess(response.data.success));
      })
      .catch((error) => {
        dispatch(shipPartialRetailerOrderFail(error));
      });
  };
};

export const getShipInfoSuccess = (data) => {
  return {
    type: "GET_SHIPINFO_SUCCESS",
    shipInfo: data,
  };
};

export const getShipInfoFail = (error) => {
  return {
    type: "GET_SHIPINFO_FAIL",
    error: error,
  };
};

export const getShipInfo = (id) => {
  return (dispatch) => {
    instance
      .get(`/product/shipinfo/${id}/`)
      .then((response) => {
        dispatch(getShipInfoSuccess(response.data));
      })
      .catch((error) => {
        dispatch(getShipInfoFail(error));
      });
  };
};

export const getOrderDetailSuccess = (data) => {
  return {
    type: "GET_ORDER_DETAIL_SUCCESS",
    orderDetails: data,
  };
};

export const getOrderDetailFail = (error) => {
  return {
    type: "GET_ORDER_DETAIL_FAIL",
    error: error,
  };
};

export const getOrderDetail = (oid) => {
  return (dispatch) => {
    instance
      .get(`/product/orderdetail/${oid}/`)
      .then((response) => {
        dispatch(getOrderDetailSuccess(response.data));
      })
      .catch((error) => {
        dispatch(getOrderDetailFail(error));
      });
  };
};

export const getOneOrderDetailSuccess = (data) => {
  return {
    type: "GET_ONE_ORDER_DETAIL_SUCCESS",
    oneOrderDetails: data,
  };
};

export const getOneOrderDetailFail = (error) => {
  return {
    type: "GET_ONE_ORDER_DETAIL_FAIL",
    error: error,
  };
};

export const getOneOrderDetail = (oid) => {
  return (dispatch) => {
    instance
      .get(`/product/allorderitems/${oid}/`)
      .then((response) => {
        dispatch(getOneOrderDetailSuccess(response.data));
      })
      .catch((error) => {
        dispatch(getOneOrderDetailFail(error));
      });
  };
};

export const getOrderSuccessDetailsSuccess = (data) => {
  return {
    type: "GET_ORDER_SUCCESS_DETAILS_SUCCESS",
    OrderSuccessDetails: data,
  };
};

export const getOrderSuccessDetailsFail = (error) => {
  return {
    type: "GET_ORDER_SUCCESS_DETAILS_FAIL",
    error: error,
  };
};

export const getOrderSuccessDetails = (oid) => {
  return (dispatch) => {
    instance
      .get(`/product/successorders/${oid}/`)
      .then((response) => {
        dispatch(getOrderSuccessDetailsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(getOrderSuccessDetailsFail(error));
      });
  };
};

export const checkReturnQuantitySuccess = (data) => {
  return {
    type: "CHECK_RETURN_QUANTITY_SUCCESS",
    returnableQuantity: data,
  };
};

export const checkReturnQuantityFail = (error) => {
  return {
    type: "CHECK_RETURN_QUANTITY_FAIL",
    error: error,
  };
};

export const checkReturnQuantity = (id) => {
  return (dispatch) => {
    instance
      .get(`/product/checkstockreturn/${id}/`)
      .then((response) => {
        dispatch(checkReturnQuantitySuccess(response.data));
      })
      .catch((error) => {
        dispatch(checkReturnQuantityFail(error));
      });
  };
};

export const returnOrderSuccess = (data) => {
  return {
    type: "RETURN_ORDER_SUCCESS",
    returnOrderStatus: data,
  };
};

export const returnOrderFail = (error) => {
  return {
    type: "RETURN_ORDER_FAIL",
    error: error,
  };
};

export const returnOrder = (data, ImageData) => {
  return (dispatch) => {
    instance
      .post("/product/returnorder/", data)
      .then((response) => {
        ImageData.append("return_id", response.data.data.id);
        instance.post("/product/retunimage/", ImageData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
        dispatch(returnOrderSuccess(response.data.success));
      })
      .catch((error) => {
        dispatch(returnOrderFail(error));
      });
  };
};

export const clearInStock = () => {
  return {
    type: "CLEAR_INSTOCK",
  };
};

export const clearInTransit = () => {
  return {
    type: "CLEAR_INTRANSIT",
  };
};

export const clearShipInfo = () => {
  return {
    type: "CLEAR_SHIP_INFO",
  };
};

export const clearReturnOrderStatus = () => {
  return {
    type: "CLEAR_RETURN_ORDER_STATUS",
  };
};

export const getBuyerReturnsSuccess = (data) => {
  return {
    type: "GET_BUYER_RETURNS_SUCCESS",
    buyerReturns: data,
  };
};

export const getBuyerReturnsFail = (error) => {
  return {
    type: "GET_BUYER_RETURNS_FAIL",
    error: error,
  };
};

export const getBuyerReturns = (returnData) => {
  return (dispatch) => {
    instance
      .post("/product/getreturnorder/", returnData)
      .then((response) => {
        dispatch(getBuyerReturnsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(getBuyerReturnsFail(error));
      });
  };
};

export const acceptBuyerReturnSuccess = (data) => {
  return {
    type: "ACCEPT_BUYER_RETURN_SUCCESS",
    acceptReturnStatus: data,
  };
};

export const acceptBuyerReturnFail = (error) => {
  return {
    type: "ACCEPT_BUYER_RETURN_FAIL",
    error: error,
  };
};

export const acceptBuyerReturn = (id) => {
  return (dispatch) => {
    instance
      .put(`/product/returnaccept/${id}/`)
      .then((response) => {
        dispatch(acceptBuyerReturnSuccess(response.data.success));
      })
      .catch((error) => {
        dispatch(acceptBuyerReturnFail(error));
      });
  };
};

export const rejectBuyerReturnSuccess = (data) => {
  return {
    type: "REJECT_BUYER_RETURN_SUCCESS",
    rejectReturnStatus: data,
  };
};

export const rejectBuyerReturnFail = (error) => {
  return {
    type: "REJECT_BUYER_RETURN_FAIL",
    error: error,
  };
};

export const rejectBuyerReturn = (id) => {
  return (dispatch) => {
    instance
      .put(`/product/returnreject/${id}/`)
      .then((response) => {
        dispatch(rejectBuyerReturnSuccess(response.data.success));
      })
      .catch((error) => {
        dispatch(rejectBuyerReturnFail(error));
      });
  };
};

export const shipBuyerReturnSuccess = (data) => {
  return {
    type: "SHIP_BUYER_RETURN_SUCCESS",
    shipReturnStatus: data,
  };
};

export const shipBuyerReturnFail = (error) => {
  return {
    type: "SHIP_BUYER_RETURN_FAIL",
    error: error,
  };
};

export const shipBuyerReturn = (id) => {
  return (dispatch) => {
    instance
      .put(`/product/returnshipped/${id}/`)
      .then((response) => {
        dispatch(shipBuyerReturnSuccess(response.data.success));
      })
      .catch((error) => {
        dispatch(shipBuyerReturnFail(error));
      });
  };
};

export const getMyReturnsSuccess = (data) => {
  return {
    type: "GET_MY_RETURNS_SUCCESS",
    myReturns: data,
  };
};

export const getMyReturnsFail = (error) => {
  return {
    type: "GET_MY_RETURNS_FAIL",
    error: error,
  };
};

export const getMyReturns = (profileId) => {
  const data = { profile: profileId };
  return (dispatch) => {
    instance
      .post("/product/getReturnorderinbuyer/", data)
      .then((response) => {
        dispatch(getMyReturnsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(getMyReturnsFail(error));
      });
  };
};

export const clearAcceptReturnStatus = () => {
  return {
    type: "CLEAR_ACCEPT_RETURN_STATUS",
  };
};

export const clearRejectReturnStatus = () => {
  return {
    type: "CLEAR_REJECT_RETURN_STATUS",
  };
};

export const clearBuyerReturns = () => {
  return {
    type: "CLEAR_BUYER_RETURNS",
  };
};

export const clearShipReturnStatus = () => {
  return {
    type: "CLEAR_SHIP_RETURN_STATUS",
  };
};

export const getReturnImagesSuccess = (data) => {
  return {
    type: "GET_RETURN_IMAGES_SUCCESS",
    returnImages: data,
  };
};

export const getReturnImagesFail = (error) => {
  return {
    type: "GET_RETURN_IMAGES_FAIL",
    error: error,
  };
};

export const getReturnImages = (id) => {
  return (dispatch) => {
    instance
      .get(`/product/getrefundimage/${id}`)
      .then((response) => {
        dispatch(getReturnImagesSuccess(response.data.data));
      })
      .catch((error) => {
        dispatch(getReturnImagesFail(error));
      });
  };
};

export const completeReturnSuccess = (data) => {
  return {
    type: "COMPLETE_RETURN_SUCCESS",
    completeReturnStatus: data,
  };
};

export const completeReturnFail = (error) => {
  return {
    type: "COMPLETE_RETURN_FAIL",
    error: error,
  };
};

export const completeReturn = (id) => {
  return (dispatch) => {
    instance
      .put(`/product/returncomplete/${id}/`)
      .then((response) => {
        dispatch(completeReturnSuccess(response.data.success));
      })
      .catch((error) => {
        dispatch(completeReturnFail(error));
      });
  };
};

export const clearCompleteReturnStatus = () => {
  return {
    type: "CLEAR_COMPLETE_RETURN_STATUS",
  };
};

export const getAllOrdersAdminSuccess = (data) => {
  return {
    type: "GET_ALL_ORDERS_ADMIN_SUCCESS",
    allOrdersAdmin: data,
  };
};

export const getAllOrdersAdminFail = (error) => {
  return {
    type: "GET_ALL_ORDERS_ADMIN_FAIL",
    error: error,
  };
};

export const getAllOrdersAdmin = (page) => {
  return (dispatch) => {
    instance
      .get(`/product/allordersinadmin/?page=${page}`)
      .then((response) => {
        dispatch(getAllOrdersAdminSuccess(response.data));
      })
      .catch((error) => {
        dispatch(getAllOrdersAdminFail(error));
      });
  };
};

export const getReturnedOrdersAdminSuccess = (data) => {
  return {
    type: "GET_RETURNED_ORDERS_ADMIN_SUCCESS",
    returnedOrdersAdmin: data,
  };
};

export const getReturnedOrdersAdminFail = (error) => {
  return {
    type: "GET_RETURNED_ORDERS_ADMIN_FAIL",
    error: error,
  };
};

export const getReturnedOrdersAdmin = (page) => {

  return (dispatch) => {
    instance
      .post(`/product/returnorderinadmin/?page=${page}`)
      .then((response) => {
        dispatch(getReturnedOrdersAdminSuccess(response.data));
      })
      .catch((error) => {
        dispatch(getReturnedOrdersAdminFail(error));
      });
  };
};

export const getManStockDashboardSuccess = (data) => {
  return {
    type: "GET_MAN_STOCK_DASHBOARD_SUCCESS",
    manStockDashboard: data,
  };
};

export const getManStockDashboardFail = (error) => {
  return {
    type: "GET_MAN_STOCK_DASHBOARD_FAIL",
    error: error,
  };
};

export const getManStockDashboard = (page) => {
  return (dispatch) => {
    instance
      .get(`/product/manufacturestockdashborad/?page=${page}`)
      .then((response) => {
        dispatch(getManStockDashboardSuccess(response.data));
      })
      .catch((error) => {
        dispatch(getManStockDashboardFail(error));
      });
  };
};

export const getDisStockDashboardSuccess = (data) => {
  return {
    type: "GET_DIS_STOCK_DASHBOARD_SUCCESS",
    disStockDashboard: data,
  };
};

export const getDisStockDashboardFail = (error) => {
  return {
    type: "GET_DIS_STOCK_DASHBOARD_FAIL",
    error: error,
  };
};

export const getDisStockDashboard = (page) => {
  return (dispatch) => {
    instance
      .get(`/product/distributorstockdashboard/?page=${page}`)
      .then((response) => {
        dispatch(getDisStockDashboardSuccess(response.data));
      })
      .catch((error) => {
        dispatch(getDisStockDashboardFail(error));
      });
  };
};

export const getDeliveryNoteSuccess = (data) => {
  return {
    type: "GET_DELIVERY_NOTE_SUCCESS",
    deliveryNote: data,
  };
};

export const getDeliveryNoteFail = (error) => {
  return {
    type: "GET_DELIVERY_NOTE_FAIL",
    error: error,
  };
};

export const getDeliveryNote = (orderId) => {
  return (dispatch) => {
    instance
      .get(`/product/delivery/${orderId}`,
        {
          responseType: 'blob',
        }
      )
      .then((response) => {
        dispatch(getDeliveryNoteSuccess(response.data));
      })
      .catch((error) => {
        dispatch(getDeliveryNoteFail(error));
      });
  };
};

export const getInvoiceSuccess = (data) => {
  return {
    type: "GET_INVOICE_SUCCESS",
    invoice: data,
  };
};

export const getInvoiceFail = (error) => {
  return {
    type: "GET_INVOICE_FAIL",
    error: error,
  };
};

export const getInvoice = (orderId) => {
  return (dispatch) => {
    instance
      .get(`/product/invoice/${orderId}`, {
        responseType: 'blob',
      })
      .then((response) => {
        dispatch(getInvoiceSuccess(response.data));
      })
      .catch((error) => {
        dispatch(getInvoiceFail(error));
      });
  };
};


export const getTempStockSuccess = (data) => {
  return {
    type: "GET_TEMP_STOCK_SUCCESS",
    tempStock: data,
  };
};

export const getTempStockFail = (error) => {
  return {
    type: "GET_TEMP_STOCK_FAIL",
    error: error,
  };
};

export const getTempStock = () => {
  return (dispatch) => {
    instance
      .get("/product/tempstock/")
      .then((response) => {
        dispatch(getTempStockSuccess(response.data));
      })
      .catch((error) => {
        dispatch(getTempStockFail(error));
      });
  };
};

export const cancelReturnOrderSuccess = (data) => {
  return {
    type: "CANCEL_RETURN_ORDER_SUCCESS",
    cancelReturnOrderStatus: data,
  };
};

export const cancelReturnOrderFail = (error) => {
  return {
    type: "CANCEL_RETURN_ORDER_FAIL",
    error: error,
  };
};

export const cancelReturnOrder = (id) => {
  return (dispatch) => {
    instance
      .put(`/product/returncancel/${id}/`)
      .then((response) => {
        console.log(response)
        dispatch(cancelReturnOrderSuccess(response.data.success));
      })
      .catch((error) => {
        console.log(error.response)
        dispatch(cancelReturnOrderFail(error));
      });
  };
};

export const clearCancelReturnOrderStatus = () => {
  return {
    type: "CLEAR_CANCEL_RETURN_ORDER_STATUS",
  };
};

export const clearMyReturns = () => {
  return {
    type: "CLEAR_MY_RETURNS",
  };
};

export const getRefundTotalSuccess = (data) => {
  return {
    type: "GET_REFUND_TOTAL_SUCCESS",
    refundTotal: data,
  };
};

export const getRefundTotalFail = (error) => {
  return {
    type: "GET_REFUND_TOTAL_FAIL",
    error: error,
  };
};

export const getRefundTotal = () => {
  return (dispatch) => {
    instance
      .get("/product/refundtotal/")
      .then((response) => {
        dispatch(getRefundTotalSuccess(response.data));
      })
      .catch((error) => {
        dispatch(getRefundTotalFail(error));
      });
  };
};

export const clearRefundTotal = () => {
  return {
    type: "CLEAR_REFUND_TOTAL",
  };
};

export const getAdminRefundTotalSuccess = (data) => {
  return {
    type: "GET_ADMIN_REFUND_TOTAL_SUCCESS",
    adminRefundTotal: data,
  };
};

export const getAdminRefundTotalFail = (error) => {
  return {
    type: "GET_ADMIN_REFUND_TOTAL_FAIL",
    error: error,
  };
};

export const getAdminRefundTotal = () => {
  return (dispatch) => {
    instance
      .get("/product/refundtotaladmin/")
      .then((response) => {
        dispatch(getAdminRefundTotalSuccess(response.data));
      })
      .catch((error) => {
        dispatch(getAdminRefundTotalFail(error));
      });
  };
};

export const clearAdminRefundTotal = () => {
  return {
    type: "CLEAR_ADMIN_REFUND_TOTAL",
  };
};

export const getRefundsInUsersSuccess = (data) => {
  return {
    type: "GET_REFUNDS_IN_USERS_SUCCESS",
    refundsInUsers: data,
  };
};

export const getRefundsInUsersFail = (error) => {
  return {
    type: "GET_REFUNDS_IN_USERS_FAIL",
    error: error,
  };
};

export const getRefundsInUsers = (page) => {
  return (dispatch) => {
    instance
      .get(`/product/refundsinuser/?page=${page}`)
      .then((response) => {
        dispatch(getRefundsInUsersSuccess(response.data));
      })
      .catch((error) => {
        console.log(error.response)
        dispatch(getRefundsInUsersFail(error));
      });
  };
};

export const getSettlementsInusersSuccess = (data) => {
  return {
    type: "GET_SETTLEMENTS_IN_USERS_SUCCESS",
    settlementsInUsers: data,
  };
};

export const getSettlementsInusersFail = (error) => {
  return {
    type: "GET_SETTLEMENTS_IN_USERS_FAIL",
    error: error,
  };
};

export const getSettlementsInusers = (page) => {
  return (dispatch) => {
    instance
      .get(`/product/settelmentinuser/?page=${page}`)
      .then((response) => {
        dispatch(getSettlementsInusersSuccess(response.data));
      })
      .catch((error) => {
        dispatch(getSettlementsInusersFail(error));
      });
  };
};

export const getRefundsInAdminSuccess = (data) => {
  return {
    type: "GET_REFUNDS_IN_ADMIN_SUCCESS",
    refundsInAdmin: data,
  };
};

export const getRefundsInAdminFail = (error) => {
  return {
    type: "GET_REFUNDS_IN_ADMIN_FAIL",
    error: error,
  };
};

export const getRefundsInAdmin = (page) => {
  return (dispatch) => {
    instance
      .get(`/product/refundsinadmin/?page=${page}`)
      .then((response) => {
        dispatch(getRefundsInAdminSuccess(response.data));
      })
      .catch((error) => {
        dispatch(getRefundsInAdminFail(error));
      });
  };
};

export const getSettlementsInAdminSuccess = (data) => {
  return {
    type: "GET_SETTLEMENTS_IN_ADMIN_SUCCESS",
    settlementsInAdmin: data,
  };
};

export const getSettlementsInAdminFail = (error) => {
  return {
    type: "GET_SETTLEMENTS_IN_ADMIN_FAIL",
    error: error,
  };
};

export const getSettlementsInAdmin = (page) => {
  return (dispatch) => {
    instance
      .get(`/product/settelmentinadmin/?page=${page}`)
      .then((response) => {
        dispatch(getSettlementsInAdminSuccess(response.data));
      })
      .catch((error) => {
        console.log(error.response)
        dispatch(getSettlementsInAdminFail(error));
      });
  };
};