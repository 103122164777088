import React, { Component } from "react";
import { Card, Grid, Button } from "@material-ui/core";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as actions from "../../redux/actions/Index";
import { message } from "antd";
import "antd/dist/antd.css";

class ForgotPassword extends Component {
  state = {
    email: "",
  };

  handleChange = (event) => {
    event.persist();
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  resetHandler = () => {
    if (!this.state.email) {
      this.setState({ validationError: "Email is required" });
    } else {
      this.props.resetPassword(this.state.email);
    }
  };

  success = () => {
    message.success("Password sent to email", 5);
    this.props.history.push("/session/signin");
  };

  render() {
    if (this.props.forgotPasswordStatus === "true") {
      this.success();
    }
    let { email } = this.state;

    return (
      <div className="signup flex flex-center w-100 h-100vh">
        <div className="p-8" style={{ width: "50%" }}>
          <Card className="signup-card position-relative y-center">
            <div className="p-36 h-100 bg-light-gray">
              <Grid container direction="row">
                <Grid item md={10}>
                  <div className="mb-20">
                    <h4>Forgot password</h4>
                  </div>
                </Grid>
                <Grid item md={2}>
                  <Button
                    style={{ marginBottom: "20px" }}
                    variant="outlined"
                    color="secondary"
                    className="capitalize"
                    onClick={() => this.props.history.push("/session/signin")}
                  >
                    Sign in
                  </Button>
                </Grid>
              </Grid>
              <ValidatorForm ref="form" onSubmit={this.handleFormSubmit}>
                <TextValidator
                  className="mb-24 w-100"
                  variant="outlined"
                  label="Email"
                  onChange={this.handleChange}
                  type="email"
                  name="email"
                  value={email}
                  validators={["required", "isEmail"]}
                  errorMessages={[
                    "this field is required",
                    "email is not valid",
                  ]}
                />
                <div className="flex flex-middle">
                  <Button
                    onClick={this.resetHandler}
                    variant="contained"
                    color="primary"
                    type="submit"
                    style={{ width: "100%" }}
                  >
                    Reset Password
                  </Button>
                </div>
              </ValidatorForm>
            </div>
          </Card>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  forgotPasswordStatus: state.users.forgotPasswordStatus,
});

const mapDispatchToProps = (dispatch) => {
  return {
    resetPassword: (email) => dispatch(actions.forgotPassword(email)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)
);
