import { MatxLoadable } from "matx";

const Brands = MatxLoadable({
  loader: () => import("./Brands"),
});

const AddBrands = MatxLoadable({
  loader: () => import("./AddBrands"),
});

const AddModel = MatxLoadable({
  loader: () => import("./AddModel"),
});

const BrandRoutes = [
  {
    path: "/brands",
    component: Brands,
  },
  {
    path: "/addbrands",
    component: AddBrands,
  },
  {
    path: "/models",
    component: AddModel,
  },
];

export default BrandRoutes;
