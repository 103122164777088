import { updatedObject } from "../Utility";

const initialState = {
  error: null,
  loading: false,
  token: "",
  categories: "",
  allCategories: "",
  Varients: [],
  VarientOptions: [],
  Units: [],
  postCatSuccess: "",
  addedData: "",
  postVarientStatus: "",
  editResponse: "",
  categoryDeleteStatus: "",
  editVariantRespone: "",
  editVariantOptionRespone: "",
  addVariantOptionResponse: "",
  addVariantResponse: "",
  editVariantResponse: "",
  taxes: "",
  updateTaxStatus: "",
  postUnitStatus: "",
  editUnitStatus: "",
  editCategoryError: "",
  postUnitError: "",
  postCategoryError: "",
  postAddVariantError: "",
  postAddVariantOptionError: "",
};

const postCategoryStart = (state, action) => {
  return updatedObject(state, {
    loading: true,
    error: null,
  });
};

const postCategorySuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    postCatSuccess: action.successStatus,
    addedData: action.data,
  });
};

const postCategoryFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    postCategoryError: action.error,
  });
};

const postVarientStart = (state, action) => {
  return updatedObject(state, {
    loading: true,
    error: null,
  });
};

const editCategorySuccess = (state, action) => {
  return updatedObject(state, {
    editResponse: action.response,
  });
};

const editCategoryFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    editCategoryError: action.error,
  });
};

const clearEditResponse = (state, action) => {
  return updatedObject(state, {
    editResponse: null,
  });
};

const postVarientSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    postVarientStatus: action.status,
  });
};

const postVarientFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const clearCategories = (state, action) => {
  return updatedObject(state, {
    categories: [],
  });
};

const clearSuccessStatus = (state, action) => {
  return updatedObject(state, {
    postCatSuccess: "",
  });
};

const getCategoryStart = (state, action) => {
  return updatedObject(state, {
    loading: true,
    error: null,
  });
};

const getCategorySuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    categories: action.categoryData,
  });
};

const getCategoryFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const getAllCategoriesStart = (state, action) => {
  return updatedObject(state, {
    loading: true,
    error: null,
  });
};

const getAllCategoriesSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    allCategories: action.allCategoryData,
  });
};

const getAllCategoriesFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const getVarientsStart = (state, action) => {
  return updatedObject(state, {
    loading: true,
    error: null,
  });
};

const getVarientsSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    Varients: action.Varients,
  });
};

const getVarientsFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const getVarientOptionsStart = (state, action) => {
  return updatedObject(state, {
    loading: true,
    error: null,
  });
};

const getVarientOptionsSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    VarientOptions: action.VarientOptions,
  });
};

const getVarientOptionsFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const getUnitsStart = (state, action) => {
  return updatedObject(state, {
    loading: true,
    error: null,
  });
};

const getUnitsSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    Units: action.Units,
  });
};

const getUnitsFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const deleteCategoryStart = (state, action) => {
  return updatedObject(state, {
    loading: true,
    error: null,
  });
};

const deleteCategorySuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    categoryDeleteStatus: action.status,
  });
};

const deleteCategoryFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const clearCategoryDeleteStatus = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
    categoryDeleteStatus: "",
  });
};

const postEditVarientOptionStart = (state, action) => {
  return updatedObject(state, {
    loading: true,
    error: null,
  });
};

const postEditVariantOptionSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    editVariantOptionRespone: action.response,
  });
};

const postEditVariantOptionFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const clearVariantOptionEditResponse = (state, action) => {
  return updatedObject(state, {
    editVariantOptionRespone: "",
  });
};

const postAddVarientOptionStart = (state, action) => {
  return updatedObject(state, {
    loading: true,
    error: null,
  });
};

const postAddVariantOptionSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    addVariantOptionResponse: action.response,
  });
};

const postAddVariantOptionFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    postAddVariantOptionError: action.error,
  });
};

const clearVariantOptionAddResponse = (state, action) => {
  return updatedObject(state, {
    addVariantOptionResponse: "",
  });
};

const postAddVariantStart = (state, action) => {
  return updatedObject(state, {
    loading: true,
    error: null,
  });
};

const postAddVariantSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    addVariantResponse: action.response,
  });
};

const postAddVariantFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: true,
    postAddVariantError: action.error,
  });
};

const clearVariantAddResponse = (state, action) => {
  return updatedObject(state, {
    addVariantResponse: ""
  });
};

const clearVariantAddFailResponse = (state, action) => {
  return updatedObject(state, {
    postAddVariantError: ""
  });
};

const postEditVariantStart = (state, action) => {
  return updatedObject(state, {
    loading: true,
    error: null,
  });
};

const postEditVariantSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    editVariantResponse: action.response,
  });
};

const postEditVariantFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const clearVariantEditResponse = (state, action) => {
  return updatedObject(state, {
    editVariantResponse: "",
  });
};

const getTaxSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    taxes: action.tax,
  });
};

const getTaxFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const updateTaxSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    updateTaxStatus: action.status,
  });
};

const updateTaxFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const clearTaxes = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    taxes: "",
  });
};

const clearUpdateTaxStatus = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    updateTaxStatus: "",
  });
};

const postUnitSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    postUnitStatus: action.postUnitStatus,
  });
};

const postUnitFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    postUnitError: action.error,
  });
};

const editUnitSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    editUnitStatus: action.editUnitStatus,
  });
};

const editUnitFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const clearPostEditUnitStatus = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    postUnitStatus: "",
    editUnitStatus: "",
  });
};

const clearUnitData = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    Units: "",
  });
};

const clearAllCategories = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    allCategories: "",
  });
};

const categoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case "POST_CATEGORY_START":
      return postCategoryStart(state, action);
    case "POST_CATEGORY_SUCCESS":
      return postCategorySuccess(state, action);
    case "POST_CATEGORY_FAIL":
      return postCategoryFail(state, action);
    case "POST_VARIENT_START":
      return postVarientStart(state, action);
    case "POST_VARIENT_SUCCESS":
      return postVarientSuccess(state, action);
    case "POST_VARIENT_FAIL":
      return postVarientFail(state, action);
    case "GET_CATEGORY_START":
      return getCategoryStart(state, action);
    case "GET_CATEGORY_SUCCESS":
      return getCategorySuccess(state, action);
    case "GET_CATEGORY_FAIL":
      return getCategoryFail(state, action);
    case "GET_VARIENTS_START":
      return getVarientsStart(state, action);
    case "GET_VARIENTS_SUCCESS":
      return getVarientsSuccess(state, action);
    case "GET_VARIENTS_FAIL":
      return getVarientsFail(state, action);
    case "GET_VARIENT_OPTIONS_START":
      return getVarientOptionsStart(state, action);
    case "GET_VARIENT_OPTIONS_SUCCESS":
      return getVarientOptionsSuccess(state, action);
    case "GET_VARIENT_OPTIONS_FAIL":
      return getVarientOptionsFail(state, action);
    case "GET_UNITS_START":
      return getUnitsStart(state, action);
    case "GET_UNITS_SUCCESS":
      return getUnitsSuccess(state, action);
    case "GET_UNITS_FAIL":
      return getUnitsFail(state, action);
    case "CLEAR_CATEGORIES":
      return clearCategories(state, action);
    case "CLEAR_SUCCESS_STATUS":
      return clearSuccessStatus(state, action);
    case "GET_ALL_CATEGORIES_START":
      return getAllCategoriesStart(state, action);
    case "GET_ALL_CATEGORIES_SUCCESS":
      return getAllCategoriesSuccess(state, action);
    case "GET_ALL_CATEGORIES_FAIL":
      return getAllCategoriesFail(state, action);
    case "EDIT_CATEGORY_SUCCESS":
      return editCategorySuccess(state, action);
    case "CLEAR_EDIT_RESPONSE":
      return clearEditResponse(state, action);
    case "DELETE_CATEGORY_START":
      return deleteCategoryStart(state, action);
    case "DELETE_CATEGORY_SUCCESS":
      return deleteCategorySuccess(state, action);
    case "DELETE_CATEGORY_FAIL":
      return deleteCategoryFail(state, action);
    case "CLEAR_CATEGORY_DELETE_STATUS":
      return clearCategoryDeleteStatus(state, action);
    case "POST_EDIT_VARIANT_OPTION_START":
      return postEditVarientOptionStart(state, action);
    case "POST_EDIT_VARIANT_OPTION_SUCCESS":
      return postEditVariantOptionSuccess(state, action);
    case "POST_EDIT_VARIANT_OPTION_FAIL":
      return postEditVariantOptionFail(state, action);
    case "CLEAR_VARIANT_OPTION_EDIT_RESPONSE":
      return clearVariantOptionEditResponse(state, action);
    case "POST_ADD_VARIANT_OPTION_START":
      return postAddVarientOptionStart(state, action);
    case "POST_ADD_VARIANT_OPTION_SUCCESS":
      return postAddVariantOptionSuccess(state, action);
    case "POST_ADD_VARIANT_OPTION_FAIL":
      return postAddVariantOptionFail(state, action);
    case "CLEAR_VARIANT_OPTION_ADD_RESPONSE":
      return clearVariantOptionAddResponse(state, action);
    case "POST_ADD_VARIANT_START":
      return postAddVariantStart(state, action);
    case "POST_ADD_VARIANT_SUCCESS":
      return postAddVariantSuccess(state, action);
    case "POST_ADD_VARIANT_FAIL":
      return postAddVariantFail(state, action);
    case "CLEAR_VARIANT_ADD_RESPONSE":
      return clearVariantAddResponse(state, action);
    case "CLEAR_VARIANT_ADD_FAIL_RESPONSE":
      return clearVariantAddFailResponse(state, action);
    case "POST_EDIT_VARIANT_START":
      return postEditVariantStart(state, action);
    case "POST_EDIT_VARIANT_SUCCESS":
      return postEditVariantSuccess(state, action);
    case "POST_EDIT_VARIANT_FAIL":
      return postEditVariantFail(state, action);
    case "CLEAR_VARIANT_EDIT_RESPONSE":
      return clearVariantEditResponse(state, action);
    case "GET_TAX_SUCCESS":
      return getTaxSuccess(state, action);
    case "GET_TAX_FAIL":
      return getTaxFail(state, action);
    case "UPDATE_TAX_SUCCESS":
      return updateTaxSuccess(state, action);
    case "UPDATE_TAX_FAIL":
      return updateTaxFail(state, action);
    case "CLEAR_TAXES":
      return clearTaxes(state, action);
    case "CLEAR_UPDATE_TAX_STATUS":
      return clearUpdateTaxStatus(state, action);
    case "POST_UNIT_SUCCESS":
      return postUnitSuccess(state, action);
    case "POST_UNIT_FAIL":
      return postUnitFail(state, action);
    case "EDIT_UNIT_SUCCESS":
      return editUnitSuccess(state, action);
    case "EDIT_UNIT_FAIL":
      return editUnitFail(state, action);
    case "CLEAR_POST_EDIT_UNIT_STATUS":
      return clearPostEditUnitStatus(state, action);
    case "CLEAR_UNIT_DATA":
      return clearUnitData(state, action);
    case "EDIT_CATEGORY_FAIL":
      return editCategoryFail(state, action);
    case "CLEAR_ALL_CATEGORIES":
      return clearAllCategories(state, action);
    default:
      return state;
  }
};

export default categoryReducer;
