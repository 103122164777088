import { updatedObject } from "../Utility";

const initialState = {
  paymentSuccess: "",
  paymentOrderId: "",
};

const postOrderinPaymentSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    paymentSuccess: action.paymentSuccess,
    paymentOrderId: action.paymentOrderId,
  });
};

const postOrderinPaymentFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const PaymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case "POST_ORDER_IN_PAYMENT_SUCCESS":
      return postOrderinPaymentSuccess(state, action);
    case "POST_ORDER_IN_PAYMENT_FAIL":
      return postOrderinPaymentFail(state, action);
    default:
      return state;
  }
};

export default PaymentReducer;
