import { updatedObject } from "../Utility";

const initialState = {
  commissionTypes: "",
  postCommissionTypeStatus: "",
  addCommissionStatus: "",
  updateCommissionsStatus: "",
  productCommissionType: "",
  updateConCommissionsStatus: "",
  postConCommissionTypeStatus: "",
  unitConCommissions: "",
  editConCommissionTypeStatus: "",
  commissions: "",
  commissionAllProducts: "",
  commissionsFailError: "",
  conCommissionsFailError: "",
  allProductsCommissionStatus: "",
};

const getCommissionTypesSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    commissionTypes: action.commissionTypes,
  });
};

const getCommissionTypesFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const postCommissionTypeSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    postCommissionTypeStatus: action.postCommissionTypeStatus,
  });
};

const postCommissionTypeFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const addCommissionSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    addCommissionStatus: action.addCommissionStatus,
  });
};

const addCommissionFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const clearAddCommissionStatusStatus = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    addCommissionStatus: "",
  });
};

const updateCommissionsSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    updateCommissionsStatus: action.updateCommissionsStatus,
  });
};

const updateCommissionsFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    commissionsFailError: action.commissionsFailError,
  });
};

const clearUpdateCommissionsStatusStatus = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    updateCommissionsStatus: "",
    updateConCommissionsStatus: "",
    postConCommissionTypeStatus: "",
    editConCommissionTypeStatus: "",
  });
};

const getProductCommissionTypeSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    productCommissionType: action.productCommissionType,
  });
};

const getProductCommissionTypeFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const updateConCommissionsSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    updateConCommissionsStatus: action.updateConCommissionsStatus,
  });
};

const updateConCommissionsFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    conCommissionsFailError: action.conCommissionsFailError,
  });
};

const postConCommissionTypeSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    postConCommissionTypeStatus: action.postConCommissionTypeStatus,
  });
};

const postConCommissionTypeFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const getConCommissionsSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    unitConCommissions: action.unitConCommissions,
  });
};

const getConCommissionsFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const editConCommissionTypeSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    editConCommissionTypeStatus: action.editConCommissionTypeStatus,
  });
};

const editConCommissionTypeFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const getCommissionsSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    commissions: action.commissions,
  });
};

const getCommissionsFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const clearOldData = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    unitConCommissions: "",
    commissions: "",
    productCommissionType: "",
  });
};

const postCommissionProfileAllProductsSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    commissionAllProducts: action.commissionAllProducts,
  });
};

const postCommissionProfileAllProductsFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const postAllProductsCommissionSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    allProductsCommissionStatus: action.allProductsCommissionStatus,
  });
};

const postAllProductsCommissionFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const clearAllProductsCommissionStatus = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    allProductsCommissionStatus: "",
  });
};

const CommissionReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_COMMISSION_TYPES_SUCCESS":
      return getCommissionTypesSuccess(state, action);
    case "GET_COMMISSION_TYPES_FAIL":
      return getCommissionTypesFail(state, action);
    case "POST_COMMISSION_TYPE_SUCCESS":
      return postCommissionTypeSuccess(state, action);
    case "POST_COMMISSION_TYPE_FAIL":
      return postCommissionTypeFail(state, action);
    case "ADD_COMMISSION_SUCCESS":
      return addCommissionSuccess(state, action);
    case "ADD_COMMISSION_FAIL":
      return addCommissionFail(state, action);
    case "CLEAR_ADD_COMMISSION_STATUS":
      return clearAddCommissionStatusStatus(state, action);
    case "UPDATE_COMMISSIONS_SUCCESS":
      return updateCommissionsSuccess(state, action);
    case "UPDATE_COMMISSIONS_FAIL":
      return updateCommissionsFail(state, action);
    case "CLEAR_UPDATE_COMMISSIONS_STATUS":
      return clearUpdateCommissionsStatusStatus(state, action);
    case "GET_PRODUCT_COMMISSION_TYPE_SUCCESS":
      return getProductCommissionTypeSuccess(state, action);
    case "GET_PRODUCT_COMMISSION_TYPE_FAIL":
      return getProductCommissionTypeFail(state, action);
    case "UPDATE_CON_COMMISSIONS_SUCCESS":
      return updateConCommissionsSuccess(state, action);
    case "UPDATE_CON_COMMISSIONS_FAIL":
      return updateConCommissionsFail(state, action);
    case "POST_CON_COMMISSION_TYPE_SUCCESS":
      return postConCommissionTypeSuccess(state, action);
    case "POST_CON_COMMISSION_TYPE_FAIL":
      return postConCommissionTypeFail(state, action);
    case "GET_CON_COMMISSIONS_SUCCESS":
      return getConCommissionsSuccess(state, action);
    case "GET_CON_COMMISSIONS_FAIL":
      return getConCommissionsFail(state, action);
    case "EDIT_CON_COMMISSION_TYPE_SUCCESS":
      return editConCommissionTypeSuccess(state, action);
    case "EDIT_CON_COMMISSION_TYPE_FAIL":
      return editConCommissionTypeFail(state, action);
    case "GET_COMMISSIONS_SUCCESS":
      return getCommissionsSuccess(state, action);
    case "GET_COMMISSIONS_FAIL":
      return getCommissionsFail(state, action);
    case "CLEAR_OLD_DATA":
      return clearOldData(state, action);
    case "POST_COMMISSION_PROFILE_ALL_PRODUCT_SUCCESS":
      return postCommissionProfileAllProductsSuccess(state, action);
    case "POST_COMMISSION_PROFILE_ALL_PRODUCT_FAIL":
      return postCommissionProfileAllProductsFail(state, action);
    case "POST_ALL_PRODUCT_COMMISSION_SUCCESS":
      return postAllProductsCommissionSuccess(state, action);
    case "POST_ALL_PRODUCT_COMMISSION_FAIL":
      return postAllProductsCommissionFail(state, action);
    case "CLEAR_ALLPRODUCT_COMMISSION_STATUS":
      return clearAllProductsCommissionStatus(state, action);
    default:
      return state;
  }
};

export default CommissionReducer;
