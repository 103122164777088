import { MatxLoadable } from "matx";

const ManufacturerList = MatxLoadable({
  loader: () => import("./ManufacturerList"),
});

const ManufacturerProducts = MatxLoadable({
  loader: () => import("./ManufacturerProducts"),
});

const ManufacturerRoutes = [
  {
    path: "/manufacturerlist",
    component: ManufacturerList,
  },
  {
    path: "/manufacturerproducts",
    component: ManufacturerProducts,
  },
];

export default ManufacturerRoutes;
