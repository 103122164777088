import Axios from "axios";
import { instance } from "../ProductApi";
import CryptoJS from "crypto-js";

const axios = Axios.create({
  baseURL: process.env.REACT_APP_PRODUCT_API,
  headers: {
    Accept: "application/json",
  },
});

const crypto = require("crypto");

let order_id = "";
let thisOrderPayId = "";
const key_secret = "X7txaPHSHAPF74Fx98PmYb9q";

export const postOrderinPaymentSuccess = (
  razorpay_payment_id,
  razorpay_order_id,
  razorpay_signature
) => {
  const generated_signature = CryptoJS.HmacSHA256(
    order_id + "|" + razorpay_payment_id,
    key_secret
  ).toString(CryptoJS.enc.Hex);
  if (generated_signature === razorpay_signature) {
    return {
      type: "POST_ORDER_IN_PAYMENT_SUCCESS",
      paymentSuccess: true,
      paymentOrderId: thisOrderPayId,
    };
  }
};

export const postOrderinPaymentFail = (error) => {
  return {
    type: "POST_ORDER_IN_PAYMENT_FAIL",
    error: error,
  };
};

export const postOrderinPayment = (data, oid) => {
  thisOrderPayId = oid;
  return (dispatch) => {
    instance
      .post(`/product/razorpayorder/${oid}/`, data)
      .then((response) => {
        order_id = response.data.data.id;
        const script = document.createElement("script");
        script.src = "https://checkout.razorpay.com/v1/checkout.js";
        document.body.appendChild(script);
        var options = {
          key: "rzp_test_kOh071QJHDuyA9",
          name: "ECAPS",
          description: `Transaction for order ${oid}`,
          order_id: response.data.data.id,
          handler: function (response) {
            dispatch(
              postOrderinPaymentSuccess(
                response.razorpay_payment_id,
                response.razorpay_order_id,
                response.razorpay_signature
              )
            );
            const data = {
              orderid: parseInt(oid),
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_order_id: response.razorpay_order_id,
              razorpay_signature: response.razorpay_signature,
            };
            instance
              .post("/product/paymentid/", data)
              .then((response) => {
              })
              .catch((error) => {
              });
          },
          theme: {
            color: "#3399cc",
          },
        };
        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
      })
      .catch((error) => {
        dispatch(postOrderinPaymentFail(error));
      });
  };
};
