import Axios from "axios";
import { instance } from "../ProductApi";

const axios = Axios.create({
  baseURL: process.env.REACT_APP_PRODUCT_API,
  headers: {
    Accept: "application/json",
  },
});

export const getBrandsSuccess = (data) => {
  return {
    type: "GET_BRANDS_SUCCESS",
    brands: data,
  };
};

export const getBrandsFail = (error) => {
  return {
    type: "GET_BRANDS_FAIL",
    error: error,
  };
};

export const getBrands = () => {
  return (dispatch) => {
    instance
      .get("/product/brand/")
      .then((response) => {
        dispatch(getBrandsSuccess(response.data.data));
      })
      .catch((error) => {
        dispatch(getBrandsFail(error));
      });
  };
};

export const postBrandSuccess = (status) => {
  return {
    type: "POST_BRAND_SUCCESS",
    status: status,
  };
};

export const postBrandFail = (error) => {
  return {
    type: "POST_BRAND_FAIL",
    postBrandError: error,
  };
};

export const postBrand = (brand) => {
  return (dispatch) => {
    instance
      .post("/product/brand/", brand)
      .then((response) => {
        dispatch(postBrandSuccess(response.data.success));
      })
      .catch((error) => {
        dispatch(postBrandFail(error.response.data.message));
      });
  };
};

export const postModelSuccess = (status) => {
  return {
    type: "POST_MODEL_SUCCESS",
    status: status,
  };
};

export const postModelFail = (error) => {
  return {
    type: "POST_MODEL_FAIL",
    postModelError: error,
  };
};

export const postModel = (model) => {
  return (dispatch) => {
    instance
      .post("/product/brandmodel/", model)
      .then((response) => {
        dispatch(postModelSuccess(response.data.success));
      })
      .catch((error) => {
        dispatch(postModelFail(error.response.data && error.response.data.message));
      });
  };
};

export const clearPostBrandStatus = () => {
  return {
    type: "CLEAR_POST_BRAND_STATUS",
  };
};

export const clearBrands = () => {
  return {
    type: "CLEAR_BRANDS",
  };
};

export const clearPostModelStatus = () => {
  return {
    type: "CLEAR_POST_MODEL_STATUS",
  };
};

export const getBrandSuccess = (data) => {
  return {
    type: "GET_BRAND_SUCCESS",
    BrandModel: data,
  };
};

export const getBrandFail = (error) => {
  return {
    type: "GET_BRAND_FAIL",
    error: error,
  };
};

export const getBrand = () => {
  return (dispatch) => {
    instance
      .get("/product/getbrand/")
      .then((response) => {
        dispatch(getBrandSuccess(response.data));
      })
      .catch((error) => {
        dispatch(getBrandFail(error));
      });
  };
};

export const editBrandSuccess = (status) => {
  return {
    type: "EDIT_BRAND_SUCCESS",
    status: status,
  };
};

export const editBrandFail = (error) => {
  return {
    type: "EDIT_BRAND_FAIL",
    editBrandError: error,
  };
};

export const editBrand = (brand) => {
  return (dispatch) => {
    instance
      .put("/product/brand/", brand)
      .then((response) => {
        dispatch(editBrandSuccess(response.data.success));
      })
      .catch((error) => {
        dispatch(editBrandFail(error.response.data.message));
      });
  };
};

export const editModelSuccess = (status) => {
  return {
    type: "EDIT_MODEL_SUCCESS",
    status: status,
  };
};

export const editModelFail = (error) => {
  return {
    type: "EDIT_MODEL_FAIL",
    editModelError: error,
  };
};

export const editModel = (model) => {
  return (dispatch) => {
    instance
      .put("/product/brandmodel/", model)
      .then((response) => {
        dispatch(editModelSuccess(response.data.success));
      })
      .catch((error) => {
        dispatch(editModelFail(error.response.data.message));
      });
  };
};

export const clearUpdateStatus = () => {
  return {
    type: "CLEAR_BRAND_MODEL_UPDATE_STATUS",
  };
};

export const clearModels = () => {
  return {
    type: "CLEAR_MODELS",
  };
};
