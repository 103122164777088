import { updatedObject } from "../Utility";

const initialState = {
  products: "",
  productAddStatus: "",
  addedProductId: "",
  priceAddStatus: "",
  ImageResponse: "",
  manufacturerProducts: "",
  variantAddStatus: "",
  newVariantAddStatus: "",
  newVariantOptionAddStatus: "",
  VideoResponse: "",
  deleteVariantResponse: "",
  deleteImageResponse: "",
  deleteVideoResponse: "",
  productPrices: "",
  editProductPriceStatus: "",
  deletePriceResponse: "",
  editProductStatus: "",
  updateStockStatus: "",
  EcommerceProducts: "",
  manufacturerDesc: "",
  stockDetails: "",
  distributorInstockProducts: "",
  thisDistributorInstockProducts: "",
  manufacturerInstockQuantity: "",
  distributorInstockQuantity: "",
  ecomManufacturerInstockQuantity: "",
  ecomDistributorInstockQuantity: "",
  taxPercentage: "",
  userProfile: "",
  adminManufacturerproducts: "",
  adminDistributorproducts: "",
  disableDistributorStatus: "",
  disableManufacturerStatus: "",
  disableManufacturerProductStatus: "",
  disableDistributorProductStatus: "",
  enableDistributorStatus: "",
  enableManufacturerStatus: "",
  enableManufacturerProductStatus: "",
  enableDistributorProductStatus: "",
  manufacturerProductDetail: "",
  distributorProductDetail: "",
  productImage: "",
  productVideo: "",
  searchResponse: "",
  filterManCategoryProducts: "",
  filterDisCategoryProducts: "",
  manSearchFilteredData: "",
  disSearchFilteredData: "",
  priceAddError: "",
  ProductMediaCount: "",
  checkQuantityResponse: "",
  checkQuantityMessage: "",
};

const postProductStart = (state, action) => {
  return updatedObject(state, {
    loading: true,
    error: null,
  });
};

const postProductSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    productAddStatus: action.addStatus,
    addedProductId: action.productId,
  });
};

const postProductFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const getProductsStart = (state, action) => {
  return updatedObject(state, {
    loading: true,
    error: null,
  });
};

const getProductsSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    products: action.products,
  });
};

const getProductsFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const getEcommerceProductsSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    EcommerceProducts: action.EcommerceProducts,
  });
};

const getEcommerceProductsFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const postProductImageStart = (state, action) => {
  return updatedObject(state, {
    loading: true,
    error: null,
  });
};

const postProductImageSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    ImageResponse: action.response,
  });
};

const postProductImageFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const postProductPriceStart = (state, action) => {
  return updatedObject(state, {
    loading: true,
    error: null,
  });
};

const postProductPriceSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    priceAddStatus: action.status,
  });
};

const postProductPriceFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    priceAddError: action.error,
  });
};

const getManufacturerProductsStart = (state, action) => {
  return updatedObject(state, {
    loading: true,
    error: null,
  });
};

const getManufacturerProductsSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    manufacturerProducts: action.manufacturerProducts,
  });
};

const getManufacturerProductsFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const postProductVariantStart = (state, action) => {
  return updatedObject(state, {
    loading: true,
    error: null,
  });
};

const postProductVariantSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    variantAddStatus: action.status,
  });
};

const postProductVariantFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const postVariantSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    newVariantAddStatus: action.status,
  });
};

const postVariantFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const postVariantOptionSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    newVariantOptionAddStatus: action.status,
  });
};

const postVariantOptionFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const clearVariantAddStatus = (state, action) => {
  return updatedObject(state, {
    variantAddStatus: "",
  });
};
const clearNewVariantAddStatus = (state, action) => {
  return updatedObject(state, {
    newVariantAddStatus: "",
  });
};
const clearNewVariantOptionAddStatus = (state, action) => {
  return updatedObject(state, {
    newVariantOptionAddStatus: "",
  });
};

const clearProductAddStatus = (state, action) => {
  return updatedObject(state, {
    productAddStatus: "",
  });
};

const clearPriceAddStatus = (state, action) => {
  return updatedObject(state, {
    priceAddStatus: "",
    editProductPriceStatus: "",
  });
};

const postProductVideoStart = (state, action) => {
  return updatedObject(state, {
    loading: true,
    error: null,
  });
};

const postProductVideoSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    VideoResponse: action.response,
  });
};

const postProductVideoFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const clearVideoResponse = (state, action) => {
  return updatedObject(state, {
    VideoResponse: "",
  });
};

const clearImageResponse = (state, action) => {
  return updatedObject(state, {
    ImageResponse: "",
  });
};

const deleteProductVariantSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    deleteVariantResponse: action.status,
  });
};

const deleteProductVariantFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const deleteProductImageSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    deleteImageResponse: action.status,
  });
};

const deleteProductImageFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const deleteProductVideoSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    deleteVideoResponse: action.status,
  });
};

const deleteProductVideoFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const getProductPricesSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    productPrices: action.prices,
  });
};

const getProductPricesFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const editProductPriceSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    editProductPriceStatus: action.status,
  });
};

const editProductPriceFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const deleteProductPriceSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    deletePriceResponse: action.response,
  });
};

const deleteProductPriceFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const clearPriceEditStatus = (state, action) => {
  return updatedObject(state, {
    loading: false,
    editProductPriceStatus: "",
  });
};

const clearProductDetails = (state, action) => {
  return updatedObject(state, {
    loading: false,
    manufacturerProducts: "",
  });
};

const clearPriceDeleteResponse = (state, action) => {
  return updatedObject(state, {
    loading: false,
    deletePriceResponse: "",
  });
};

const editProductSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    editProductStatus: action.editStatus,
  });
};

const editProductFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const clearProductEditStatus = (state, action) => {
  return updatedObject(state, {
    loading: false,
    editProductStatus: "",
  });
};

const updateStockSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    updateStockStatus: action.status,
  });
};

const updateStockFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const clearUpdateStockStatus = (state, action) => {
  return updatedObject(state, {
    loading: false,
    updateStockStatus: "",
  });
};

const getManufacturerDescSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    manufacturerDesc: action.manufacturerDesc,
  });
};

const getManufacturerDescFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const getStockDetailsSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    stockDetails: action.stock,
  });
};

const getStockDetailsFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const getDistributorInstockProductsSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    distributorInstockProducts: action.distributorInstockProducts,
  });
};

const getDistributorInstockProductsFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const thisDistributorInstockProductsSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    thisDistributorInstockProducts: action.thisDistributorInstockProducts,
  });
};

const thisDistributorInstockProductsFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const getManufacturerInstockQuantitySuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    manufacturerInstockQuantity: action.instockQuantity,
  });
};

const getManufacturerInstockQuantityFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const getDistributorInstockQuantitySuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    distributorInstockQuantity: action.instockQuantity,
  });
};

const getDistributorInstockQuantityFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const getEcomManufacturerInstockQuantitySuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    ecomManufacturerInstockQuantity: action.instockQuantity,
  });
};

const getEcomManufacturerInstockQuantityFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const getEcomDistributorInstockQuantitySuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    ecomDistributorInstockQuantity: action.instockQuantity,
  });
};

const getEcomDistributorInstockQuantityFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const getTaxPercentageSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    taxPercentage: action.taxPercentage,
  });
};

const getTaxPercentageFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const getUserProfileSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    userProfile: action.userProfile,
  });
};

const getUserProfileFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const getAdminManufacturerProductsSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    adminManufacturerproducts: action.adminManufacturerproducts,
  });
};

const getAdminManufacturerProductsFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const getAdminDistributorProductsSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    adminDistributorproducts: action.adminDistributorproducts,
  });
};

const getAdminDistributorProductsFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const disableDistributorSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    disableDistributorStatus: action.disableDistributorStatus,
  });
};

const disableDistributorFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const disableManufacturerSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    disableManufacturerStatus: action.disableManufacturerStatus,
  });
};

const disableManufacturerFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const disableManufacturerProductSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    disableManufacturerProductStatus: action.disableManufacturerProductStatus,
  });
};

const disableManufacturerProductFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const disableDistributorProductSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    disableDistributorProductStatus: action.disableDistributorProductStatus,
  });
};

const disableDistributorProductFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const clearPrices = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    productPrices: "",
  });
};

const enableDistributorSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    enableDistributorStatus: action.enableDistributorStatus,
  });
};

const enableDistributorFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const enableManufacturerSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    enableManufacturerStatus: action.enableManufacturerStatus,
  });
};

const enableManufacturerFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const enableManufacturerProductSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    enableManufacturerProductStatus: action.enableManufacturerProductStatus,
  });
};

const enableManufacturerProductFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const enableDistributorProductSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    enableDistributorProductStatus: action.enableDistributorProductStatus,
  });
};

const enableDistributorProductFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const clearDisableStatus = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    disableDistributorStatus: "",
    disableManufacturerStatus: "",
    disableManufacturerProductStatus: "",
    disableDistributorProductStatus: "",
    enableDistributorStatus: "",
    enableManufacturerStatus: "",
    enableManufacturerProductStatus: "",
    enableDistributorProductStatus: "",
  });
};

const getProductDetailSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    manufacturerProductDetail: action.manufacturerProductDetail,
  });
};

const getProductDetailFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const getDistributorProductDetailSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    distributorProductDetail: action.distributorProductDetail,
  });
};

const getDistributorProductDetailFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const getProductImageSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    productImage: action.productImage,
  });
};

const getProductImageFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const getProductVideoSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    productVideo: action.productVideo,
  });
};

const getProductVideoFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const postSearchQuerySuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    searchResponse: action.searchResponse,
  });
};

const postSearchQueryFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const filterByCategoryManSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    filterManCategoryProducts: action.filterManCategoryProducts,
  });
};

const filterByCategoryManFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const filterByCategoryDisSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    filterDisCategoryProducts: action.filterDisCategoryProducts,
  });
};

const filterByCategoryDisFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const getManSearchFilteredDataSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    manSearchFilteredData: action.manSearchFilteredData,
  });
};

const getManSearchFilteredDataFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const getDisSearchFilteredDataSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    disSearchFilteredData: action.disSearchFilteredData,
  });
};

const getDisSearchFilteredDataFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const clearAllProducts = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    EcommerceProducts: "",
  });
};

const clearVariantDeleteStatus = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    deleteVariantResponse: "",
  });
};

const clearThisProductDetails = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    manufacturerProductDetail: "",
  });
};

const clearDeleteImageResponse = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    deleteImageResponse: "",
  });
};

const clearDeleteVideoResponse = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    deleteVideoResponse: "",
  });
};

const clearManufacturerProducts = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    manufacturerProducts: "",
  });
};

const clearStockData = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    stockDetails: "",
  });
};

const clearInstockQuantity = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    manufacturerInstockQuantity: "",
  });
};

const clearDistributorProducts = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    thisDistributorInstockProducts: "",
  });
};

const clearManufacturerDesc = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    manufacturerDesc: "",
  });
};

const clearManufacturerInstockQuantity = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    ecomManufacturerInstockQuantity: "",
  });
};

const getProductMediaCountSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    ProductMediaCount: action.ProductMediaCount,
  });
};

const getProductMediaCountFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const checkQuantitySuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    checkQuantityResponse: action.checkQuantityResponse,
    checkQuantityMessage: action.checkQuantityMessage,
  });
};

const checkQuantityFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const clearDistributorProductDetails = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    distributorProductDetail: "",
  });
};

const clearDistributorInstockQuantity = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    ecomDistributorInstockQuantity: "",
  });
};

const clearProductImages = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    productImage: "",
  });
};

const clearProductVideos = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    productVideo: "",
  });
};

const clearCheckQuantityResponse = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    checkQuantityResponse: "",
  });
};

const checkActiveSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    checkActiveResponse: action.checkActiveResponse,
  });
};

const checkActiveFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const clearCheckActive = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    checkActiveResponse: "",
  });
};

const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case "POST_PRODUCT_START":
      return postProductStart(state, action);
    case "POST_PRODUCT_SUCCESS":
      return postProductSuccess(state, action);
    case "POST_PRODUCT_FAIL":
      return postProductFail(state, action);
    case "GET_PRODUCTS_START":
      return getProductsStart(state, action);
    case "GET_PRODUCTS_SUCCESS":
      return getProductsSuccess(state, action);
    case "GET_PRODUCTS_FAIL":
      return getProductsFail(state, action);
    case "GET_ECOMMERCE_PRODUCTS_SUCCESS":
      return getEcommerceProductsSuccess(state, action);
    case "GET_ECOMMERCE_PRODUCTS_FAIL":
      return getEcommerceProductsFail(state, action);
    case "POST_PRODUCT_IMAGE_START":
      return postProductImageStart(state, action);
    case "POST_PRODUCT_IMAGE_SUCCESS":
      return postProductImageSuccess(state, action);
    case "POST_PRODUCT_IMAGE_FAIL":
      return postProductImageFail(state, action);
    case "POST_PRODUCT_PRICE_START":
      return postProductPriceStart(state, action);
    case "POST_PRODUCT_PRICE_SUCCESS":
      return postProductPriceSuccess(state, action);
    case "POST_PRODUCT_PRICE_FAIL":
      return postProductPriceFail(state, action);
    case "GET_MANUFACTURER_PRODUCTS_START":
      return getManufacturerProductsStart(state, action);
    case "GET_MANUFACTURER_PRODUCTS_SUCCESS":
      return getManufacturerProductsSuccess(state, action);
    case "GET_MANUFACTURER_PRODUCTS_FAIL":
      return getManufacturerProductsFail(state, action);
    case "POST_PRODUCT_VARIANT_START":
      return postProductVariantStart(state, action);
    case "POST_PRODUCT_VARIANT_SUCCESS":
      return postProductVariantSuccess(state, action);
    case "POST_PRODUCT_VARIANT_FAIL":
      return postProductVariantFail(state, action);
    case "POST_VARIANT_SUCCESS":
      return postVariantSuccess(state, action);
    case "POST_VARIANT_FAIL":
      return postVariantFail(state, action);
    case "POST_VARIANT_OPTION_SUCCESS":
      return postVariantOptionSuccess(state, action);
    case "POST_VARIANT_OPTION_FAIL":
      return postVariantOptionFail(state, action);
    case "CLEAR_VARIANT_ADD_STATUS":
      return clearVariantAddStatus(state, action);
    case "CLEAR_NEW_VARIANT_ADD_STATUS":
      return clearNewVariantAddStatus(state, action);
    case "CLEAR_NEW_VARIANT_OPTION_ADD_STATUS":
      return clearNewVariantOptionAddStatus(state, action);
    case "CLEAR_PRODUCT_ADD_STATUS":
      return clearProductAddStatus(state, action);
    case "CLEAR_PRICE_ADD_STATUS":
      return clearPriceAddStatus(state, action);
    case "POST_PRODUCT_VIDEO_START":
      return postProductVideoStart(state, action);
    case "POST_PRODUCT_VIDEO_SUCCESS":
      return postProductVideoSuccess(state, action);
    case "POST_PRODUCT_VIDEO_FAIL":
      return postProductVideoFail(state, action);
    case "CLEAR_VIDEO_RESPONSE":
      return clearVideoResponse(state, action);
    case "CLEAR_IMAGE_RESPONSE":
      return clearImageResponse(state, action);
    case "DELETE_PRODUCT_VIDEO_SUCCESS":
      return deleteProductVideoSuccess(state, action);
    case "DELETE_PRODUCT_VIDEO_FAIL":
      return deleteProductVideoFail(state, action);
    case "DELETE_PRODUCT_IMAGE_SUCCESS":
      return deleteProductImageSuccess(state, action);
    case "DELETE_PRODUCT_IMAGE_FAIL":
      return deleteProductImageFail(state, action);
    case "DELETE_PRODUCT_VARIANT_SUCCESS":
      return deleteProductVariantSuccess(state, action);
    case "DELETE_PRODUCT_VARIANT_FAIL":
      return deleteProductVariantFail(state, action);
    case "GET_PRODUCT_PRICES_SUCCESS":
      return getProductPricesSuccess(state, action);
    case "GET_PRODUCT_PRICES_FAIL":
      return getProductPricesFail(state, action);
    case "EDIT_PRODUCT_PRICE_SUCCESS":
      return editProductPriceSuccess(state, action);
    case "EDIT_PRODUCT_PRICE_FAIL":
      return editProductPriceFail(state, action);
    case "DELETE_PRODUCT_PRICE_SUCCESS":
      return deleteProductPriceSuccess(state, action);
    case "DELETE_PRODUCT_PRICE_FAIL":
      return deleteProductPriceFail(state, action);
    case "CLEAR_PRICE_EDIT_STATUS":
      return clearPriceEditStatus(state, action);
    case "CLEAR_PRODUCT_DETAILS":
      return clearProductDetails(state, action);
    case "CLEAR_PRICE_DELETE_RESPONSE":
      return clearPriceDeleteResponse(state, action);
    case "EDIT_PRODUCT_SUCCESS":
      return editProductSuccess(state, action);
    case "EDIT_PRODUCT_FAIL":
      return editProductFail(state, action);
    case "CLEAR_PRODUCT_EDIT_STATUS":
      return clearProductEditStatus(state, action);
    case "UPDATE_STOCK_SUCCESS":
      return updateStockSuccess(state, action);
    case "UPDATE_STOCK_FAIL":
      return updateStockFail(state, action);
    case "CLEAR_UPDATE_STOCK_STATUS":
      return clearUpdateStockStatus(state, action);
    case "GET_MANFACTURER_DESC_SUCCESS":
      return getManufacturerDescSuccess(state, action);
    case "GET_MANFACTURER_DESC_FAIL":
      return getManufacturerDescFail(state, action);
    case "GET_STOCK_DETAILS_SUCCESS":
      return getStockDetailsSuccess(state, action);
    case "GET_STOCK_DETAILS_FAIL":
      return getStockDetailsFail(state, action);
    case "GET_DISTRIBUTOR_INSTOCK_PRODUCTS_SUCCESS":
      return getDistributorInstockProductsSuccess(state, action);
    case "GET_DISTRIBUTOR_INSTOCK_PRODUCTS_FAIL":
      return getDistributorInstockProductsFail(state, action);
    case "THIS_DISTRIBUTOR_INSTOCK_PRODUCTS_SUCCESS":
      return thisDistributorInstockProductsSuccess(state, action);
    case "THIS_DISTRIBUTOR_INSTOCK_PRODUCTS_FAIL":
      return thisDistributorInstockProductsFail(state, action);
    case "GET_MANUFACTURER_INSTOCK_QUANTITY_SUCCESS":
      return getManufacturerInstockQuantitySuccess(state, action);
    case "GET_MANUFACTURER_INSTOCK_QUANTITY_FAIL":
      return getManufacturerInstockQuantityFail(state, action);
    case "GET_DISTRIBUTOR_INSTOCK_QUANTITY_SUCCESS":
      return getDistributorInstockQuantitySuccess(state, action);
    case "GET_DISTRIBUTOR_INSTOCK_QUANTITY_FAIL":
      return getDistributorInstockQuantityFail(state, action);
    case "GET_ECOM_MANUFACTURER_INSTOCK_QUANTITY_SUCCESS":
      return getEcomManufacturerInstockQuantitySuccess(state, action);
    case "GET_ECOM_MANUFACTURER_INSTOCK_QUANTITY_FAIL":
      return getEcomManufacturerInstockQuantityFail(state, action);
    case "GET_ECOM_DISTRIBUTOR_INSTOCK_QUANTITY_SUCCESS":
      return getEcomDistributorInstockQuantitySuccess(state, action);
    case "GET_ECOM_DISTRIBUTOR_INSTOCK_QUANTITY_FAIL":
      return getEcomDistributorInstockQuantityFail(state, action);
    case "GET_TAX_PERCENTAGE_SUCCESS":
      return getTaxPercentageSuccess(state, action);
    case "GET_TAX_PERCENTAGE_FAIL":
      return getTaxPercentageFail(state, action);
    case "GET_USER_PROFILE_SUCCESS":
      return getUserProfileSuccess(state, action);
    case "GET_USER_PROFILE_FAIL":
      return getUserProfileFail(state, action);
    case "GET_ADMIN_MANUFACTURER_PRODUCTS_SUCCESS":
      return getAdminManufacturerProductsSuccess(state, action);
    case "GET_ADMIN_MANUFACTURER_PRODUCTS_FAIL":
      return getAdminManufacturerProductsFail(state, action);
    case "GET_ADMIN_DISTRIBUTOR_PRODUCTS_SUCCESS":
      return getAdminDistributorProductsSuccess(state, action);
    case "GET_ADMIN_DISTRIBUTOR_PRODUCTS_FAIL":
      return getAdminDistributorProductsFail(state, action);
    case "CLEAR_PRICES":
      return clearPrices(state, action);
    case "DISABLE_DISTRIBUTOR_SUCCESS":
      return disableDistributorSuccess(state, action);
    case "DISABLE_DISTRIBUTOR_FAIL":
      return disableDistributorFail(state, action);
    case "DISABLE_MANUFACTURER_SUCCESS":
      return disableManufacturerSuccess(state, action);
    case "DISABLE_MANUFACTURER_FAIL":
      return disableManufacturerFail(state, action);
    case "DISABLE_MANUFACTURER_PRODUCT_SUCCESS":
      return disableManufacturerProductSuccess(state, action);
    case "DISABLE_MANUFACTURER_PRODUCT_FAIL":
      return disableManufacturerProductFail(state, action);
    case "DISABLE_DISTRIBUTOR_PRODUCT_SUCCESS":
      return disableDistributorProductSuccess(state, action);
    case "DISABLE_DISTRIBUTOR_PRODUCT_FAIL":
      return disableDistributorProductFail(state, action);
    case "ENABLE_DISTRIBUTOR_SUCCESS":
      return enableDistributorSuccess(state, action);
    case "ENABLE_DISTRIBUTOR_FAIL":
      return enableDistributorFail(state, action);
    case "ENABLE_MANUFACTURER_SUCCESS":
      return enableManufacturerSuccess(state, action);
    case "ENABLE_MANUFACTURER_FAIL":
      return enableManufacturerFail(state, action);
    case "ENABLE_MANUFACTURER_PRODUCT_SUCCESS":
      return enableManufacturerProductSuccess(state, action);
    case "ENABLE_MANUFACTURER_PRODUCT_FAIL":
      return enableManufacturerProductFail(state, action);
    case "ENABLE_DISTRIBUTOR_PRODUCT_SUCCESS":
      return enableDistributorProductSuccess(state, action);
    case "ENABLE_DISTRIBUTOR_PRODUCT_FAIL":
      return enableDistributorProductFail(state, action);
    case "CLEAR_DISABLE_STATUS":
      return clearDisableStatus(state, action);
    case "GET_PRODUCT_DETAIL_SUCCESS":
      return getProductDetailSuccess(state, action);
    case "GET_PRODUCT_DETAIL_FAIL":
      return getProductDetailFail(state, action);
    case "GET_DISTRIBUTOR_PRODUCT_DETAIL_SUCCESS":
      return getDistributorProductDetailSuccess(state, action);
    case "GET_DISTRIBUTOR_PRODUCT_DETAIL_FAIL":
      return getDistributorProductDetailFail(state, action);
    case "GET_PRODUCT_IMAGE_SUCCESS":
      return getProductImageSuccess(state, action);
    case "GET_PRODUCT_IMAGE_FAIL":
      return getProductImageFail(state, action);
    case "GET_PRODUCT_VIDEO_SUCCESS":
      return getProductVideoSuccess(state, action);
    case "GET_PRODUCT_VIDEO_FAIL":
      return getProductVideoFail(state, action);
    case "POST_SEARCH_QUERY_SUCCESS":
      return postSearchQuerySuccess(state, action);
    case "POST_SEARCH_QUERY_FAIL":
      return postSearchQueryFail(state, action);
    case "FILTER_BYCATEGORY_MAN_SUCCESS":
      return filterByCategoryManSuccess(state, action);
    case "FILTER_BYCATEGORY_MAN_FAIL":
      return filterByCategoryManFail(state, action);
    case "FILTER_BYCATEGORY_DIS_SUCCESS":
      return filterByCategoryDisSuccess(state, action);
    case "FILTER_BYCATEGORY_DIS_FAIL":
      return filterByCategoryDisFail(state, action);
    case "GET_MAN_SEARCH_FILTERED_DATA_SUCCESS":
      return getManSearchFilteredDataSuccess(state, action);
    case "GET_MAN_SEARCH_FILTERED_DATA_FAIL":
      return getManSearchFilteredDataFail(state, action);
    case "GET_DIS_SEARCH_FILTERED_DATA_SUCCESS":
      return getDisSearchFilteredDataSuccess(state, action);
    case "GET_DIS_SEARCH_FILTERED_DATA_FAIL":
      return getDisSearchFilteredDataFail(state, action);
    case "CLEAR_VARIANT_DELETE_STATUS":
      return clearVariantDeleteStatus(state, action);
    case "CLEAR_THIS_PRODUCT_DETAIL":
      return clearThisProductDetails(state, action);
    case "CLEAR_DELETE_IMAGE_RESPONSE":
      return clearDeleteImageResponse(state, action);
    case "CLEAR_DELETE_VIDEO_RESPONSE":
      return clearDeleteVideoResponse(state, action);
    case "CLEAR_MANUFACTURER_PRODUCTS":
      return clearManufacturerProducts(state, action);
    case "CLEAR_STOCK_DATA":
      return clearStockData(state, action);
    case "CLEAR_INSTOCK_QUANTITY":
      return clearInstockQuantity(state, action);
    case "CLEAR_DISTRIBUTOR_PRODUCTS":
      return clearDistributorProducts(state, action);
    case "CLEAR_MAN_DESC":
      return clearManufacturerDesc(state, action);
    case "CLEAR_MAN_INSTOCK":
      return clearManufacturerInstockQuantity(state, action);
    case "GET_PRODUCT_MEDIA_COUNT_SUCCESS":
      return getProductMediaCountSuccess(state, action);
    case "GET_PRODUCT_MEDIA_COUNT_FAIL":
      return getProductMediaCountFail(state, action);
    case "CHECK_QUANTITY_SUCCESS":
      return checkQuantitySuccess(state, action);
    case "CHECK_QUANTITY_FAIL":
      return checkQuantityFail(state, action);
    case "CLEAR_DISTRIBUTOR_PRODUCT_DETAILS":
      return clearDistributorProductDetails(state, action);
    case "CLEAR_DIS_INSTOCK_QUANTITY":
      return clearDistributorInstockQuantity(state, action);
    case "CLEAR_PRODUCT_IMAGES":
      return clearProductImages(state, action);
    case "CLEAR_PRODUCT_VIDEOS":
      return clearProductVideos(state, action);
    case "CLEAR_CHECK_QUANTITY_RESPONSE":
      return clearCheckQuantityResponse(state, action);
    case "CHECK_ACTIVE_SUCCESS":
      return checkActiveSuccess(state, action);
    case "CHECK_ACTIVE_FAIL":
      return checkActiveFail(state, action);
    case "CLEAR_CHECK_ACTIVE":
      return clearCheckActive(state, action);
    default:
      return state;
  }
};

export default productReducer;
