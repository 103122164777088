import { MatxLoadable } from "matx";
import { authRoles } from "../../../auth/authRoles";

const CategoryList = MatxLoadable({
  loader: () => import("./Category-List"),
});

const AddCategories = MatxLoadable({
  loader: () => import("./Categories"),
});

const AddSubCategory = MatxLoadable({
  loader: () => import("./AddSubCategory"),
});

const EditCategory = MatxLoadable({
  loader: () => import("./EditCategory"),
});

const VarientList = MatxLoadable({
  loader: () => import("./VarientList"),
});

const AddVarient = MatxLoadable({
  loader: () => import("./AddVarient"),
});

const CategoryRoutes = [
  {
    path: "/category/categoryList",
    component: CategoryList,
    auth: authRoles.admin,
  },
  {
    path: "/category/addCategories",
    component: AddCategories,
    auth: authRoles.admin,
  },
  {
    path: "/category/addSubCategory",
    component: AddSubCategory,
    auth: authRoles.admin,
  },
  {
    path: "/category/editCategory",
    component: EditCategory,
    auth: authRoles.admin,
  },
  {
    path: "/varient/list",
    component: VarientList,
    auth: authRoles.admin,
  },
  {
    path: "/varient/add",
    component: AddVarient,
    auth: authRoles.admin,
  },
];

export default CategoryRoutes;
