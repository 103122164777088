import { MatxLoadable } from "matx";

const RetailerList = MatxLoadable({
  loader: () => import("./RetailerList"),
});

const RetailerRoutes = [
  {
    path: "/retailerlist",
    component: RetailerList,
  },
];

export default RetailerRoutes;
