import { MatxLoadable } from "matx";
import { authRoles } from "../../../auth/authRoles";

const UserApprove = MatxLoadable({
  loader: () => import("./UserList"),
});

const UserDetail = MatxLoadable({
  loader: () => import("./UserDetail"),
});

const UserApproveRoutes = [
  {
    path: "/user/registered",
    component: UserApprove,
    auth: authRoles.admin,
  },
  {
    path: "/user/userdetail",
    component: UserDetail,
    auth: authRoles.admin,
  },
];

export default UserApproveRoutes;
