import { updatedObject } from "../Utility";

const initialState = {
  cartData: "",
  createCartData: "",
  addToCartStatus: "",
  cartItems: "",
  deleteCartItemStatus: "",
  deleteCartStatus: "",
  orderedItemsDeleteStatus: "",
};

// const postProductStart = (state, action) => {
//     return updatedObject(state, {
//       loading: true,
//       error: null,
//     });
//   };

const getCartSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    cartData: action.cartData,
  });
};

const getCartFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const createCartSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    createCartData: action.cartData,
  });
};

const createCartFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const addToCartSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    addToCartStatus: action.cartItemStatus,
  });
};

const addToCartFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const clearAddToCartStatus = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    addToCartStatus: "",
  });
};

const getCartItemsSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    cartItems: action.cartData,
  });
};

const getCartItemsFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const deleteCartItemSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    deleteCartItemStatus: action.deleteStatus,
  });
};

const deleteCartItemFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const clearCartItemDeleteStatus = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    deleteCartItemStatus: "",
  });
};

const clearCartData = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    cartItems: "",
  });
};

const deleteCartSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    deleteCartStatus: action.deleteStatus,
  });
};

const deleteCartFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const clearCartDeleteStatus = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    deleteCartStatus: "",
  });
};

const clearCreateCartData = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    createCartData: "",
  });
};

const deleteOrderedCartItemsSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    orderedItemsDeleteStatus: action.orderedItemsDeleteStatus,
  });
};

const deleteOrderedCartItemsFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const clearOrderedItemsDeleteStatus = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    orderedItemsDeleteStatus: "",
  });
};

const CartReducer = (state = initialState, action) => {
  switch (action.type) {
    //   case "POST_PRODUCT_START":
    //     return postProductStart(state, action);
    case "GET_CART_SUCCESS":
      return getCartSuccess(state, action);
    case "GET_CART_FAIL":
      return getCartFail(state, action);
    case "CREATE_CART_SUCCESS":
      return createCartSuccess(state, action);
    case "CREATE_CART_FAIL":
      return createCartFail(state, action);
    case "ADD_TO_CART_SUCCESS":
      return addToCartSuccess(state, action);
    case "ADD_TO_CART_FAIL":
      return addToCartFail(state, action);
    case "CLEAR_ADD_TO_CART_STATUS":
      return clearAddToCartStatus(state, action);
    case "GET_CART_ITEMS_SUCCESS":
      return getCartItemsSuccess(state, action);
    case "GET_CART_ITEMS_FAIL":
      return getCartItemsFail(state, action);
    case "DELETE_CART_ITEM_SUCCESS":
      return deleteCartItemSuccess(state, action);
    case "DELETE_CART_ITEM_FAIL":
      return deleteCartItemFail(state, action);
    case "CLEAR_CART_ITEM_DELETE_STATUS":
      return clearCartItemDeleteStatus(state, action);
    case "CLEAR_CART_DATA":
      return clearCartData(state, action);
    case "DELETE_CART_SUCCESS":
      return deleteCartSuccess(state, action);
    case "DELETE_CART_FAIL":
      return deleteCartFail(state, action);
    case "CLEAR_CART_DELETE_STATUS":
      return clearCartDeleteStatus(state, action);
    case "CLEAR_CREATE_CART_DATA":
      return clearCreateCartData(state, action);
    case "DELETE_ORDERED_CART_ITEMS_SUCCESS":
      return deleteOrderedCartItemsSuccess(state, action);
    case "DELETE_ORDERED_CART_ITEMS_FAIL":
      return deleteOrderedCartItemsFail(state, action);
    case "CLEAR_ORDERED_CART_ITEMS_DELETE_STATUS":
      return clearOrderedItemsDeleteStatus(state, action);
    default:
      return state;
  }
};

export default CartReducer;
