import Axios from "axios";
import { instance } from "../ProductApi";

const axios = Axios.create({
  baseURL: process.env.REACT_APP_PRODUCT_API,
  headers: {
    Accept: "application/json",
  },
});

export const getCommissionTypesSuccess = (data) => {
  return {
    type: "GET_COMMISSION_TYPES_SUCCESS",
    commissionTypes: data,
  };
};

export const getCommissionTypesFail = (error) => {
  return {
    type: "GET_COMMISSION_TYPES_FAIL",
    error: error,
  };
};

export const getCommissionTypes = () => {
  return (dispatch) => {
    instance
      .get("/product/allcommisions/")
      .then((response) => {
        dispatch(getCommissionTypesSuccess(response.data.data));
      })
      .catch((error) => {
        dispatch(getCommissionTypesFail(error));
      });
  };
};

export const postCommissionTypeSuccess = (status) => {
  return {
    type: "POST_COMMISSION_TYPE_SUCCESS",
    postCommissionTypeStatus: status,
  };
};

export const postCommissionTypeFail = (error) => {
  return {
    type: "POST_COMMISSION_TYPE_FAIL",
    error: error,
  };
};

export const postCommissionType = (commissionTypeData, type) => {
  return (dispatch) => {
    instance
      .post(`/product/profilecommision/${type}/`, commissionTypeData)
      .then((response) => {
        dispatch(postCommissionTypeSuccess(response.data.data));
      })
      .catch((error) => {
        dispatch(postCommissionTypeFail(error));
      });
  };
};

export const addCommissionSuccess = (status) => {
  return {
    type: "ADD_COMMISSION_SUCCESS",
    addCommissionStatus: status,
  };
};

export const addCommissionFail = (error) => {
  return {
    type: "ADD_COMMISSION_FAIL",
    error: error,
  };
};

export const addCommission = (priceId, commission) => {
  return (dispatch) => {
    instance
      .put(`/product/ecapsprofitonprice/${priceId}/`, commission)
      .then((response) => {
        dispatch(addCommissionSuccess(response.data.success));
      })
      .catch((error) => {
        dispatch(addCommissionFail(error));
      });
  };
};

export const clearAddCommissionStatusStatus = () => {
  return {
    type: "CLEAR_ADD_COMMISSION_STATUS",
  };
};

export const updateCommissionsSuccess = (status) => {
  return {
    type: "UPDATE_COMMISSIONS_SUCCESS",
    updateCommissionsStatus: status,
  };
};

export const updateCommissionsFail = (error) => {
  return {
    type: "UPDATE_COMMISSIONS_FAIL",
    commissionsFailError: error,
  };
};

export const updateCommissions = (comData) => {
  return (dispatch) => {
    instance
      .post("/product/productcommision/", comData)
      .then((response) => {
        dispatch(updateCommissionsSuccess(response.data.success));
      })
      .catch((error) => {
        dispatch(updateCommissionsFail(error.response.data.message));
      });
  };
};

export const clearUpdateCommissionsStatusStatus = () => {
  return {
    type: "CLEAR_UPDATE_COMMISSIONS_STATUS",
  };
};

export const getProductCommissionTypeSuccess = (data) => {
  return {
    type: "GET_PRODUCT_COMMISSION_TYPE_SUCCESS",
    productCommissionType: data,
  };
};

export const getProductCommissionTypeFail = (error) => {
  return {
    type: "GET_PRODUCT_COMMISSION_TYPE_FAIL",
    error: error,
  };
};

export const getProductCommissionType = (pid, profileid) => {
  const data = { profile: profileid };
  return (dispatch) => {
    instance
      .post(`/product/getcommisiontype/${pid}/`, data)
      .then((response) => {
        dispatch(getProductCommissionTypeSuccess(response.data));
      })
      .catch((error) => {
        dispatch(getProductCommissionTypeFail(error));
      });
  };
};

export const updateConCommissionsSuccess = (status) => {
  return {
    type: "UPDATE_CON_COMMISSIONS_SUCCESS",
    updateConCommissionsStatus: status,
  };
};

export const updateConCommissionsFail = (error) => {
  return {
    type: "UPDATE_CON_COMMISSIONS_FAIL",
    conCommissionsFailError: error,
  };
};

export const updateConCommissions = (comData) => {
  return (dispatch) => {
    instance
      .post("/product/conveniencecommision/", comData)
      .then((response) => {
        dispatch(updateConCommissionsSuccess(response.data.success));
      })
      .catch((error) => {
        dispatch(updateConCommissionsFail(error.response.data.message));
      });
  };
};

export const postConCommissionTypeSuccess = (status) => {
  return {
    type: "POST_CON_COMMISSION_TYPE_SUCCESS",
    postConCommissionTypeStatus: status,
  };
};

export const postConCommissionTypeFail = (error) => {
  return {
    type: "POST_CON_COMMISSION_TYPE_FAIL",
    error: error,
  };
};

export const postConCommissionType = (commissionTypeData) => {
  return (dispatch) => {
    instance
      .post("/product/createunitconvenience/", commissionTypeData)
      .then((response) => {
        dispatch(postConCommissionTypeSuccess(response.data.success));
      })
      .catch((error) => {
        dispatch(postConCommissionTypeFail(error));
      });
  };
};

export const getConCommissionsSuccess = (data) => {
  return {
    type: "GET_CON_COMMISSIONS_SUCCESS",
    unitConCommissions: data,
  };
};

export const getConCommissionsFail = (error) => {
  return {
    type: "GET_CON_COMMISSIONS_FAIL",
    error: error,
  };
};

export const getConCommissions = (pid, profileid) => {
  const data = { product: pid, profile: profileid };
  return (dispatch) => {
    instance
      .post("/product/getunitcovenience/", data)
      .then((response) => {
        dispatch(getConCommissionsSuccess(response.data.data));
      })
      .catch((error) => {
        dispatch(getConCommissionsFail(error));
      });
  };
};

export const editConCommissionTypeSuccess = (status) => {
  return {
    type: "EDIT_CON_COMMISSION_TYPE_SUCCESS",
    editConCommissionTypeStatus: status,
  };
};

export const editConCommissionTypeFail = (error) => {
  return {
    type: "EDIT_CON_COMMISSION_TYPE_FAIL",
    error: error,
  };
};

export const editConCommissionType = (ucId, commissionTypeData) => {
  return (dispatch) => {
    instance
      .put(`/product/updateunitconvenience/${ucId}/`, commissionTypeData)
      .then((response) => {
        dispatch(editConCommissionTypeSuccess(response.data.success));
      })
      .catch((error) => {
        dispatch(editConCommissionTypeFail(error));
      });
  };
};

export const getCommissionsSuccess = (data) => {
  return {
    type: "GET_COMMISSIONS_SUCCESS",
    commissions: data,
  };
};

export const getCommissionsFail = (error) => {
  return {
    type: "GET_COMMISSIONS_FAIL",
    error: error,
  };
};

export const getCommissions = (pid, profileid) => {
  const data = { product: pid, profile: profileid };
  return (dispatch) => {
    instance
      .post("/product/getcommisionforproduct/", data)
      .then((response) => {
        dispatch(getCommissionsSuccess(response.data.data));
      })
      .catch((error) => {
        dispatch(getCommissionsFail(error));
      });
  };
};

export const clearOldData = () => {
  return {
    type: "CLEAR_OLD_DATA",
  };
};

export const postCommissionProfileAllProductsSuccess = (status) => {
  return {
    type: "POST_COMMISSION_PROFILE_ALL_PRODUCT_SUCCESS",
    commissionAllProducts: status,
  };
};

export const postCommissionProfileAllProductsFail = (error) => {
  return {
    type: "POST_COMMISSION_PROFILE_ALL_PRODUCT_FAIL",
    error: error,
  };
};

export const postCommissionProfileAllProducts = (
  commissionProfileData,
  type
) => {
  return (dispatch) => {
    instance
      .post(
        `/product/profilecommisionallproduct/${type}/`,
        commissionProfileData
      )
      .then((response) => {
        dispatch(postCommissionProfileAllProductsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(postCommissionProfileAllProductsFail(error));
      });
  };
};

export const postAllProductsCommissionSuccess = (status) => {
  return {
    type: "POST_ALL_PRODUCT_COMMISSION_SUCCESS",
    allProductsCommissionStatus: status,
  };
};

export const postAllProductsCommissionFail = (error) => {
  return {
    type: "POST_ALL_PRODUCT_COMMISSION_FAIL",
    error: error,
  };
};

export const postAllProductsCommission = (commissionData, type) => {
  return (dispatch) => {
    instance
      .post(`/product/profilecommisionallproduct/${type}/`, commissionData)
      .then((response) => {
        dispatch(postAllProductsCommissionSuccess(response.data.success));
      })
      .catch((error) => {
        dispatch(postAllProductsCommissionFail(error));
      });
  };
};

export const clearAllProductsCommissionStatus = () => {
  return {
    type: "CLEAR_ALLPRODUCT_COMMISSION_STATUS",
  };
};
