import { MatxLoadable } from "matx";

const SalesAndOrders = MatxLoadable({
  loader: () => import("./SalesAndOrders"),
});

const SalesAndOrdersDetail = MatxLoadable({
  loader: () => import("./SalesAndOrdersDetail"),
});

const DeliveryNote = MatxLoadable({
  loader: () => import("./DeliveryNote"),
});

const DistributorReturns = MatxLoadable({
  loader: () => import("./DistributorReturns"),
});

const DistributorReturnsDetail = MatxLoadable({
  loader: () => import("./DistributorReturnsDetail"),
});

const Invoice = MatxLoadable({
  loader: () => import("./Invoice"),
});

const Refund = MatxLoadable({
  loader: () => import("../../Refund"),
});

const SalesAndOrdersRoutes = [
  {
    path: "/salesAndOrders",
    component: SalesAndOrders,
  },
  {
    path: "/salesAndOrdersDetail",
    component: SalesAndOrdersDetail,
  },
  {
    path: "/deliveryNote",
    component: DeliveryNote,
  },
  {
    path: "/dreturns",
    component: DistributorReturns,
  },
  {
    path: "/dreturnsDetail",
    component: DistributorReturnsDetail,
  },
  {
    path: "/dinvoice",
    component: Invoice,
  },
  {
    path: "/mrefunds",
    component: Refund,
  },
];

export default SalesAndOrdersRoutes;
