import React, { Component } from "react";
import * as actions from "../../redux/actions/Index";
import { Redirect } from "react-router";
import { connect } from "react-redux";

class Logout extends Component {
  state = {
    // LoggedinToken: this.props.logintoken,
  };

  componentDidMount() {
    this.props.onLogout();
  }

  render() {
    return <Redirect to="/session/signin" />;
  }
}

const mapStateToProps = (state) => {
  return {
    // logintoken: state.auth.token,
    // Status: state.auth.Status,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: () => dispatch(actions.logout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
