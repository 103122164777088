import React from "react";
import { Redirect } from "react-router-dom";
import dashboardRoutes from "./views/Admin/dashboard/DashboardRoutes";
import manufacturerDashboardRoutes from "./views/Manufacturer/dashboard/DashboardRoutes";
import distributorDashboardRoutes from "./views/Distributor/dashboard/DashboardRoutes";
import retailerDashboardRoutes from "./views/Retailer/dashboard/DashboardRoutes";
import ProductsRoutes from "./views/Manufacturer/Products/Product-routes";
import CategoryRoutes from "./views/Manufacturer/Category/Category-routes";
import DistributorProductRoutes from "./views/Distributor/Products/Distributor-product-routes";
import EcommerceRoutes from "./views/Distributor/Ecommerce/EcommerceRoutes";
import SalesAndOrdersRoutes from "./views/Manufacturer/SalesAndOrders/SalesAndOrders-routes";
import utilitiesRoutes from "./views/utilities/UtilitiesRoutes";
import sessionRoutes from "./views/sessions/SessionRoutes";
import materialRoutes from "./views/material-kit/MaterialRoutes";
import dragAndDropRoute from "./views/Drag&Drop/DragAndDropRoute";
import formsRoutes from "./views/forms/FormsRoutes";
import mapRoutes from "./views/map/MapRoutes";
import UserApproveRoutes from "./views/Admin/UserApprove/userlistroutes";
import SettingsRoutes from "./views/UserProfile/SettingsRoutes";
import ManufacturerRoutes from "./views/Admin/Manufacturer/ManufacturerRoutes";
import DistributorRoutes from "./views/Admin/Distributor/DistributorRoutes";
import RetailerRoutes from "./views/Admin/Retailer/RetailerRoutes";
import CartRoutes from "./views/Cart/CartRoutes";
import DistributorOrderRoutes from "./views/Distributor/Orders/OrderRoutes";
import BrandRoutes from "./views/Manufacturer/Brands/BrandRoutes";
import RetailerEcomRoutes from "./views/Retailer/Ecommerce/RetailerEcomRoutes";
import RetailerOrderRoutes from "./views/Retailer/Orders/OrderRoutes";
import UnitRoutes from "./views/Admin/Unit/UnitRoutes";
import AllOrderRoutes from "./views/Admin/Orders/AllOrderRoutes";

const redirectRoute = [
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/session/signin" />,
  },
];

const errorRoute = [
  {
    component: () => <Redirect to="/session/404" />,
  },
];

const routes = [
  ...sessionRoutes,
  ...dashboardRoutes,
  ...manufacturerDashboardRoutes,
  ...distributorDashboardRoutes,
  ...retailerDashboardRoutes,
  ...ProductsRoutes,
  ...AllOrderRoutes,
  ...CategoryRoutes,
  ...SettingsRoutes,
  ...CartRoutes,
  ...DistributorOrderRoutes,
  ...ManufacturerRoutes,
  ...DistributorRoutes,
  ...RetailerRoutes,
  ...UserApproveRoutes,
  ...BrandRoutes,
  ...UnitRoutes,
  ...DistributorProductRoutes,
  ...EcommerceRoutes,
  ...RetailerEcomRoutes,
  ...RetailerOrderRoutes,
  ...SalesAndOrdersRoutes,
  ...materialRoutes,
  ...utilitiesRoutes,
  ...dragAndDropRoute,
  ...formsRoutes,
  ...mapRoutes,
  ...redirectRoute,
  ...errorRoute,
];

export default routes;
