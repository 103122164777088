import { instance } from "../CustomerApi";
import Axios from "axios";

export const axios = Axios.create({
  baseURL: process.env.REACT_APP_CUSTOMER_API,
  headers: { Accept: "application/json" },
});

export const postUserStart = () => {
  return {
    type: "POST_USER_START",
  };
};

export const postUserSuccess = (status) => {
  return {
    type: "POST_USER_SUCCESS",
    status: status,
  };
};

export const postUserFail = (error) => {
  return {
    type: "POST_USER_FAIL",
    error: error,
  };
};

export const approveUser = (approveData) => {
  return (dispatch) => {
    dispatch(postUserStart());
    instance
      .post("/user/approveuser/", approveData)
      .then((response) => {
        dispatch(postUserSuccess(response.data.success));
      })
      .catch((error) => {
        dispatch(postUserFail(error));
      });
  };
};

export const getUsersStart = () => {
  return {
    type: "GET_USERS_START",
  };
};

export const getUsersSuccess = (data) => {
  return {
    type: "GET_USERS_SUCCESS",
    users: data,
  };
};

export const getUsersFail = (error) => {
  return {
    type: "GET_USERS_FAIL",
    error: error,
  };
};

export const getUsers = (id) => {
  return (dispatch) => {
    dispatch(getUsersStart());
    instance
      .get(`/user/getUsers/${id}/`)
      .then((response) => {
        dispatch(getUsersSuccess(response.data));
      })
      .catch((error) => {
        dispatch(getUsersFail(error));
      });
  };
};

export const deleteUserStart = () => {
  return {
    type: "DELETE_USER_START",
  };
};

export const deleteUserSuccess = (status) => {
  return {
    type: "DELETE_USER_SUCCESS",
    successStatus: status,
  };
};

export const deleteUserFail = (error) => {
  return {
    type: "DELETE_USER_FAIL",
    error: error,
  };
};

export const deleteUser = (id) => {
  return (dispatch) => {
    dispatch(deleteUserStart());
    instance
      .post(`/user/userdelete/${id}/`)
      .then((response) => {
        dispatch(deleteUserSuccess(response.data.success));
      })
      .catch((error) => {
        dispatch(deleteUserFail(error));
      });
  };
};

export const clearUsersList = () => {
  return {
    type: "CLEAR_USERS_LIST",
  };
};

export const clearDeleteStatus = () => {
  return {
    type: "CLEAR_DELETE_STATUS",
  };
};

export const clearApproveStatus = () => {
  return {
    type: "CLEAR_APPROVE_STATUS",
  };
};

export const getUserDetailsStart = () => {
  return {
    type: "GET_USER_DETAILS_START",
  };
};

export const getUserDetailsSuccess = (data) => {
  return {
    type: "GET_USER_DETAILS_SUCCESS",
    userDetails: data,
  };
};

export const getUserDetailsFail = (error) => {
  return {
    type: "GET_USER_DETAILS_FAIL",
    error: error,
  };
};

export const getUserDetails = () => {
  return (dispatch) => {
    dispatch(getUserDetailsStart());
    instance
      .get("/user/details/")
      .then((response) => {
        dispatch(getUserDetailsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(getUserDetailsFail(error));
      });
  };
};

export const getSpecifiedUsersStart = () => {
  return {
    type: "GET_SPECIFIED_USERS_START",
  };
};

export const getSpecifiedUsersSuccess = (data) => {
  return {
    type: "GET_SPECIFIED_USERS_SUCCESS",
    specifiedusers: data,
  };
};

export const getSpecifiedUsersFail = (error) => {
  return {
    type: "GET_SPECIFIED_USERS_FAIL",
    error: error,
  };
};

export const getSpecifiedUsers = (type, page) => {
  return (dispatch) => {
    dispatch(getSpecifiedUsersStart());
    instance
      .get(`/user/alluser/${type}/?page=${page}`)
      .then((response) => {
        dispatch(getSpecifiedUsersSuccess(response.data));
      })
      .catch((error) => {
        dispatch(getSpecifiedUsersFail(error));
      });
  };
};

export const editUserDetailsSuccess = (status) => {
  return {
    type: "EDIT_USER_DETAILS_SUCCESS",
    status: status,
  };
};

export const editUserDetailsFail = (error) => {
  return {
    type: "EDIT_USER_DETAILS_FAIL",
    error: error,
  };
};

export const editUserDetails = (data) => {
  return (dispatch) => {
    instance
      .put("/user/details/", data)
      .then((response) => {
        dispatch(editUserDetailsSuccess(response.status));
      })
      .catch((error) => {
        dispatch(editUserDetailsFail(error));
      });
  };
};

export const forgotPasswordSuccess = (status) => {
  return {
    type: "FORGOT_PASSWORD_SUCCESS",
    successStatus: status,
  };
};

export const forgotPasswordFail = (error) => {
  return {
    type: "FORGOT_PASSWORD_FAIL",
    error: error,
  };
};

export const forgotPassword = (email) => {
  const resetData = {
    email: email,
  };
  return (dispatch) => {
    axios
      .post("/user/forgotpassword/", resetData)
      .then((response) => {
        dispatch(forgotPasswordSuccess(response.data.success));
      })
      .catch((error) => {
        dispatch(forgotPasswordFail(error));
      });
  };
};

export const resetPasswordSuccess = (status) => {
  return {
    type: "RESET_PASSWORD_SUCCESS",
    successStatus: status,
  };
};

export const resetPasswordFail = (error) => {
  return {
    type: "RESET_PASSWORD_FAIL",
    error: error,
  };
};

export const resetPassword = (resetData) => {
  return (dispatch) => {
    instance
      .put("/user/passwordupdate/", resetData)
      .then((response) => {
        dispatch(resetPasswordSuccess(response.data.success));
      })
      .catch((error) => {
        dispatch(resetPasswordFail(error));
      });
  };
};

export const addThirdPartyUserSuccess = (status) => {
  return {
    type: "ADD_THIRD_PARTY_USER_SUCCESS",
    thirdPartyRegisterStatus: status,
  };
};

export const addThirdPartyUserFail = (error) => {
  return {
    type: "ADD_THIRD_PARTY_USER_FAIL",
    error: error,
  };
};

export const addThirdPartyUser = (data) => {
  return (dispatch) => {
    instance
      .post("/user/createuser/", data)
      .then((response) => {
        dispatch(addThirdPartyUserSuccess(response.data.status));
      })
      .catch((error) => {
        dispatch(addThirdPartyUserFail(error));
      });
  };
};

export const clearResetPasswordStatus = () => {
  return {
    type: "CLEAR_RESET_PASSWORD_STATUS",
  };
};

export const getThirdPartyUserListSuccess = (data) => {
  return {
    type: "GET_THIRD_PARTY_USERLIST_SUCCESS",
    thirdPartyUserList: data,
  };
};

export const getThirdPartyUserListFail = (error) => {
  return {
    type: "GET_THIRD_PARTY_USERLIST_FAIL",
    error: error,
  };
};

export const getThirdPartyUserList = (profileId) => {
  return (dispatch) => {
    instance
      .get(`/user/companyprofile/${profileId}/`)
      .then((response) => {
        dispatch(getThirdPartyUserListSuccess(response.data.data));
      })
      .catch((error) => {
        dispatch(getThirdPartyUserListFail(error));
      });
  };
};

export const addKYCDetailsSuccess = (data) => {
  return {
    type: "ADD_KYC_DETAILS_SUCCESS",
    addKYCStatus: data,
  };
};

export const addKYCDetailsFail = (error) => {
  return {
    type: "ADD_KYC_DETAILS_FAIL",
    error: error,
  };
};

export const addKYCDetails = (KYCDetails) => {
  return (dispatch) => {
    instance
      .post("/user/createrazorpayaccount/", KYCDetails)
      .then((response) => {
        dispatch(addKYCDetailsSuccess(response.data.success));
      })
      .catch((error) => {
        dispatch(addKYCDetailsFail(error));
      });
  };
};

export const clearAddKYCStatus = () => {
  return {
    type: "CLEAR_ADD_KYC_STATUS",
  };
};

export const checkKYCaddedSuccess = (data) => {
  return {
    type: "CHECK_KYC_ADDED_SUCCESS",
    checkKYCStatus: data,
  };
};

export const checkKYCaddedFail = (error) => {
  return {
    type: "CHECK_KYC_ADDED_FAIL",
    error: error,
  };
};

export const checkKYCadded = () => {
  return (dispatch) => {
    instance
      .get("/user/accontactive/")
      .then((response) => {
        dispatch(checkKYCaddedSuccess(response.data));
      })
      .catch((error) => {
        dispatch(checkKYCaddedFail(error));
      });
  };
};

export const clearCheckKYCaddedStatus = () => {
  return {
    type: "CLEAR_CHECK_KYC_ADDED_STATUS",
  };
};

export const getMyKYCDetailsSuccess = (data) => {
  return {
    type: "GET_MY_KYC_DETAILS_SUCCESS",
    myKYCDetails: data,
  };
};

export const getMyKYCDetailsFail = (error) => {
  return {
    type: "GET_MY_KYC_DETAILS_FAIL",
    error: error,
  };
};

export const getMyKYCDetails = () => {
  return (dispatch) => {
    instance
      .get("/user/mykycdetail/")
      .then((response) => {
        dispatch(getMyKYCDetailsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(getMyKYCDetailsFail(error));
      });
  };
};

export const getUserStateSuccess = (data) => {
  return {
    type: "GET_USER_STATE_SUCCESS",
    userState: data,
  };
};

export const getUserStateFail = (error) => {
  return {
    type: "GET_USER_STATE_FAIL",
    error: error,
  };
};

export const getUserState = (profileId) => {
  const data = {
    sellerid: profileId
  }
  return (dispatch) => {
    instance
      .post("/user/checkstate/", data)
      .then((response) => {
        dispatch(getUserStateSuccess(response.data));
      })
      .catch((error) => {
        dispatch(getUserStateFail(error));
      });
  };
};