import { MatxLoadable } from "matx";

const AddProduct = MatxLoadable({
  loader: () => import("./Add-Products"),
});

const ProductList = MatxLoadable({
  loader: () => import("./Product-Lists"),
});

const ProductPricing = MatxLoadable({
  loader: () => import("./ProductPricing"),
});

const ProductVariant = MatxLoadable({
  loader: () => import("./ProductVariant"),
});

const ProductMedia = MatxLoadable({
  loader: () => import("./ProductMedia"),
});

const ProductEdit = MatxLoadable({
  loader: () => import("./EditProduct"),
});

const EditProductPrice = MatxLoadable({
  loader: () => import("./ProductPriceEdit"),
});

const ProductStock = MatxLoadable({
  loader: () => import("./ProductStock"),
});

const SelectProductCategory = MatxLoadable({
  loader: () => import("./SelectProductCategory"),
});

const ProductRoutes = [
  {
    path: "/products/addProducts",
    component: AddProduct,
  },
  {
    path: "/products/ProductList",
    component: ProductList,
  },
  {
    path: "/products/ProductPricing",
    component: ProductPricing,
  },
  {
    path: "/products/ProductVariant",
    component: ProductVariant,
  },
  {
    path: "/products/ProductMedia",
    component: ProductMedia,
  },
  {
    path: "/products/editproduct",
    component: ProductEdit,
  },
  {
    path: "/products/editProductPricing",
    component: EditProductPrice,
  },
  {
    path: "/products/productstock",
    component: ProductStock,
  },
  {
    path: "/products/selectCategory",
    component: SelectProductCategory,
  },
];

export default ProductRoutes;
