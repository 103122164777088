import { updatedObject } from "../Utility";

const initialState = {
  error: "",
  loading: false,
  refreshToken: "",
  accessToken: "",
  successStatus: "",
  userType: "",
  states: "",
  cities: "",
  registerError: "",
  registerStatus: "",
  loginError: "",
};

const authStart = (state, action) => {
  return updatedObject(state, {
    loading: true,
    error: null,
  });
};

const authSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    refreshToken: action.refreshToken,
    accessToken: action.accessToken,
    successStatus: action.status,
    userType: action.userType,
  });
};

const authFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    loginError: action.error,
  });
};

const registerUserStart = (state, action) => {
  return updatedObject(state, {
    loading: true,
    error: null,
  });
};

const registerUserSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    registerStatus: action.status,
  });
};

const registerUserFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    registerError: action.error,
  });
};

const getStateStart = (state, action) => {
  return updatedObject(state, {
    loading: true,
    error: null,
  });
};

const getStateSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    states: action.state,
  });
};

const getStateFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const getCityStart = (state, action) => {
  return updatedObject(state, {
    loading: true,
    error: null,
  });
};

const getCitySuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    cities: action.city,
  });
};

const clearLoginSuccessStatus = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    successStatus: null,
  });
};

const clearErrStatus = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    loginError: null,
  });
};

const getCityFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const logoutStart = (state, action) => {
  return updatedObject(state, {
    loading: true,
    error: null,
  });
};

const logoutSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    refreshToken: "",
    accessToken: "",
    userType: "",
  });
};

const logoutFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const LoginReducer = (state = initialState, action) => {
  switch (action.type) {
    case "AUTH_START":
      return authStart(state, action);
    case "AUTH_SUCCESS":
      return authSuccess(state, action);
    case "AUTH_FAIL":
      return authFail(state, action);
    case "REGISTER_USER_START":
      return registerUserStart(state, action);
    case "REGISTER_USER_SUCCESS":
      return registerUserSuccess(state, action);
    case "REGISTER_USER_FAIL":
      return registerUserFail(state, action);
    case "GET_STATE_START":
      return getStateStart(state, action);
    case "GET_STATE_SUCCESS":
      return getStateSuccess(state, action);
    case "GET_STATE_FAIL":
      return getStateFail(state, action);
    case "GET_CITY_START":
      return getCityStart(state, action);
    case "GET_CITY_SUCCESS":
      return getCitySuccess(state, action);
    case "GET_CITY_FAIL":
      return getCityFail(state, action);
    case "LOGOUT_START":
      return logoutStart(state, action);
    case "LOGOUT_SUCCESS":
      return logoutSuccess(state, action);
    case "LOGOUT_FAIL":
      return logoutFail(state, action);
    case "CLEAR_LOGIN_SUCCESS":
      return clearLoginSuccessStatus(state, action);
    case "CLEAR_ERR_STATUS":
      return clearErrStatus(state, action);
    default:
      return state;
  }
};

export default LoginReducer;
