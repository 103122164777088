import { MatxLoadable } from "matx";

const RetailerOrders = MatxLoadable({
  loader: () => import("./RetailerOrders"),
});

const RetailerOrderDetail = MatxLoadable({
  loader: () => import("./RetailerOrderDetail"),
});

const MyReturns = MatxLoadable({
  loader: () => import("./MyReturns"),
});

const Refund = MatxLoadable({
  loader: () => import("../../Refund"),
});

const OrderRoutes = [
  {
    path: "/retailer/myorders",
    component: RetailerOrders,
  },
  {
    path: "/retailer/myordersdetail",
    component: RetailerOrderDetail,
  },
  {
    path: "/retailer/myreturns",
    component: MyReturns,
  },
  {
    path: "/rrefunds",
    component: Refund,
  },
];

export default OrderRoutes;
