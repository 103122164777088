import Axios from "axios";
import { instance } from "../ProductApi";

const axios = Axios.create({
  baseURL: process.env.REACT_APP_PRODUCT_API,
  headers: {
    Accept: "application/json",
  },
});

export const postProductStart = () => {
  return {
    type: "POST_PRODUCT_START",
  };
};

export const postProductSuccess = (status, id) => {
  return {
    type: "POST_PRODUCT_SUCCESS",
    addStatus: status,
    productId: id,
  };
};

export const postProductFail = (error) => {
  return {
    type: "POST_PRODUCT_FAIL",
    error: error,
  };
};

export const addProduct = (productData) => {
  return (dispatch) => {
    dispatch(postProductStart());
    instance
      .post("/product/addProducts/1/", productData)
      .then((response) => {
        dispatch(
          postProductSuccess(response.data.success, response.data.data.id)
        );
      })
      .catch((error) => {
        dispatch(postProductFail(error));
      });
  };
};

export const postProductImageStart = () => {
  return {
    type: "POST_PRODUCT_IMAGE_START",
  };
};

export const postProductImageSuccess = (responseData) => {
  return {
    type: "POST_PRODUCT_IMAGE_SUCCESS",
    response: responseData,
  };
};

export const postProductImageFail = (error) => {
  return {
    type: "POST_PRODUCT_IMAGE_FAIL",
    error: error,
  };
};

export const addProductImage = (ImageData) => {
  return (dispatch) => {
    dispatch(postProductImageStart());
    instance
      .post("/product/uploadimage/", ImageData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        dispatch(postProductImageSuccess(response.data));
      })
      .catch((error) => {
        dispatch(postProductImageFail(error));
      });
  };
};

export const getProductsStart = () => {
  return {
    type: "GET_PRODUCTS_START",
  };
};

export const getProductsSuccess = (data) => {
  return {
    type: "GET_PRODUCTS_SUCCESS",
    products: data,
  };
};

export const getProductsFail = (error) => {
  return {
    type: "GET_PRODUCTS_FAIL",
    error: error,
  };
};

export const getProducts = () => {
  return (dispatch) => {
    dispatch(getProductsStart());
    instance
      .get("/product/getProducts/")
      .then((response) => {
        dispatch(getProductsSuccess(response.data.results));
      })
      .catch((error) => {
        dispatch(getProductsFail(error));
      });
  };
};

export const getEcommerceProductsSuccess = (data) => {
  return {
    type: "GET_ECOMMERCE_PRODUCTS_SUCCESS",
    EcommerceProducts: data,
  };
};

export const getEcommerceProductsFail = (error) => {
  return {
    type: "GET_ECOMMERCE_PRODUCTS_FAIL",
    error: error,
  };
};

export const getEcommerceProducts = (page) => {
  return (dispatch) => {
    instance
      .get(`/product/getProducts/?page=${page}`)
      .then((response) => {
        dispatch(getEcommerceProductsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(getEcommerceProductsFail(error));
      });
  };
};

export const postProductPriceStart = () => {
  return {
    type: "POST_PRODUCT_PRICE_START",
  };
};

export const postProductPriceSuccess = (status) => {
  return {
    type: "POST_PRODUCT_PRICE_SUCCESS",
    status: status,
  };
};

export const postProductPriceFail = (error) => {
  return {
    type: "POST_PRODUCT_PRICE_FAIL",
    error: error,
  };
};

export const postProductPrice = (PriceData) => {
  return (dispatch) => {
    dispatch(postProductPriceStart());
    instance
      .post("/product/addProductPrice/", PriceData)
      .then((response) => {
        dispatch(postProductPriceSuccess(response.data.success));
      })
      .catch((error) => {
        dispatch(postProductPriceFail(error.response.data && error.response.data.message));
      });
  };
};

export const getManufacturerProductsStart = () => {
  return {
    type: "GET_MANUFACTURER_PRODUCTS_START",
  };
};

export const getManufacturerProductsSuccess = (data) => {
  return {
    type: "GET_MANUFACTURER_PRODUCTS_SUCCESS",
    manufacturerProducts: data,
  };
};

export const getManufacturerProductsFail = (error) => {
  return {
    type: "GET_MANUFACTURER_PRODUCTS_FAIL",
    error: error,
  };
};

export const getManufacturerProducts = (page) => {
  return (dispatch) => {
    dispatch(getManufacturerProductsStart());
    instance
      .get(`/product/manufactureproducts/?page=${page}`)
      .then((response) => {
        dispatch(getManufacturerProductsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(getManufacturerProductsFail(error));
      });
  };
};

export const postProductVariantStart = () => {
  return {
    type: "POST_PRODUCT_VARIANT_START",
  };
};

export const postProductVariantSuccess = (status) => {
  return {
    type: "POST_PRODUCT_VARIANT_SUCCESS",
    status: status,
  };
};

export const postProductVariantFail = (error) => {
  return {
    type: "POST_PRODUCT_VARIANT_FAIL",
    error: error,
  };
};

export const onSubmitVariantData = (variantData) => {
  return (dispatch) => {
    dispatch(postProductVariantStart());
    instance
      .post("/product/productvarient/", variantData)
      .then((response) => {
        dispatch(postProductVariantSuccess(response.data.success));
      })
      .catch((error) => {
        dispatch(postProductVariantFail(error));
      });
  };
};

export const postVariantSuccess = (status) => {
  return {
    type: "POST_VARIANT_SUCCESS",
    status: status,
  };
};

export const postVariantFail = (error) => {
  return {
    type: "POST_VARIANT_FAIL",
    error: error,
  };
};

export const onPostVariant = (variant) => {
  return (dispatch) => {
    instance
      .post("/product/createvarient/", variant)
      .then((response) => {
        dispatch(postVariantSuccess(response.data.success));
      })
      .catch((error) => {
        dispatch(postVariantFail(error));
      });
  };
};

export const postVariantOptionSuccess = (status) => {
  return {
    type: "POST_VARIANT_OPTION_SUCCESS",
    status: status,
  };
};

export const postVariantOptionFail = (error) => {
  return {
    type: "POST_VARIANT_OPTION_FAIL",
    error: error,
  };
};

export const onPostVariantOption = (variantOption, variantId) => {
  return (dispatch) => {
    instance
      .post(`/product/createvarientoption/${variantId}`, variantOption)
      .then((response) => {
        dispatch(postVariantOptionSuccess(response.data.success));
      })
      .catch((error) => {
        dispatch(postVariantOptionFail(error));
      });
  };
};

export const clearVariantAddStatus = () => {
  return {
    type: "CLEAR_VARIANT_ADD_STATUS",
  };
};
export const clearNewVariantAddStatus = () => {
  return {
    type: "CLEAR_NEW_VARIANT_ADD_STATUS",
  };
};
export const clearNewVariantOptionAddStatus = () => {
  return {
    type: "CLEAR_NEW_VARIANT_OPTION_ADD_STATUS",
  };
};

export const clearProductAddStatus = () => {
  return {
    type: "CLEAR_PRODUCT_ADD_STATUS",
  };
};

export const clearPriceAddStatus = () => {
  return {
    type: "CLEAR_PRICE_ADD_STATUS",
  };
};

export const postProductVideoStart = () => {
  return {
    type: "POST_PRODUCT_VIDEO_START",
  };
};

export const postProductVideoSuccess = (responseData) => {
  return {
    type: "POST_PRODUCT_VIDEO_SUCCESS",
    response: responseData,
  };
};

export const postProductVideoFail = (error) => {
  return {
    type: "POST_PRODUCT_VIDEO_FAIL",
    error: error,
  };
};

export const postProductVideo = (videoFile) => {
  return (dispatch) => {
    dispatch(postProductVideoStart());
    instance
      .post("/product/uploadvideo/", videoFile, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        dispatch(postProductVideoSuccess(response.data));
      })
      .catch((error) => {
        dispatch(postProductVideoFail(error));
      });
  };
};

export const clearVideoResponse = () => {
  return {
    type: "CLEAR_VIDEO_RESPONSE",
  };
};

export const clearImageResponse = () => {
  return {
    type: "CLEAR_IMAGE_RESPONSE",
  };
};

export const deleteProductVariantSuccess = (status) => {
  return {
    type: "DELETE_PRODUCT_VARIANT_SUCCESS",
    status: status,
  };
};

export const deleteProductVariantFail = (error) => {
  return {
    type: "DELETE_PRODUCT_VARIANT_FAIL",
    error: error,
  };
};

export const deleteVariant = (id) => {
  const data = { id: id };
  return (dispatch) => {
    instance
      .delete("/product/productvarient/", { data })
      .then((response) => {
        dispatch(deleteProductVariantSuccess(response.status));
      })
      .catch((error) => {
        dispatch(deleteProductVariantFail(error));
      });
  };
};

export const deleteProductImageSuccess = (status) => {
  return {
    type: "DELETE_PRODUCT_IMAGE_SUCCESS",
    status: status,
  };
};

export const deleteProductImageFail = (error) => {
  return {
    type: "DELETE_PRODUCT_IMAGE_FAIL",
    error: error,
  };
};

export const deleteImage = (img) => {
  const data = { id: img };
  return (dispatch) => {
    instance
      .delete("/product/deleteimage/", { data })
      .then((response) => {
        dispatch(deleteProductImageSuccess(response.data.success));
      })
      .catch((error) => {
        dispatch(deleteProductImageFail(error));
      });
  };
};

export const deleteProductVideoSuccess = (status) => {
  return {
    type: "DELETE_PRODUCT_VIDEO_SUCCESS",
    status: status,
  };
};

export const deleteProductVideoFail = (error) => {
  return {
    type: "DELETE_PRODUCT_VIDEO_FAIL",
    error: error,
  };
};

export const deleteVideo = (video) => {
  const data = { id: video };
  return (dispatch) => {
    instance
      .delete("/product/deletevideo/", { data })
      .then((response) => {
        dispatch(deleteProductVideoSuccess(response.data.success));
      })
      .catch((error) => {
        dispatch(deleteProductVideoFail(error));
      });
  };
};

export const getProductPricesSuccess = (prices) => {
  return {
    type: "GET_PRODUCT_PRICES_SUCCESS",
    prices: prices,
  };
};

export const getProductPricesFail = (error) => {
  return {
    type: "GET_PRODUCT_PRICES_FAIL",
    error: error,
  };
};

export const getPrices = (id, profileId) => {
  const data = {
    profile: profileId,
  };
  return (dispatch) => {
    instance
      .post(`/product/productprice/${id}`, data)
      .then((response) => {
        dispatch(getProductPricesSuccess(response.data.data));
      })
      .catch((error) => {
        dispatch(getProductPricesFail(error));
      });
  };
};

export const editProductPriceSuccess = (status) => {
  return {
    type: "EDIT_PRODUCT_PRICE_SUCCESS",
    status: status,
  };
};

export const editProductPriceFail = (error) => {
  return {
    type: "EDIT_PRODUCT_PRICE_FAIL",
    error: error,
  };
};

export const editProductPrice = (priceData, id) => {
  return (dispatch) => {
    instance
      .put(`/product/productprice/${id}`, priceData)
      .then((response) => {
        dispatch(editProductPriceSuccess(response.data.success));
      })
      .catch((error) => {
        dispatch(editProductPriceFail(error));
      });
  };
};

export const deleteProductPriceSuccess = (status) => {
  return {
    type: "DELETE_PRODUCT_PRICE_SUCCESS",
    response: status,
  };
};

export const deleteProductPriceFail = (error) => {
  return {
    type: "DELETE_PRODUCT_PRICE_FAIL",
    error: error,
  };
};

export const deleteProductPrice = (id) => {
  return (dispatch) => {
    instance
      .delete(`/product/productprice/${id}`)
      .then((response) => {
        dispatch(deleteProductPriceSuccess(response.data.success));
      })
      .catch((error) => {
        dispatch(deleteProductPriceFail(error));
      });
  };
};

export const clearPriceEditStatus = () => {
  return {
    type: "CLEAR_PRICE_EDIT_STATUS",
  };
};

export const clearProductDetails = () => {
  return {
    type: "CLEAR_PRODUCT_DETAILS",
  };
};

export const clearPriceDeleteResponse = () => {
  return {
    type: "CLEAR_PRICE_DELETE_RESPONSE",
  };
};

export const editProductSuccess = (status) => {
  return {
    type: "EDIT_PRODUCT_SUCCESS",
    editStatus: status,
  };
};

export const editProductFail = (error) => {
  return {
    type: "EDIT_PRODUCT_FAIL",
    error: error,
  };
};

export const editProduct = (editData, id) => {
  return (dispatch) => {
    instance
      .put(`/product/addProducts/${id}/`, editData)
      .then((response) => {
        dispatch(editProductSuccess(response.data.success));
      })
      .catch((error) => {
        dispatch(editProductFail(error));
      });
  };
};

export const clearProductEditStatus = () => {
  return {
    type: "CLEAR_PRODUCT_EDIT_STATUS",
  };
};

export const updateStockSuccess = (status) => {
  return {
    type: "UPDATE_STOCK_SUCCESS",
    status: status,
  };
};

export const updateStockFail = (error) => {
  return {
    type: "UPDATE_STOCK_FAIL",
    error: error,
  };
};

export const updateStock = (stockData) => {
  return (dispatch) => {
    instance
      .post("/product/productstock/", stockData)
      .then((response) => {
        dispatch(updateStockSuccess(response.data.success));
      })
      .catch((error) => {
        dispatch(updateStockFail(error));
      });
  };
};

export const clearUpdateStockStatus = () => {
  return {
    type: "CLEAR_UPDATE_STOCK_STATUS",
  };
};

export const getManufacturerDescSuccess = (data) => {
  return {
    type: "GET_MANFACTURER_DESC_SUCCESS",
    manufacturerDesc: data,
  };
};

export const getManufacturerDescFail = (error) => {
  return {
    type: "GET_MANFACTURER_DESC_FAIL",
    error: error,
  };
};

export const getManufacturerDesc = (id) => {
  return (dispatch) => {
    instance
      .get(`/product/manufactureprofile/${id}/`)
      .then((response) => {
        dispatch(getManufacturerDescSuccess(response.data.data));
      })
      .catch((error) => {
        dispatch(getManufacturerDescFail(error));
      });
  };
};

export const getStockDetailsSuccess = (data) => {
  return {
    type: "GET_STOCK_DETAILS_SUCCESS",
    stock: data,
  };
};

export const getStockDetailsFail = (error) => {
  return {
    type: "GET_STOCK_DETAILS_FAIL",
    error: error,
  };
};

export const getStockDetails = () => {
  return (dispatch) => {
    instance
      .get("/product/productstock/")
      .then((response) => {
        dispatch(getStockDetailsSuccess(response.data.data));
      })
      .catch((error) => {
        dispatch(getStockDetailsFail(error));
      });
  };
};

export const getDistributorInstockProductsSuccess = (data) => {
  return {
    type: "GET_DISTRIBUTOR_INSTOCK_PRODUCTS_SUCCESS",
    distributorInstockProducts: data,
  };
};

export const getDistributorInstockProductsFail = (error) => {
  return {
    type: "GET_DISTRIBUTOR_INSTOCK_PRODUCTS_FAIL",
    error: error,
  };
};

export const getDistributorInstockProducts = (page) => {
  return (dispatch) => {
    instance
      .get(`/product/distributorproduct/?page=${page}`)
      .then((response) => {
        dispatch(getDistributorInstockProductsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(getDistributorInstockProductsFail(error));
      });
  };
};

export const thisDistributorInstockProductsSuccess = (data) => {
  return {
    type: "THIS_DISTRIBUTOR_INSTOCK_PRODUCTS_SUCCESS",
    thisDistributorInstockProducts: data,
  };
};

export const thisDistributorInstockProductsFail = (error) => {
  return {
    type: "THIS_DISTRIBUTOR_INSTOCK_PRODUCTS_FAIL",
    error: error,
  };
};

export const thisDistributorInstockProducts = (page) => {
  return (dispatch) => {
    instance
      .get(`/product/distributorinhandproduct/?page=${page}`)
      .then((response) => {
        dispatch(thisDistributorInstockProductsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(thisDistributorInstockProductsFail(error));
      });
  };
};

export const getManufacturerInstockQuantitySuccess = (data) => {
  return {
    type: "GET_MANUFACTURER_INSTOCK_QUANTITY_SUCCESS",
    instockQuantity: data,
  };
};

export const getManufacturerInstockQuantityFail = (error) => {
  return {
    type: "GET_MANUFACTURER_INSTOCK_QUANTITY_FAIL",
    error: error,
  };
};

export const getManufacturerInstockQuantity = (id) => {
  return (dispatch) => {
    instance
      .get(`/product/manufacturestock/${id}/`)
      .then((response) => {
        dispatch(getManufacturerInstockQuantitySuccess(response.data.stock));
      })
      .catch((error) => {
        dispatch(getManufacturerInstockQuantityFail(error));
      });
  };
};

export const getDistributorInstockQuantitySuccess = (data) => {
  return {
    type: "GET_DISTRIBUTOR_INSTOCK_QUANTITY_SUCCESS",
    instockQuantity: data,
  };
};

export const getDistributorInstockQuantityFail = (error) => {
  return {
    type: "GET_DISTRIBUTOR_INSTOCK_QUANTITY_FAIL",
    error: error,
  };
};

export const getDistributorInstockQuantity = (id) => {
  return (dispatch) => {
    instance
      .get(`/product/disrtibutorstock/${id}/`)
      .then((response) => {
        dispatch(getDistributorInstockQuantitySuccess(response.data.stock));
      })
      .catch((error) => {
        dispatch(getDistributorInstockQuantityFail(error));
      });
  };
};

export const getEcomManufacturerInstockQuantitySuccess = (data) => {
  return {
    type: "GET_ECOM_MANUFACTURER_INSTOCK_QUANTITY_SUCCESS",
    instockQuantity: data,
  };
};

export const getEcomManufacturerInstockQuantityFail = (error) => {
  return {
    type: "GET_ECOM_MANUFACTURER_INSTOCK_QUANTITY_FAIL",
    error: error,
  };
};

export const getEcomManufacturerInstockQuantity = (id, profileid) => {
  const data = {
    profileid: profileid,
  };
  return (dispatch) => {
    instance
      .post(`/product/manufacturestockecommerce/${id}/`, data)
      .then((response) => {
        dispatch(
          getEcomManufacturerInstockQuantitySuccess(response.data.stock)
        );
      })
      .catch((error) => {
        dispatch(getEcomManufacturerInstockQuantityFail(error));
      });
  };
};

export const getEcomDistributorInstockQuantitySuccess = (data) => {
  return {
    type: "GET_ECOM_DISTRIBUTOR_INSTOCK_QUANTITY_SUCCESS",
    instockQuantity: data,
  };
};

export const getEcomDistributorInstockQuantityFail = (error) => {
  return {
    type: "GET_ECOM_DISTRIBUTOR_INSTOCK_QUANTITY_FAIL",
    error: error,
  };
};

export const getEcomDistributorInstockQuantity = (id, profileid) => {
  const data = {
    profileid: profileid,
  };
  return (dispatch) => {
    instance
      .post(`/product/distributorstockecommerce/${id}/`, data)
      .then((response) => {
        dispatch(getEcomDistributorInstockQuantitySuccess(response.data.stock));
      })
      .catch((error) => {
        dispatch(getEcomDistributorInstockQuantityFail(error));
      });
  };
};

export const getTaxPercentageSuccess = (prices) => {
  return {
    type: "GET_TAX_PERCENTAGE_SUCCESS",
    taxPercentage: prices,
  };
};

export const getTaxPercentageFail = (error) => {
  return {
    type: "GET_TAX_PERCENTAGE_FAIL",
    error: error,
  };
};

export const getTaxPercentage = () => {
  return (dispatch) => {
    instance
      .get("/product/gettaxpercent/")
      .then((response) => {
        dispatch(getTaxPercentageSuccess(response.data.data));
      })
      .catch((error) => {
        dispatch(getTaxPercentageFail(error));
      });
  };
};

export const getUserProfileSuccess = (data) => {
  return {
    type: "GET_USER_PROFILE_SUCCESS",
    userProfile: data,
  };
};

export const getUserProfileFail = (error) => {
  return {
    type: "GET_USER_PROFILE_FAIL",
    error: error,
  };
};

export const getUserProfile = (profileId) => {
  return (dispatch) => {
    instance
      .get(`/product/getuserprofile/${profileId}/`)
      .then((response) => {
        dispatch(getUserProfileSuccess(response.data));
      })
      .catch((error) => {
        dispatch(getUserProfileFail(error));
      });
  };
};

export const getAdminManufacturerProductsSuccess = (data) => {
  return {
    type: "GET_ADMIN_MANUFACTURER_PRODUCTS_SUCCESS",
    adminManufacturerproducts: data,
  };
};

export const getAdminManufacturerProductsFail = (error) => {
  return {
    type: "GET_ADMIN_MANUFACTURER_PRODUCTS_FAIL",
    error: error,
  };
};

export const getAdminManufacturerProducts = (profileId, page) => {
  const data = { profile_id: profileId };
  return (dispatch) => {
    instance
      .post(`/product/ecapsmanufactureproducts/?page=${page}`, data)
      .then((response) => {
        dispatch(getAdminManufacturerProductsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(getAdminManufacturerProductsFail(error));
      });
  };
};

export const getAdminDistributorProductsSuccess = (data) => {
  return {
    type: "GET_ADMIN_DISTRIBUTOR_PRODUCTS_SUCCESS",
    adminDistributorproducts: data,
  };
};

export const getAdminDistributorProductsFail = (error) => {
  return {
    type: "GET_ADMIN_DISTRIBUTOR_PRODUCTS_FAIL",
    error: error,
  };
};

export const getAdminDistributorProducts = (profileId, page) => {
  const data = { profileid: profileId };
  return (dispatch) => {
    instance
      .post(`/product/getdistributorproductinecaps/?page=${page}`, data)
      .then((response) => {
        dispatch(getAdminDistributorProductsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(getAdminDistributorProductsFail(error));
      });
  };
};
export const clearPrices = () => {
  return {
    type: "CLEAR_PRICES",
  };
};

export const disableDistributorSuccess = (status) => {
  return {
    type: "DISABLE_DISTRIBUTOR_SUCCESS",
    disableDistributorStatus: status,
  };
};

export const disableDistributorFail = (error) => {
  return {
    type: "DISABLE_DISTRIBUTOR_FAIL",
    error: error,
  };
};

export const disableDistributor = (profileId) => {
  const data = { profile: profileId };
  return (dispatch) => {
    instance
      .put("/product/disablealldistributorproduct/", data)
      .then((response) => {
        dispatch(disableDistributorSuccess(response.data.success));
      })
      .catch((error) => {
        dispatch(disableDistributorFail(error));
      });
  };
};

export const disableManufacturerSuccess = (status) => {
  return {
    type: "DISABLE_MANUFACTURER_SUCCESS",
    disableManufacturerStatus: status,
  };
};

export const disableManufacturerFail = (error) => {
  return {
    type: "DISABLE_MANUFACTURER_FAIL",
    error: error,
  };
};

export const disableManufacturer = (profileId) => {
  const data = { profile: profileId };
  return (dispatch) => {
    instance
      .put("/product/disableallmanufactureproduct/", data)
      .then((response) => {
        dispatch(disableManufacturerSuccess(response.data.success));
      })
      .catch((error) => {
        dispatch(disableManufacturerFail(error));
      });
  };
};

export const disableManufacturerProductSuccess = (status) => {
  return {
    type: "DISABLE_MANUFACTURER_PRODUCT_SUCCESS",
    disableManufacturerProductStatus: status,
  };
};

export const disableManufacturerProductFail = (error) => {
  return {
    type: "DISABLE_MANUFACTURER_PRODUCT_FAIL",
    error: error,
  };
};

export const disableManufacturerProduct = (pid) => {
  return (dispatch) => {
    instance
      .put(`/product/disablesinglemanufactureproduct/${pid}/`)
      .then((response) => {
        dispatch(disableManufacturerProductSuccess(response.data.success));
      })
      .catch((error) => {
        dispatch(disableManufacturerProductFail(error));
      });
  };
};

export const disableDistributorProductSuccess = (status) => {
  return {
    type: "DISABLE_DISTRIBUTOR_PRODUCT_SUCCESS",
    disableDistributorProductStatus: status,
  };
};

export const disableDistributorProductFail = (error) => {
  return {
    type: "DISABLE_DISTRIBUTOR_PRODUCT_FAIL",
    error: error,
  };
};

export const disableDistributorProduct = (pid) => {
  return (dispatch) => {
    instance
      .put(`/product/disablesingledistributorproduct/${pid}/`)
      .then((response) => {
        dispatch(disableDistributorProductSuccess(response.data.success));
      })
      .catch((error) => {
        dispatch(disableDistributorProductFail(error));
      });
  };
};

export const enableManufacturerSuccess = (status) => {
  return {
    type: "ENABLE_MANUFACTURER_SUCCESS",
    enableManufacturerStatus: status,
  };
};

export const enableManufacturerFail = (error) => {
  return {
    type: "ENABLE_MANUFACTURER_FAIL",
    error: error,
  };
};

export const enableManufacturer = (profileId) => {
  const data = { profile: profileId };
  return (dispatch) => {
    instance
      .put("/product/enableallmanufactureproduct/", data)
      .then((response) => {
        dispatch(enableManufacturerSuccess(response.data.success));
      })
      .catch((error) => {
        dispatch(enableManufacturerFail(error));
      });
  };
};

export const enableDistributorSuccess = (status) => {
  return {
    type: "ENABLE_DISTRIBUTOR_SUCCESS",
    enableDistributorStatus: status,
  };
};

export const enableDistributorFail = (error) => {
  return {
    type: "ENABLE_DISTRIBUTOR_FAIL",
    error: error,
  };
};

export const enableDistributor = (profileId) => {
  const data = { profile: profileId };
  return (dispatch) => {
    instance
      .put("/product/enablealldistributorproduct/", data)
      .then((response) => {
        dispatch(enableDistributorSuccess(response.data.success));
      })
      .catch((error) => {
        dispatch(enableDistributorFail(error));
      });
  };
};

export const enableManufacturerProductSuccess = (status) => {
  return {
    type: "ENABLE_MANUFACTURER_PRODUCT_SUCCESS",
    enableManufacturerProductStatus: status,
  };
};

export const enableManufacturerProductFail = (error) => {
  return {
    type: "ENABLE_MANUFACTURER_PRODUCT_FAIL",
    error: error,
  };
};

export const enableManufacturerProduct = (pid) => {
  return (dispatch) => {
    instance
      .put(`/product/enablesinglemanufactureproduct/${pid}/`)
      .then((response) => {
        dispatch(enableManufacturerProductSuccess(response.data.success));
      })
      .catch((error) => {
        dispatch(enableManufacturerProductFail(error));
      });
  };
};

export const enableDistributorProductSuccess = (status) => {
  return {
    type: "ENABLE_DISTRIBUTOR_PRODUCT_SUCCESS",
    enableDistributorProductStatus: status,
  };
};

export const enableDistributorProductFail = (error) => {
  return {
    type: "ENABLE_DISTRIBUTOR_PRODUCT_FAIL",
    error: error,
  };
};

export const enableDistributorProduct = (pid) => {
  return (dispatch) => {
    instance
      .put(`/product/enablesingledistributorproduct/${pid}/`)
      .then((response) => {
        dispatch(enableDistributorProductSuccess(response.data.success));
      })
      .catch((error) => {
        dispatch(enableDistributorProductFail(error));
      });
  };
};

export const clearDisableStatus = () => {
  return {
    type: "CLEAR_DISABLE_STATUS",
  };
};

export const getProductDetailSuccess = (data) => {
  return {
    type: "GET_PRODUCT_DETAIL_SUCCESS",
    manufacturerProductDetail: data,
  };
};

export const getProductDetailFail = (error) => {
  return {
    type: "GET_PRODUCT_DETAIL_FAIL",
    error: error,
  };
};

export const getProductDetail = (pid) => {
  return (dispatch) => {
    instance
      .get(`/product/productdetail/${pid}/`)
      .then((response) => {
        dispatch(getProductDetailSuccess(response.data));
      })
      .catch((error) => {
        dispatch(getProductDetailFail(error));
      });
  };
};

export const getDistributorProductDetailSuccess = (data) => {
  return {
    type: "GET_DISTRIBUTOR_PRODUCT_DETAIL_SUCCESS",
    distributorProductDetail: data,
  };
};

export const getDistributorProductDetailFail = (error) => {
  return {
    type: "GET_DISTRIBUTOR_PRODUCT_DETAIL_FAIL",
    error: error,
  };
};

export const getDistributorProductDetail = (pid) => {
  return (dispatch) => {
    instance
      .get(`/product/distributorproductdetail/${pid}/`)
      .then((response) => {
        dispatch(getDistributorProductDetailSuccess(response.data));
      })
      .catch((error) => {
        dispatch(getDistributorProductDetailFail(error));
      });
  };
};

export const getProductImageSuccess = (data) => {
  return {
    type: "GET_PRODUCT_IMAGE_SUCCESS",
    productImage: data,
  };
};

export const getProductImageFail = (error) => {
  return {
    type: "GET_PRODUCT_IMAGE_FAIL",
    error: error,
  };
};

export const getProductImage = (pid) => {
  return (dispatch) => {
    instance
      .get(`/product/productimage/${pid}/`)
      .then((response) => {
        dispatch(getProductImageSuccess(response.data));
      })
      .catch((error) => {
        dispatch(getProductImageFail(error));
      });
  };
};

export const getProductVideoSuccess = (data) => {
  return {
    type: "GET_PRODUCT_VIDEO_SUCCESS",
    productVideo: data,
  };
};

export const getProductVideoFail = (error) => {
  return {
    type: "GET_PRODUCT_VIDEO_FAIL",
    error: error,
  };
};

export const getProductVideo = (pid) => {
  return (dispatch) => {
    instance
      .get(`/product/productvideo/${pid}/`)
      .then((response) => {
        dispatch(getProductVideoSuccess(response.data));
      })
      .catch((error) => {
        dispatch(getProductVideoFail(error));
      });
  };
};

export const postSearchQuerySuccess = (data) => {
  return {
    type: "POST_SEARCH_QUERY_SUCCESS",
    searchResponse: data,
  };
};

export const postSearchQueryFail = (error) => {
  return {
    type: "POST_SEARCH_QUERY_FAIL",
    error: error,
  };
};

export const postSearchQuery = (data) => {
  return (dispatch) => {
    instance
      .post("/product/autocomplete/", data)
      .then((response) => {
        dispatch(postSearchQuerySuccess(response.data));
      })
      .catch((error) => {
        dispatch(postSearchQueryFail(error));
      });
  };
};

export const filterByCategoryManSuccess = (data) => {
  return {
    type: "FILTER_BYCATEGORY_MAN_SUCCESS",
    filterManCategoryProducts: data,
  };
};

export const filterByCategoryManFail = (error) => {
  return {
    type: "FILTER_BYCATEGORY_MAN_FAIL",
    error: error,
  };
};

export const filterByCategoryMan = (id, page) => {
  return (dispatch) => {
    instance
      .get(`/product/productfilter/${id}/?page=${page}`)
      .then((response) => {
        dispatch(filterByCategoryManSuccess(response.data));
      })
      .catch((error) => {
        dispatch(filterByCategoryManFail(error));
      });
  };
};

export const filterByCategoryDisSuccess = (data) => {
  return {
    type: "FILTER_BYCATEGORY_DIS_SUCCESS",
    filterDisCategoryProducts: data,
  };
};

export const filterByCategoryDisFail = (error) => {
  return {
    type: "FILTER_BYCATEGORY_DIS_FAIL",
    error: error,
  };
};

export const filterByCategoryDis = (id) => {
  return (dispatch) => {
    instance
      .get(`/product/productfilterdistributor/${id}/`)
      .then((response) => {
        dispatch(filterByCategoryDisSuccess(response.data));
      })
      .catch((error) => {
        dispatch(filterByCategoryDisFail(error));
      });
  };
};

export const getManSearchFilteredDataSuccess = (data) => {
  return {
    type: "GET_MAN_SEARCH_FILTERED_DATA_SUCCESS",
    manSearchFilteredData: data,
  };
};

export const getManSearchFilteredDataFail = (error) => {
  return {
    type: "GET_MAN_SEARCH_FILTERED_DATA_FAIL",
    error: error,
  };
};

export const getManSearchFilteredData = (data) => {
  return (dispatch) => {
    instance
      .post("/product/manufactureproductsearch/", data)
      .then((response) => {
        dispatch(getManSearchFilteredDataSuccess(response.data));
      })
      .catch((error) => {
        dispatch(getManSearchFilteredDataFail(error));
      });
  };
};

export const getDisSearchFilteredDataSuccess = (data) => {
  return {
    type: "GET_DIS_SEARCH_FILTERED_DATA_SUCCESS",
    disSearchFilteredData: data,
  };
};

export const getDisSearchFilteredDataFail = (error) => {
  return {
    type: "GET_DIS_SEARCH_FILTERED_DATA_FAIL",
    error: error,
  };
};

export const getDisSearchFilteredData = (data) => {
  return (dispatch) => {
    instance
      .post("/product/distributorproductsearch/", data)
      .then((response) => {
        dispatch(getDisSearchFilteredDataSuccess(response.data));
      })
      .catch((error) => {
        dispatch(getDisSearchFilteredDataFail(error));
      });
  };
};

export const clearAllProducts = () => {
  return {
    type: "CLEAR_ALL_PRODUCTS",
  };
};

export const clearVariantDeleteStatus = () => {
  return {
    type: "CLEAR_VARIANT_DELETE_STATUS",
  };
};

export const clearThisProductDetails = () => {
  return {
    type: "CLEAR_THIS_PRODUCT_DETAIL",
  };
};

export const clearDeleteImageResponse = () => {
  return {
    type: "CLEAR_DELETE_IMAGE_RESPONSE",
  };
};

export const clearDeleteVideoResponse = () => {
  return {
    type: "CLEAR_DELETE_VIDEO_RESPONSE",
  };
};

export const clearManufacturerProducts = () => {
  return {
    type: "CLEAR_MANUFACTURER_PRODUCTS",
  };
};

export const clearStockData = () => {
  return {
    type: "CLEAR_STOCK_DATA",
  };
};

export const clearInstockQuantity = () => {
  return {
    type: "CLEAR_INSTOCK_QUANTITY",
  };
};

export const clearDistributorProducts = () => {
  return {
    type: "CLEAR_DISTRIBUTOR_PRODUCTS",
  };
};

export const clearManufacturerDesc = () => {
  return {
    type: "CLEAR_MAN_DESC",
  };
};

export const clearManufacturerInstockQuantity = () => {
  return {
    type: "CLEAR_MAN_INSTOCK",
  };
};

export const getProductMediaCountSuccess = (data) => {
  return {
    type: "GET_PRODUCT_MEDIA_COUNT_SUCCESS",
    ProductMediaCount: data,
  };
};

export const getProductMediaCountFail = (error) => {
  return {
    type: "GET_PRODUCT_MEDIA_COUNT_FAIL",
    error: error,
  };
};

export const getProductMediaCount = (PID) => {
  return (dispatch) => {
    instance
      .get(`/product/count/${PID}/`)
      .then((response) => {
        dispatch(getProductMediaCountSuccess(response.data));
      })
      .catch((error) => {
        dispatch(getProductMediaCountFail(error));
      });
  };
};

export const checkQuantitySuccess = (status, message) => {
  return {
    type: "CHECK_QUANTITY_SUCCESS",
    checkQuantityResponse: status,
    checkQuantityMessage: message,
  };
};

export const checkQuantityFail = (error) => {
  return {
    type: "CHECK_QUANTITY_FAIL",
    error: error,
  };
};

export const checkQuantity = (data) => {
  return (dispatch) => {
    instance
      .post("/product/checkquantity/", data)
      .then((response) => {
        dispatch(
          checkQuantitySuccess(response.data.success, response.data.message)
        );
      })
      .catch((error) => {
        dispatch(checkQuantityFail(error));
      });
  };
};

export const clearDistributorProductDetails = () => {
  return {
    type: "CLEAR_DISTRIBUTOR_PRODUCT_DETAILS",
  };
};

export const clearDistributorInstockQuantity = () => {
  return {
    type: "CLEAR_DIS_INSTOCK_QUANTITY",
  };
};

export const clearProductImages = () => {
  return {
    type: "CLEAR_PRODUCT_IMAGES",
  };
};

export const clearProductVideos = () => {
  return {
    type: "CLEAR_PRODUCT_VIDEOS",
  };
};

export const clearCheckQuantityResponse = () => {
  return {
    type: "CLEAR_CHECK_QUANTITY_RESPONSE",
  };
};

export const checkActiveSuccess = (status) => {
  return {
    type: "CHECK_ACTIVE_SUCCESS",
    checkActiveResponse: status,
  };
};

export const checkActiveFail = (error) => {
  return {
    type: "CHECK_ACTIVE_FAIL",
    error: error,
  };
};

export const checkActive = (data) => {
  return (dispatch) => {
    instance
      .post("/product/checkactive/", data)
      .then((response) => {
        dispatch(checkActiveSuccess(response.data.active));
      })
      .catch((error) => {
        dispatch(checkActiveFail(error));
      });
  };
};

export const clearCheckActive = () => {
  return {
    type: "CLEAR_CHECK_ACTIVE",
  };
};
