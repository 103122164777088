import { MatxLoadable } from "matx";
import { authRoles } from "../../../auth/authRoles";

// const DistributorProduct = MatxLoadable({
//   loader: () => import("./Purchase-History")
// })
const DistributorStock = MatxLoadable({
  loader: () => import("./Productsinstock"),
});

const UpdateProductPrice = MatxLoadable({
  loader: () => import("./UpdateProductPrice"),
});

const DistributorProductRoutes = [
  // {
  //   path: "/products/PurchaseHistory",
  //   component: DistributorProduct,
  //   auth: authRoles.admin
  // },
  {
    path: "/distributor/Productinstock",
    component: DistributorStock,
    auth: authRoles.admin,
  },
  {
    path: "/distributor/updateprice",
    component: UpdateProductPrice,
    auth: authRoles.admin,
  },
];

export default DistributorProductRoutes;
