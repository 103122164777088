import React, { Component } from "react";
import {
  Card,
  Checkbox,
  FormControlLabel,
  Grid,
  Button,
  withStyles,
  CircularProgress,
  Link,
} from "@material-ui/core";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { withRouter } from "react-router-dom";

const styles = (theme) => ({
  wrapper: {
    position: "relative",
  },

  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
});

class SuperSignIn extends Component {
  state = {
    email: "superAdmin@eCaps.com",
    password: "superPass",
    agreement: "",
  };
  handleChange = (event) => {
    event.persist();
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  handleFormSubmit = (event) => {
    this.props.onAuth(this.state.email, this.state.password);
  };

  render() {
    let { email, password } = this.state;
    let { classes } = this.props;
    return (
      <div className="signup flex flex-center w-100 h-100vh">
        <div className="p-8">
          <Card className="signup-card position-relative y-center">
            <Grid container>
              <Grid item lg={5} md={5} sm={5} xs={12}>
                <div className="p-36 h-100 bg-light-gray position-relative">
                  <div className="mb-20">
                    <h4>Super Admin Log-in</h4>
                  </div>
                  <ValidatorForm ref="form" onSubmit={this.handleFormSubmit}>
                    <TextValidator
                      className="mb-24 w-100"
                      variant="outlined"
                      label="Email"
                      onChange={this.handleChange}
                      type="email"
                      name="email"
                      value={email}
                      validators={["required", "isEmail"]}
                      errorMessages={[
                        "this field is required",
                        "email is not valid",
                      ]}
                    />
                    <TextValidator
                      className=" w-100"
                      label="Password"
                      variant="outlined"
                      onChange={this.handleChange}
                      name="password"
                      type="password"
                      value={password}
                      validators={["required"]}
                      errorMessages={["this field is required"]}
                    />

                    {/* <FormControlLabel
                      className="mb-8"
                      name="agreement"
                      onChange={this.handleChange}
                      control={<Checkbox checked />}
                      label="I agree terms of service."
                    /> */}
                    <Button
                      size="small"
                      className="text-primary"
                      onClick={() =>
                        this.props.history.push("/session/forgot-password")
                      }
                    >
                      Forgot password?
                    </Button>
                    <div className="flex flex-middle mb-8">
                      <div className={classes.wrapper}>
                        <Button
                          style={{ width: "350px" }}
                          size="medium"
                          variant="contained"
                          color="primary"
                          disabled={this.props.login.loading}
                          type="submit"
                        >
                          Login
                        </Button>
                        {this.props.login.loading && (
                          <CircularProgress
                            size={24}
                            className={classes.buttonProgress}
                          />
                        )}
                      </div>
                    </div>
                  </ValidatorForm>
                </div>
              </Grid>
              <Grid item lg={7} md={7} sm={7} xs={12}>
                <div
                  className="p-32 h-100 bg-primary"
                  style={{ width: "600px", textAlign: "center" }}
                >
                  {/* <img src="" alt="" /> */}
                  <p style={{ color: "white", marginTop: "15%" }}>
                    Welcome to Ecaps
                  </p>
                  <div
                    className="signup-content"
                    style={{ marginBottom: "20px" }}
                  ></div>
                  <div style={{ color: "white", marginTop: "10" }}>
                    <span>709-0000-725, Send a message</span>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Card>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loginWithEmailAndPassword: PropTypes.func.isRequired,
  login: state.login,
});

const mapDispatchToProps = (dispatch) => {
  return {
    // onAuth: (email, password) => dispatch(actions.auth(email, password)),
  };
};

export default withStyles(styles, { withTheme: true })(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(SuperSignIn))
);
