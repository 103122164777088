import { MatxLoadable } from "matx";

const Cart = MatxLoadable({
  loader: () => import("./Cart"),
});

const Proceed = MatxLoadable({
  loader: () => import("./ProceedPay"),
});

const ShowSuccess = MatxLoadable({
  loader: () => import("./ShowSuccess"),
});

const settings = {
  activeLayout: "layout1",
  layout1Settings: {
    topbar: {
      show: false,
    },
    leftSidebar: {
      show: false,
      mode: "close",
    },
  },
  layout2Settings: {
    mode: "full",
    topbar: {
      show: false,
    },
    navbar: { show: false },
  },
  secondarySidebar: { show: false },
  footer: { show: false },
};

const CartRoutes = [
  {
    path: "/cart",
    component: Cart,
  },
  {
    path: "/proceedpay",
    component: Proceed,
    settings,
  },
  {
    path: "/showsuccess",
    component: ShowSuccess,
  },
];

export default CartRoutes;
