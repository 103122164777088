import { updatedObject } from "../Utility";

const initialState = {
  orderStatus: "",
  customerOrders: "",
  acceptOrderStatus: "",
  shipOrderStatus: "",
  myOrders: "",
  instock: "",
  intransit: "",
  shipPartialOrderStatus: "",
  acceptRecievedStatus: "",
  shipRetailerOrderStatus: "",
  shipPartialRetailerOrderStatus: "",
  shipInfo: "",
  orderDetails: "",
  orderedData: "",
  oneOrderDetails: "",
  OrderSuccessDetails: "",
  returnableQuantity: "",
  returnOrderStatus: "",
  buyerReturns: "",
  acceptReturnStatus: "",
  shipReturnStatus: "",
  myReturns: "",
  returnImages: "",
  completeReturnStatus: "",
  allOrdersAdmin: "",
  returnedOrdersAdmin: "",
  manStockDashboard: "",
  disStockDashboard: "",
  deliveryNote: "",
  invoice: "",
  tempStock: "",
  rejectReturnStatus: "",
  cancelReturnOrderStatus: "",
  refundTotal: "",
  adminRefundTotal: "",
  refundsInUsers: "",
  settlementsInUsers: "",
  refundsInAdmin: "",
  settlementsInAdmin: "",
};

const orderProductsSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    orderStatus: action.orderStatus,
    orderedData: action.orderedData,
  });
};

const orderProductsFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const getCustomerOrdersSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    customerOrders: action.customerOrders,
  });
};

const getCustomerOrdersFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const acceptOrderSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    acceptOrderStatus: action.acceptOrderStatus,
  });
};

const acceptOrderFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const clearOrderData = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    customerOrders: "",
  });
};

const clearAcceptOrderStatus = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    acceptOrderStatus: "",
  });
};

const clearOrderStatus = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    orderStatus: "",
  });
};

const shipOrderSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    shipOrderStatus: action.shipOrderStatus,
  });
};

const shipOrderFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const clearShipOrderStatus = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    shipOrderStatus: "",
    shipPartialOrderStatus: "",
  });
};

const getMyOrdersSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    myOrders: action.distributorOrders,
  });
};

const getMyOrdersFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const getStockSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    instock: action.inStock,
  });
};

const getStockFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const getIntransitSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    intransit: action.intransit,
  });
};

const getIntransitFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const shipPartialOrderSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    shipPartialOrderStatus: action.shipPartialOrderStatus,
  });
};

const shipPartialOrderFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const acceptRecievedSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    acceptRecievedStatus: action.acceptRecievedStatus,
  });
};

const acceptRecievedFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const clearAcceptRecievedStatus = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    acceptRecievedStatus: "",
  });
};

const shipRetailerOrderSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    shipRetailerOrderStatus: action.shipRetailerOrderStatus,
  });
};

const shipRetailerOrderFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const clearShipRetailerOrderStatus = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    shipRetailerOrderStatus: "",
    shipPartialRetailerOrderStatus: "",
  });
};

const shipPartialRetailerOrderSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    shipPartialRetailerOrderStatus: action.shipPartialRetailerOrderStatus,
  });
};

const shipPartialRetailerOrderFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const getShipInfoSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    shipInfo: action.shipInfo,
  });
};

const getShipInfoFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const getOrderDetailSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    orderDetails: action.orderDetails,
  });
};

const getOrderDetailFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const getOneOrderDetailSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    oneOrderDetails: action.oneOrderDetails,
  });
};

const getOneOrderDetailFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const getOrderSuccessDetailsSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    OrderSuccessDetails: action.OrderSuccessDetails,
  });
};

const getOrderSuccessDetailsFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const checkReturnQuantitySuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    returnableQuantity: action.returnableQuantity,
  });
};

const checkReturnQuantityFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const returnOrderSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    returnOrderStatus: action.returnOrderStatus,
  });
};

const returnOrderFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const clearInStock = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    instock: "",
  });
};

const clearInTransit = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    intransit: "",
  });
};

const clearShipInfo = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    shipInfo: "",
  });
};

const clearReturnOrderStatus = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    returnOrderStatus: "",
  });
};

const getBuyerReturnsSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    buyerReturns: action.buyerReturns,
  });
};

const getBuyerReturnsFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const acceptBuyerReturnSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    acceptReturnStatus: action.acceptReturnStatus,
  });
};

const acceptBuyerReturnFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const rejectBuyerReturnSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    rejectReturnStatus: action.rejectReturnStatus,
  });
};

const rejectBuyerReturnFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const shipBuyerReturnSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    shipReturnStatus: action.shipReturnStatus,
  });
};

const shipBuyerReturnFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const getMyReturnsSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    myReturns: action.myReturns,
  });
};

const getMyReturnsFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const clearAcceptReturnStatus = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    acceptReturnStatus: "",
  });
};

const clearRejectReturnStatus = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    rejectReturnStatus: "",
  });
};

const clearBuyerReturns = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    buyerReturns: "",
  });
};

const clearShipReturnStatus = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    shipReturnStatus: "",
  });
};

const getReturnImagesSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    returnImages: action.returnImages,
  });
};

const getReturnImagesFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const completeReturnSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    completeReturnStatus: action.completeReturnStatus,
  });
};

const completeReturnFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const clearCompleteReturnStatus = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    completeReturnStatus: "",
  });
};

const getAllOrdersAdminSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    allOrdersAdmin: action.allOrdersAdmin,
  });
};

const getAllOrdersAdminFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const getReturnedOrdersAdminSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    returnedOrdersAdmin: action.returnedOrdersAdmin,
  });
};

const getReturnedOrdersAdminFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const getManStockDashboardSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    manStockDashboard: action.manStockDashboard,
  });
};

const getManStockDashboardFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const getDisStockDashboardSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    disStockDashboard: action.disStockDashboard,
  });
};

const getDisStockDashboardFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const getDeliveryNoteSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    deliveryNote: action.deliveryNote,
  });
};

const getDeliveryNoteFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const getInvoiceSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    invoice: action.invoice,
  });
};

const getInvoiceFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const getTempStockSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    tempStock: action.tempStock,
  });
};

const getTempStockFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};


const cancelReturnOrderSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    cancelReturnOrderStatus: action.cancelReturnOrderStatus,
  });
};

const cancelReturnOrderFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const clearCancelReturnOrderStatus = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    cancelReturnOrderStatus: "",
  });
};

const clearMyReturns = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    myReturns: "",
  });
};

const getRefundTotalSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    refundTotal: action.refundTotal,
  });
};

const getRefundTotalFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const clearRefundTotal = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    refundTotal: "",
  });
};

const getAdminRefundTotalSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    adminRefundTotal: action.adminRefundTotal,
  });
};

const getAdminRefundTotalFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const clearAdminRefundTotal = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    adminRefundTotal: "",
  });
};

const getRefundsInUsersSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    refundsInUsers: action.refundsInUsers,
  });
};

const getRefundsInUsersFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const getSettlementsInusersSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    settlementsInUsers: action.settlementsInUsers,
  });
};

const getSettlementsInusersFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const getRefundsInAdminSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    refundsInAdmin: action.refundsInAdmin,
  });
};

const getRefundsInAdminFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const getSettlementsInAdminSuccess = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: null,
    settlementsInAdmin: action.settlementsInAdmin,
  });
};

const getSettlementsInAdminFail = (state, action) => {
  return updatedObject(state, {
    loading: false,
    error: action.error,
  });
};

const OrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ORDER_PRODUCTS_SUCCESS":
      return orderProductsSuccess(state, action);
    case "ORDER_PRODUCTS_FAIL":
      return orderProductsFail(state, action);
    case "GET_CUSTOMER_ORDERS_SUCCESS":
      return getCustomerOrdersSuccess(state, action);
    case "GET_CUSTOMER_ORDERS_FAIL":
      return getCustomerOrdersFail(state, action);
    case "ACCEPT_ORDER_SUCCESS":
      return acceptOrderSuccess(state, action);
    case "ACCEPT_ORDER_FAIL":
      return acceptOrderFail(state, action);
    case "CLEAR_ORDER_DATA":
      return clearOrderData(state, action);
    case "CLEAR_ACCEPT_ORDER_STATUS":
      return clearAcceptOrderStatus(state, action);
    case "CLEAR_ORDER_STATUS":
      return clearOrderStatus(state, action);
    case "SHIP_ORDER_SUCCESS":
      return shipOrderSuccess(state, action);
    case "SHIP_ORDER_FAIL":
      return shipOrderFail(state, action);
    case "CLEAR_SHIP_ORDER_STATUS":
      return clearShipOrderStatus(state, action);
    case "GET_MY_ORDERS_SUCCESS":
      return getMyOrdersSuccess(state, action);
    case "GET_MY_ORDERS_FAIL":
      return getMyOrdersFail(state, action);
    case "GET_STOCK_SUCCESS":
      return getStockSuccess(state, action);
    case "GET_STOCK_FAIL":
      return getStockFail(state, action);
    case "GET_INTRANSIT_SUCCESS":
      return getIntransitSuccess(state, action);
    case "GET_INTRANSIT_FAIL":
      return getIntransitFail(state, action);
    case "SHIP_PARTIAL_ORDER_SUCCESS":
      return shipPartialOrderSuccess(state, action);
    case "SHIP_PARTIAL_ORDER_FAIL":
      return shipPartialOrderFail(state, action);
    case "ACCEPT_RECIEVED_SUCCESS":
      return acceptRecievedSuccess(state, action);
    case "ACCEPT_RECIEVED_FAIL":
      return acceptRecievedFail(state, action);
    case "CLEAR_ACCEPT_RECIEVED_STATUS":
      return clearAcceptRecievedStatus(state, action);
    case "SHIP_RETAILER_ORDER_SUCCESS":
      return shipRetailerOrderSuccess(state, action);
    case "SHIP_RETAILER_ORDER_FAIL":
      return shipRetailerOrderFail(state, action);
    case "CLEAR_SHIP_RETAILER_ORDER_STATUS":
      return clearShipRetailerOrderStatus(state, action);
    case "SHIP_PARTIAL_RETAILER_ORDER_SUCCESS":
      return shipPartialRetailerOrderSuccess(state, action);
    case "SHIP_PARTIAL_RETAILER_ORDER_FAIL":
      return shipPartialRetailerOrderFail(state, action);
    case "GET_SHIPINFO_SUCCESS":
      return getShipInfoSuccess(state, action);
    case "GET_SHIPINFO_FAIL":
      return getShipInfoFail(state, action);
    case "GET_ORDER_DETAIL_SUCCESS":
      return getOrderDetailSuccess(state, action);
    case "GET_ORDER_DETAIL_FAIL":
      return getOrderDetailFail(state, action);
    case "GET_ONE_ORDER_DETAIL_SUCCESS":
      return getOneOrderDetailSuccess(state, action);
    case "GET_ONE_ORDER_DETAIL_FAIL":
      return getOneOrderDetailFail(state, action);
    case "GET_ORDER_SUCCESS_DETAILS_SUCCESS":
      return getOrderSuccessDetailsSuccess(state, action);
    case "GET_ORDER_SUCCESS_DETAILS_FAIL":
      return getOrderSuccessDetailsFail(state, action);
    case "CHECK_RETURN_QUANTITY_SUCCESS":
      return checkReturnQuantitySuccess(state, action);
    case "CHECK_RETURN_QUANTITY_FAIL":
      return checkReturnQuantityFail(state, action);
    case "RETURN_ORDER_SUCCESS":
      return returnOrderSuccess(state, action);
    case "RETURN_ORDER_FAIL":
      return returnOrderFail(state, action);
    case "CLEAR_INSTOCK":
      return clearInStock(state, action);
    case "CLEAR_INTRANSIT":
      return clearInTransit(state, action);
    case "CLEAR_SHIP_INFO":
      return clearShipInfo(state, action);
    case "CLEAR_RETURN_ORDER_STATUS":
      return clearReturnOrderStatus(state, action);
    case "GET_BUYER_RETURNS_SUCCESS":
      return getBuyerReturnsSuccess(state, action);
    case "GET_BUYER_RETURNS_FAIL":
      return getBuyerReturnsFail(state, action);
    case "ACCEPT_BUYER_RETURN_SUCCESS":
      return acceptBuyerReturnSuccess(state, action);
    case "ACCEPT_BUYER_RETURN_FAIL":
      return acceptBuyerReturnFail(state, action);
    case "REJECT_BUYER_RETURN_SUCCESS":
      return rejectBuyerReturnSuccess(state, action);
    case "REJECT_BUYER_RETURN_FAIL":
      return rejectBuyerReturnFail(state, action);
    case "SHIP_BUYER_RETURN_SUCCESS":
      return shipBuyerReturnSuccess(state, action);
    case "SHIP_BUYER_RETURN_FAIL":
      return shipBuyerReturnFail(state, action);
    case "GET_MY_RETURNS_SUCCESS":
      return getMyReturnsSuccess(state, action);
    case "GET_MY_RETURNS_FAIL":
      return getMyReturnsFail(state, action);
    case "CLEAR_ACCEPT_RETURN_STATUS":
      return clearAcceptReturnStatus(state, action);
    case "CLEAR_REJECT_RETURN_STATUS":
      return clearRejectReturnStatus(state, action);
    case "CLEAR_BUYER_RETURNS":
      return clearBuyerReturns(state, action);
    case "CLEAR_SHIP_RETURN_STATUS":
      return clearShipReturnStatus(state, action);
    case "GET_RETURN_IMAGES_SUCCESS":
      return getReturnImagesSuccess(state, action);
    case "GET_RETURN_IMAGES_FAIL":
      return getReturnImagesFail(state, action);
    case "COMPLETE_RETURN_SUCCESS":
      return completeReturnSuccess(state, action);
    case "COMPLETE_RETURN_FAIL":
      return completeReturnFail(state, action);
    case "CLEAR_COMPLETE_RETURN_STATUS":
      return clearCompleteReturnStatus(state, action);
    case "GET_ALL_ORDERS_ADMIN_SUCCESS":
      return getAllOrdersAdminSuccess(state, action);
    case "GET_ALL_ORDERS_ADMIN_FAIL":
      return getAllOrdersAdminFail(state, action);
    case "GET_RETURNED_ORDERS_ADMIN_SUCCESS":
      return getReturnedOrdersAdminSuccess(state, action);
    case "GET_RETURNED_ORDERS_ADMIN_FAIL":
      return getReturnedOrdersAdminFail(state, action);
    case "GET_MAN_STOCK_DASHBOARD_SUCCESS":
      return getManStockDashboardSuccess(state, action);
    case "GET_MAN_STOCK_DASHBOARD_FAIL":
      return getManStockDashboardFail(state, action);
    case "GET_DIS_STOCK_DASHBOARD_SUCCESS":
      return getDisStockDashboardSuccess(state, action);
    case "GET_DIS_STOCK_DASHBOARD_FAIL":
      return getDisStockDashboardFail(state, action);
    case "GET_DELIVERY_NOTE_SUCCESS":
      return getDeliveryNoteSuccess(state, action);
    case "GET_DELIVERY_NOTE_FAIL":
      return getDeliveryNoteFail(state, action);
    case "GET_INVOICE_SUCCESS":
      return getInvoiceSuccess(state, action);
    case "GET_INVOICE_FAIL":
      return getInvoiceFail(state, action);
    case "GET_TEMP_STOCK_SUCCESS":
      return getTempStockSuccess(state, action);
    case "GET_TEMP_STOCK_FAIL":
      return getTempStockFail(state, action);
    case "CANCEL_RETURN_ORDER_SUCCESS":
      return cancelReturnOrderSuccess(state, action);
    case "CANCEL_RETURN_ORDER_FAIL":
      return cancelReturnOrderFail(state, action);
    case "CLEAR_CANCEL_RETURN_ORDER_STATUS":
      return clearCancelReturnOrderStatus(state, action);
    case "CLEAR_MY_RETURNS":
      return clearMyReturns(state, action);
    case "GET_REFUND_TOTAL_SUCCESS":
      return getRefundTotalSuccess(state, action);
    case "GET_REFUND_TOTAL_FAIL":
      return getRefundTotalFail(state, action);
    case "CLEAR_REFUND_TOTAL":
      return clearRefundTotal(state, action);
    case "GET_ADMIN_REFUND_TOTAL_SUCCESS":
      return getAdminRefundTotalSuccess(state, action);
    case "GET_ADMIN_REFUND_TOTAL_FAIL":
      return getAdminRefundTotalFail(state, action);
    case "CLEAR_ADMIN_REFUND_TOTAL":
      return clearAdminRefundTotal(state, action);
    case "GET_REFUNDS_IN_USERS_SUCCESS":
      return getRefundsInUsersSuccess(state, action);
    case "GET_REFUNDS_IN_USERS_FAIL":
      return getRefundsInUsersFail(state, action);
    case "GET_SETTLEMENTS_IN_USERS_SUCCESS":
      return getSettlementsInusersSuccess(state, action);
    case "GET_SETTLEMENTS_IN_USERS_FAIL":
      return getSettlementsInusersFail(state, action);
    case "GET_REFUNDS_IN_ADMIN_SUCCESS":
      return getRefundsInAdminSuccess(state, action);
    case "GET_REFUNDS_IN_ADMIN_FAIL":
      return getRefundsInAdminFail(state, action);
    case "GET_SETTLEMENTS_IN_ADMIN_SUCCESS":
      return getSettlementsInAdminSuccess(state, action);
    case "GET_SETTLEMENTS_IN_ADMIN_FAIL":
      return getSettlementsInAdminFail(state, action);
    default:
      return state;
  }
};

export default OrderReducer;
