import { MatxLoadable } from "matx";

const AllOrders = MatxLoadable({
  loader: () => import("./AllOrders"),
});

const AllReturns = MatxLoadable({
  loader: () => import("./AllReturns"),
});

const Refund = MatxLoadable({
  loader: () => import("./Refund"),
});

const AllOrderRoutes = [
  {
    path: "/allorders",
    component: AllOrders,
  },
  {
    path: "/allreturns",
    component: AllReturns,
  },
  {
    path: "/adminrefunds",
    component: Refund,
  },
];

export default AllOrderRoutes;
