import { MatxLoadable } from "matx";

const DistributorList = MatxLoadable({
  loader: () => import("./DistributorList"),
});

const DistributorProducts = MatxLoadable({
  loader: () => import("./DistributorProducts"),
});

const DistributorRoutes = [
  {
    path: "/distributorlist",
    component: DistributorList,
  },
  {
    path: "/distributorproducts",
    component: DistributorProducts,
  },
];

export default DistributorRoutes;
